import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Icon
import AutorenewIcon from '@material-ui/icons/Autorenew';

// Services
import { loadBalance } from "./../../../../@core/services/appService";

// Redux
import { setHomeData } from "./../../../../@core/redux/actions/home/index";

export default function ReloadBlance() {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    // const [pathName, setPathName] = useState("/");
    const [spin, setSpin] = useState(0);
    const homeData = useSelector(state => state.reduxHome.result);

    const useStyles = makeStyles(() => ({
        iconSize: {
            fontSize: "24px",
            cursor: "pointer",
            '&:hover': {
                color: "rgb(255, 143, 0)"
            }
        },
        refresh: {
            margin: "auto",
        },
        spin: {
            margin: "auto",
            animation: "$spin 1s 1",
        },
        "@keyframes spin": {
            "0%": {
                transform: "rotate(0deg)",
            },
            "100%": {
                transform: "rotate(360deg)",
            },
        }
    }));

    const classes = useStyles();

    const ReloadBlance = () => {
        setSpin(true);

        loadBalance().then((data) => {
            setSpin(false);
            const statusCode = data.code;
            if (statusCode === 0 || statusCode === 200) {
                let dataObj = {
                    ...homeData,
                    balance: data.result.balance
                };

                dispatch(setHomeData(dataObj));
            }
        });
    };

    // useEffect(() => {
    //     setPathName(window.location.pathname);
    // }, [window.location.pathname, path]);

    return <AutorenewIcon className={spin ? [classes.iconSize, classes.spin].join(" ") : [classes.iconSize, classes.refresh].join(" ")} onClick={() => ReloadBlance()} />;
}