const SET_HOME_DATA = 'app/SET_HOME_DATA';
const SET_HOME_MENU_TYPE = 'app/SET_HOME_MENU_TYPE';

function setHomeData(data) {
    return {
        type: SET_HOME_DATA,
        payload: {
            items: data
        }
    }
}

function setHomeMenuType(data) {
    return {
        type: SET_HOME_MENU_TYPE,
        payload: {
            items: data
        }
    }
}

export {
    SET_HOME_DATA,
    SET_HOME_MENU_TYPE,
    setHomeData,
    setHomeMenuType
}