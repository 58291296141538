import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';

// CSS
import styles from "./../../../assets/css/Style.module.css";

export default function Condition({ typePage, condition }) {
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const [conditionJson, setConditionJson] = useState(null);

    const useStyles = makeStyles(() => ({
        titleMarginLeft: {
            marginLeft: "15px"
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        if (condition) {
            setConditionJson({ text: condition });
        }

        if (!condition) {
            if (dataSetting !== null) {
                if (dataSetting?.agent_condition !== undefined) {
                    let dataFilter = {};
                    let agent_condition = dataSetting.agent_condition;
                    if (typePage === "truemoney") {
                        dataFilter = agent_condition.find((item) => (item.code === "deposit_truemoney"))
                    }
                    else {
                        dataFilter = agent_condition.find((item) => (item.code === "deposit_bank"))
                    }

                    if (dataFilter !== undefined) {
                        setConditionJson(dataFilter);
                    }
                }
            }
        }
    }, [condition, dataSetting?.agent_condition]);

    return (
        conditionJson !== null && (
            <>
                <div className={classes.titleMarginLeft}>
                    {
                        typePage === "bank" && (
                            <>
                                <div className={styles.boxMarginTop}>
                                    <span className={styles.titleRule}>
                                        ขั้นตอนการเติมเงิน
                                    </span>
                                </div>

                                <div>
                                    กรุณาระบุจำนวนเงินที่คุณต้องการ เเล้วกดปุ่มขอเลขบัญชี
                                </div>
                            </>
                        )
                    }

                    {
                        ReactHtmlParser(conditionJson?.text)
                    }
                </div>
            </>
        )
    );
}