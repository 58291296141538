const SET_DEPOSIT_DATA = 'app/SET_DEPOSIT_DATA';
const SET_DEPOSIT_MANUAL = 'app/SET_DEPOSIT_MANUAL';
const SET_DATA_BANK_CUSTOMER_ALL = 'app/SET_DATA_BANK_CUSTOMER_ALL';

function setDepositData(data) {
    return {
        type: SET_DEPOSIT_DATA,
        payload: {
            items: data
        }
    }
}

function setDepositManual(data) {
    return {
        type: SET_DEPOSIT_MANUAL,
        payload: {
            items: data
        }
    }
}

function setDataBankCustomerAll(data) {
    return {
        type: SET_DATA_BANK_CUSTOMER_ALL,
        payload: {
            items: data
        }
    }
}

export {
    SET_DEPOSIT_DATA,
    SET_DEPOSIT_MANUAL,
    SET_DATA_BANK_CUSTOMER_ALL,
    setDepositData,
    setDepositManual,
    setDataBankCustomerAll
}