import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Redux
import { setDataDialog } from "./../../../../@core/redux/actions/app/dialog";
import * as authActions from "./../../../../@core/redux/actions/auth/index";

//  CSS
import modalStyles from "./../assets/css/Modal.module.css";
import styles from "./../assets/css/Style.module.css";
import buttonStyles from "./../assets/css/Button.module.css";

// Component
import CloseButton from "./CloseButton";

export default function DialogAction({ dialogData }) {
    const [dialogJson, setDialogJson] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        // btnPosition: {
        //     position: "absolute",
        //     left: "50%",
        //     bottom: "0px",
        //     transform: "translateX(-50%)"
        // },
        borderContent: {
            color: "#dacfcf",
            fontSize: "14px"
        },
        // boxPadding: {
        //     padding: "20px 40px"
        // },
        contentBoxAlign: {
            marginTop: "28px",
            textAlign: "center"
        },
        imgAlertSize: {
            width: "80px",
            height: "auto"
        },
        contentBoxAlignText: {
            fontSize: "22px",
            paddingTop: "10px",
            color: "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "18px",
            }
        },
        // textYellow: {
        //     color: "rgb(255, 193, 7)"
        // }
    }));

    const classes = useStyles();

    const closeBox = () => {
        let dialogJson = {
            statusShow: false,
            type: null,
            image: null,
            title: null,
            content: null,
            action: null
        };

        dispatch(setDataDialog(dialogJson));
    };

    const gotoDemo = (data, pageType) => {
        closeBox();

        if(pageType === "newTabGame") {
            window.open(data);
        }
        else {
            history.push(data);
        }
    };

    const openLogin = () => {
        let myObj = {
            tab: "login",
            show: true
        };

        closeBox();
        dispatch(authActions.openAuthenApp(myObj));
    };

    const actionConfirm = () => {
        if (dialogJson.pageType === "login") {
            openLogin();
        }
        else {
            closeBox();
        }
    };

    // const selectAction = () => {
    //     if (dialogJson.pageType === "login") {
    //         openLogin();
    //     }
    //     else {
    //         gotoDemo("/bank?redirect_back=/");
    //     }
    // };

    const renderContent = () => {
        if (dialogJson !== null) {
            if (dialogJson.pageType === "promotion") {
                if (dialogJson.type === "html") {
                    return (
                        <div style={{ fontSize: "17px", paddingTop: "14px" }}>
                            {ReactHtmlParser(dialogJson.content)}
                            {
                                dialogJson.action !== null && (
                                    <>
                                        <br />
                                        <div style={{ color: "rgb(255, 193, 7)" }}>
                                            วันที่หมดเขตรับโปรโมชั่น
                                            <div style={{ color: "#dacfcf" }}>
                                                {dialogJson.action.expire}
                                            </div>
                                        </div>
                                        {/* <br />
                                        <div style={{ color: "rgb(255, 193, 7)" }}>
                                            จำนวนคงเหลือ
                                            <div style={{ color: "#dacfcf" }}>
                                                100/1000
                                            </div>
                                        </div> */}
                                    </>
                                )
                            }
                            <br /><br />
                        </div>
                    );
                }
                else {
                    return (
                        <div>
                            {dialogJson.content}
                            {
                                dialogJson.action !== null && (
                                    <>
                                        <br />
                                        <div style={{ color: "rgb(255, 193, 7)" }}>
                                            วันที่หมดเขตรับโปรโมชั่น
                                            <div style={{ color: "#dacfcf" }}>
                                                {dialogJson.action.expire}
                                            </div>
                                        </div>
                                        {/* <br />
                                        <div style={{ color: "rgb(255, 193, 7)" }}>
                                            จำนวนคงเหลือ
                                            <div style={{ color: "#dacfcf" }}>
                                                100/1000
                                            </div>
                                        </div> */}
                                    </>
                                )
                            }
                            <br /><br />
                        </div>
                    );
                }
            }
            else if (dialogJson.pageType === "openGame" || dialogJson.pageType === "newTabGame") {
                return (
                    <>
                        <div className={classes.contentBoxAlign}>
                            {/* <img className={classes.imgAlertSize} src={dialogJson.image} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" /> */}
                            <div className={[classes.contentBoxAlign, classes.contentBoxAlignText].join(" ")}>
                                {dialogJson.content} "<span style={{ color: "rgb(255, 193, 7)" }}>เข้าสู่ระบบ</span>"
                            </div>
                            <br /><br />
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <button className={[buttonStyles.btnConfirm, buttonStyles.btnDemo].join(" ")} onClick={() => gotoDemo(dialogJson.action, dialogJson.pageType)}>
                                        ทดลองเล่น
                                    </button>
                                </Grid>
                                <Grid item xs={12}>
                                    <button className={buttonStyles.btnConfirm} onClick={openLogin}>
                                        เข้าสู่ระบบ
                                    </button>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                );
            }
            else if(dialogJson.pageType === "htmlOther") {
                return (
                    <>
                        <div className={classes.contentBoxAlign}>
                            <div style={{ fontSize: "17px", paddingTop: "14px" }}>
                                {ReactHtmlParser(dialogJson.content)}
                            </div>
                        </div>

                        {/* <div className={styles.boxMarginFormTop}>
                            <div className="center">
                                <button type="submit" className={buttonStyles.btnConfirm} onClick={actionConfirm}>
                                    ตกลง
                                </button>
                            </div>
                        </div> */}
                    </>
                );
            }
            else {
                return (
                    <>
                        <div className={classes.contentBoxAlign}>
                            <img className={classes.imgAlertSize} src={dialogJson.image} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            <div className={[classes.contentBoxAlign, classes.contentBoxAlignText].join(" ")} style={{ marginTop: "20px" }}>
                                {dialogJson.content}
                            </div>
                        </div>

                        <div className={styles.boxMarginFormTop}>
                            <div className="center">
                                <button type="submit" className={buttonStyles.btnConfirm} onClick={actionConfirm}>
                                    ตกลง
                                </button>
                            </div>
                        </div>
                    </>
                );
            }
        }
    };

    useEffect(() => {
        setDialogJson(dialogData);
    }, [dialogData, dispatch]);

    return (
        <>
            <div className={modalStyles.contentPosition} style={{ zIndex: "2022" }}>
                <div className={modalStyles.boxBody}>
                    <div className={modalStyles.box}>
                        <div className={modalStyles.closeButton} onClick={closeBox}>
                            <CloseButton></CloseButton>
                        </div>
                        <div className={[modalStyles.boxContentWidthBig].join(" ")}>
                            <div className={modalStyles.boxContentPadding}>
                                <div className={modalStyles.boxContentScroll}>
                                    <div className={[modalStyles.boxTitle, styles.center].join(" ")} style={{ marginTop: "0px" }}>
                                        {
                                            dialogJson !== null && (
                                                dialogJson.title
                                            )
                                        }
                                    </div>
                                    <div className={classes.borderContent}>
                                        {renderContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={modalStyles.fixedBackdrop} style={{ zIndex: "2021", opacity: "0.9" }} onClick={closeBox}></div>
        </>
    );
}