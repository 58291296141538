import { useSelector } from 'react-redux';

// Components
import Profile from "./Profile";

export default function ContentProfile({ reduxProfile }) {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - โปรไฟล์";
    const homeData = useSelector(state => state.reduxHome.result);

    return <Profile homeData={homeData} reduxProfile={reduxProfile}></Profile>;
}