import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

// @utility
import alertText from "./../../../../../@utility/app/alertText";
import validateInput from "./../../../../../@utility/app/validateInput";

// Services
import { rememberPassword } from "./../../../../../@core/auth/jwt/jwtService";

// Redux
import { setDataAlert } from "./../../../../../@core/redux/actions/app/alert";
import { setStatusSpinner } from "./../../../../../@core/redux/actions/app/spinner";

//  CSS
import formStyles from "./../../assets/css/Input.module.css";
import buttonStyles from "./../../assets/css/Button.module.css";
import modalStyles from "./../../assets/css/Modal.module.css";

// Component
import BackButton from "./../../components/BackButton";

// Icon
import AccountCircle from '@material-ui/icons/AccountCircle';

export default function RememberApp(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    // const history = useHistory();
    let snackbarJson = {};

    const onSubmit = (data) => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));
        rememberPassword(data.username).then((data) => {
            dispatch(setStatusSpinner({status: false, textProcess: null}));
            const statusCode = data;
            if (statusCode === 200 || statusCode === 0) {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "ขอรหัสผ่านใหม่สำเร็จ",
                    textAlert: "ส่งรหัสผ่านใหม่ ไปยังเบอร์โทรศัพท์ของคุณเเล้ว"
                };

                openBox(snackbarJson);
                props.switchChanel('login');
            }
            else {
                if (statusCode === 2 || statusCode === 4) {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);

                    setTimeout(function () {
                        window.location.href = `/`;
                    }, 5000);
                }
                else {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);
                }
            }
        });
    };

    const renderErrorUsername = () => {
        if (errors.username?.type === "pattern") {
            return (
                <div className="validateError">
                    ยูสเซอร์เนมที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return null;
    };

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    return (

        !props.process ?
            <>
                <div className={modalStyles.backButtonFull} onClick={() => props.switchChanel('login')}>
                    <BackButton></BackButton>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={formStyles.inputPaddingTop}>
                        <br />
                        <div className={formStyles.boxTitle}>
                            ลืมรหัสผ่าน ?
                        </div>
                        <div className={formStyles.inputPaddingTop} style={{ paddingTop: "20px" }}>
                            <div className={formStyles.inputTextLabel}>
                                ยูสเซอร์เนม
                            </div>
                            <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")}>
                                <Grid item xs={2}>
                                    <AccountCircle className={formStyles.iconSize} />
                                </Grid>
                                <Grid item xs={10}>
                                    <input type="text" className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="ยูสเซอร์เนม" {...register("username", { required: true, maxLength: 20, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} maxLength="20" required />
                                </Grid>
                            </Grid>
                            {
                                renderErrorUsername()
                            }
                        </div>
                    </div>

                    <div className="center">
                        <div className={formStyles.inputPaddingTop} style={{ paddingTop: "20px" }}>
                            <button type="submit" className={buttonStyles.btnConfirm}>
                                ขอรหัสผ่านใหม่
                            </button>
                        </div>
                    </div>
                </form>
            </>
            :
            null
    );
}