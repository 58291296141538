import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

// Redux
import { openPromotionPopup } from "./../../../../../../../@core/redux/actions/promotion/index";

// @Utility
import copyText from "./../../../../../../../@utility/app/copyText";
import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";

// CSS
import styles from "./../../../../assets/css/Style.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Component
import Switch from "./../../component/Switch";

export default function BoxResult({ depositItems, reduxPromotionPopup, openBox, promotionItem }) {
    const dispatch = useDispatch();
    const [promotionSwitch, setPromotionSwitch] = useState(false);
    const homeData = useSelector(state => state.reduxHome.result);
    let snackbarJson = {};

    const openPromotion = (data) => {
        dispatch(openPromotionPopup({
            tab: "promotionPopup",
            show: true,
            promotionId: data.id,
            promotionData: data
        }));
    };

    const explodeDecimal = (decimal) => {
        let decimalSplit = decimal.split(".");

        return <>
            {decimalSplit[0]}.<span style={{ color: "#FFE600" }}>{decimalSplit[1]}</span>
        </>
    };

    const copyData = (data) => {
        if(copyText(data)) {
            snackbarJson = {
                statusShow: true,
                statusAlert: "success",
                titleAlert: "แจ้งเตือน",
                textAlert: "คัดลอกสำเร็จ !"
            };

            openBox(snackbarJson);
        }
    };

    useEffect(() => {
        if (promotionSwitch === false) {
            dispatch(openPromotionPopup({
                tab: "promotionPopup",
                show: false,
                promotionId: 0,
                promotionSelect: false,
                promotionData: null
            }));
        }
        else {
            if(promotionItem.length === 1) {
                dispatch(openPromotionPopup({
                    tab: "promotionPopup",
                    show: false,
                    promotionSelect: true,
                    promotionId: promotionItem[0].id,
                    promotionData: promotionItem[0]
                }));
            }
        }
    }, [promotionSwitch]);

    return (
        <>
            <div className={styles.boxDetailTop} style={{ borderBottom: "2px dashed #000000" }}>
                <div className={styles.boxAccountAgentTitle}>
                    จำนวนเงินที่ต้องโอน
                </div>

                <div className={styles.boxMarginTop}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={5}>
                            ยอดทั้งหมด
                        </Grid>
                        <Grid item xs={5}>
                            <div className={`${styles.boxDetailAmountNumber} ${styles.boxDetailAmountNumberText}`}>
                                {
                                    explodeDecimal(currencyFormat(depositItems?.amount, 2))
                                }
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            บาท
                        </Grid>
                    </Grid>
                </div>

                <div className={[styles.boxMarginTop, "center"].join(" ")}>
                    <div className={styles.bankPromotionComment}>
                        ** กรุณาโอนเงินจำนวน <span style={{ fontWeight: "bold", color: "black" }}>{ currencyFormat(depositItems?.amount, 2) }</span> บาท ภายใน 5 นาที **
                    </div>
                </div>
            </div>
            <div className={styles.boxDetailTop}>
                <div className={styles.boxAccountAgentTitle}>
                    โอนเงินมาที่บัญชี
                </div>
                <div className={[styles.boxDetailAmount, styles.boxDetailAgent].join(" ")} style={{ marginTop: "0px" }}>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs={2}>
                            <img src={depositItems?.agent_bankimg} style={{ width: "100%", borderRadius: "12px" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                        </Grid>
                        <Grid item xs={10}>
                            <div className={styles.boxAccountAgentText} style={{ fontWeight: "600" }}>
                                {depositItems?.agent_bankname}
                                <br />
                                ชื่อบัญชี  : {depositItems?.agent_bank_account_name}
                                <br />
                                เลขบัญชี : {depositItems?.agent_bank_account}
                            </div>
                        </Grid>
                    </Grid>
                    <br />
                    <div className="center">
                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnCopy].join(" ")} onClick={() => copyData(depositItems?.agent_bank_account)}>
                            คัดลอกเลขบัญชี
                        </button>
                    </div>
                    <div className={[styles.boxMarginTop, "center"].join(" ")}>
                        <div className={styles.bankPromotionComment}>
                            ** เมื่อโอนเงินเเล้วกรุณากดยืนยันการโอน **
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.boxMarginTop}>
                <div className={styles.boxDetailTop}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <div className={styles.boxAccountAgentTitle}>
                                {
                                    promotionSwitch ?
                                        "รับโปรโมชั่น"
                                        :
                                        "ไม่รับโปรโมชั่น"
                                }
                            </div>
                        </Grid>
                        <Grid item>
                            <Switch
                                isOn={promotionSwitch}
                                onColor="#38C172"
                                handleToggle={() => setPromotionSwitch(!promotionSwitch)}
                            />
                        </Grid>
                    </Grid>
                    {
                        promotionSwitch && (
                            homeData.balance <= process.env.REACT_APP_BALANCE_LIMIT ?
                            <>
                                <div className={styles.boxMarginTop}>
                                    <div className={styles.boxAccountAgentSub}>
                                        โปรโมชั่นเเนะนำสำหรับคุณ (กรุณากดเลือกรับโปรโมชั่น)
                                    </div>
                                </div>
                                <div className={styles.boxMarginTop}>
                                    {
                                        promotionItem !== null && (
                                            promotionItem.length > 0 ?
                                                <div className={[styles.menuPositionWidthScroll].join(" ")} style={{ marginLeft: "0px", marginRight: "0px", paddingBottom: "10px" }}>
                                                    <div className="flexContent">
                                                        {
                                                            promotionItem.map((item) => {
                                                                return (
                                                                    (reduxPromotionPopup.promotionSelect && reduxPromotionPopup.promotionId === item.id) ?
                                                                        <div key={item.id} className="relative" onClick={() => { openPromotion(item) }}>
                                                                            <img className={reduxPromotionPopup.promotionId === item.id ? styles.imgPromotionListBorder : styles.imgPromotionList} src={item.content_picture} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม"  />
                                                                            <div className={buttonStyles.btnActionPosition}>
                                                                                <div className={[buttonStyles.btnAction, buttonStyles.btnCancelPromotion].join(" ")}>
                                                                                    ยกเลิกรับโปรโมชั่น
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div key={item.id} className="relative" onClick={() => { openPromotion(item) }}>
                                                                            <img className={styles.imgPromotionList} src={item.content_picture} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม"/>
                                                                            <div className={buttonStyles.btnActionPosition}>
                                                                                <div className={[buttonStyles.btnAction, buttonStyles.btnSelectPromotion].join(" ")}>
                                                                                    เลือกรับโปรโมชั่น
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="center">
                                                    <img className={styles.imgPromotionNone} src="/images/game/promotion_none.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                </div>
                                        )
                                    }
                                </div>
                            </>
                            :
                            <div className={styles.boxMarginTop}>
                                <div className={styles.bankPromotionComment}>
                                    ไม่สามารถเลือกโปรโมชั่นได้ เนื่องจากเครดิตของคุณมีมากกว่า {process.env.REACT_APP_BALANCE_LIMIT} เครดิต
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}