import {
    SET_CAMP_ALL,
    SET_CATEGORY_ALL,
    SET_GAME_ALL,
    SET_GAME_LAST_ALL,
    SET_GAME_HIT_ALL,
    SET_GAME_JACKPOT_ALL,
    SET_TYPE_ALL,
    SET_PROCESSING_GAME,
    SET_GAME_HOT_ALL,
    SET_POPUP_GAME_DATA
} from './../../actions/game/index';

const initialState = {
    itemsCampList: [],
    itemsCategoryList: [],
    itemsGameList: false,
    itemsGameLastList: [],
    itemsGameHitList: [],
    itemsGameJackpotList: [],
    itemsTypeList: null,
    processingGame: false,
    itemsGameHotList: [],
    popupGameData: {
        show: false,
        camp: null,
        gamecode: null
    }
    // itemCampType: {
    //     itemsTypeList: null,
    //     specials: ""
    // }
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_CAMP_ALL:
            return { ...state, itemsCampList: action.payload.items };
        case SET_CATEGORY_ALL:
            return { ...state, itemsCategoryList: action.payload.items };
        case SET_GAME_ALL:
            return { ...state, itemsGameList: action.payload.items };
        case SET_GAME_LAST_ALL:
            return { ...state, itemsGameLastList: action.payload.items };
        case SET_GAME_HIT_ALL:
            return { ...state, itemsGameHitList: action.payload.items };
        case SET_GAME_JACKPOT_ALL:
            return { ...state, itemsGameJackpotList: action.payload.items };
        case SET_TYPE_ALL:
            return { ...state, itemsTypeList: action.payload.items };
        case SET_PROCESSING_GAME:
            return { ...state, processingGame: action.payload.items };
        case SET_GAME_HOT_ALL:
            return { ...state, itemsGameHotList: action.payload.items };
        case SET_POPUP_GAME_DATA:
            return { ...state, popupGameData: action.payload.items };
        default:
            return state;
    }
}