import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Icon
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// CSS
import buttonStyles from "./../../../assets/css/Button.module.css";

export default function GameNone({ gameType, gameImgUrl, gameTitle, gameDetail, gameRedirect }) {
    const history = useHistory();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles((theme) => ({
        boxGameLikeNone: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            // border: "2px solid #8A2EFF",
            boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
            display: "inline-block",
            padding: "40px",
            borderRadius: "10px",
            width: "340px",
            [theme.breakpoints.down('xs')]: {
                width: "80%",
                padding: "18px"
            }
        },
        boxGameNone: {
            textAlign: "center"
        },
        gameLikeTitle: {
            fontSize: "20px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-main"] : "black",
            marginBottom: "10px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "18px"
            }
        },
        gameTitle: {
            fontSize: "24px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-main"] : "black",
            marginBottom: "10px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "18px"
            }
        },
        gameLikeDetail: {
            fontSize: "16px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-main"] : "black",
            [theme.breakpoints.down('xs')]: {
                fontSize: "12px"
            }
        },
        gameLikeImgPosition: {
            margin: "40px 0px"
        },
        gameLikeImg: {
            width: "280px",
            height: "auto",
            borderRadius: "12px",
            [theme.breakpoints.down('sm')]: {
                width: "160px",
            }
        },
        boxGameLikeNonePosition: {
            margin: "40px 0px",
            [theme.breakpoints.down('xs')]: {
                margin: "20px 0px",
            }
        },
        iconSize: {
            marginTop: "8px",
            width: "28px",
            height: "28px"
        },
        boxMargin: {
            marginTop: "50px"
        },
        heartContentPosition: {
            position: "absolute",
            top: "-26px",
            right: "14px",
            [theme.breakpoints.down('xs')]: {
                top: "-24px",
                right: "34px",
            }
        },
        heartContentAlign: {
            border: "2px solid #FF0000",
            borderRadius: "40px",
            padding: "14px 8px 0px 8px"
        },
        heartContent: {
            width: "44px",
            height: "40px",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                width: "34px",
                height: "30px",
            }
        },
    }));

    const classes = useStyles();

    const gotoPage = () => {
        history.push(gameRedirect);
    };

    useEffect(() => { }, [gameRedirect]);

    return (
        <div className={classes.boxGameLikeNonePosition}>
            {
                gameType === "like" ?
                    <div className={classes.boxGameLikeNone}>
                        <div className={classes.gameLikeTitle}>
                            {gameTitle}
                        </div>
                        <div className={classes.gameLikeDetail}>
                            {gameDetail}
                        </div>
                        <div className={[classes.gameLikeImgPosition, "relative"].join(" ")}>
                            <img src={gameImgUrl} className={classes.gameLikeImg} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            <div className={classes.heartContentPosition}>
                                <div className={classes.heartContentAlign}>
                                    <img src="/images/icon/hearth_not_active.webp" className={classes.heartContent} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                </div>
                            </div>
                        </div>
                        <div className={[buttonStyles.btnRedirect, classes.boxMargin].join(" ")} onClick={gotoPage}>
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item>
                                    ไปยังหน้าค่ายเกม
                                </Grid>
                                <Grid item>
                                    <ArrowForwardIcon className={classes.iconSize}></ArrowForwardIcon>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    :
                    <div className={classes.boxGameNone}>
                        <div className={classes.gameLikeTitle}>
                            {gameTitle}
                        </div>
                        <div className={classes.gameLikeImgPosition}>
                            <img src={gameImgUrl} className={classes.gameLikeImg} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                        </div>
                    </div>
            }

        </div>
    );
}