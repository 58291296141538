import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// import ArrowLeft from '@material-ui/icons/ArrowLeft'
// import ArrowRight from '@material-ui/icons/ArrowRight'
// import ButtonBase from '@material-ui/core/ButtonBase'

// Check Auth
import { isUserLoggedIn } from './../../../../../../../@core/auth/utils'

// @Utility
import filterImages from './../../../../../../../@utility/app/filterImages'
import useWindowSize from './../../../../../../../@utility/hooks/useWindowSize'

// Redux
import {
    openGetCredit,
    openInviteFriend,
} from './../../../../../../../@core/redux/actions/affiliate/index'
import { setHomeMenuType } from './../../../../../../../@core/redux/actions/home/index'
import { setDataAlert } from './../../../../../../../@core/redux/actions/app/alert'
import { setOpenWheelStatus } from "./../../../../../../../@core/redux/actions/app/wheel";

// Component
import ProgressiveImage from "./../../../../components/ProgressiveImage";

// CSS
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './../../../../assets/css/Style.module.css'

export default function MenuHomeContent() {
    // const homeProcessing = useSelector(state => state.reduxAppProcessing.home);
    const dispatch = useDispatch()
    const dataSetting = useSelector((state) => state.reduxAppMenu.menu_items)
    // const imagesProcessing = useSelector(
    //     (state) => state.reduxAppProcessing.images
    // )
    const reduxAppScrollPosition = useSelector(
        (state) => state.reduxAppScrollPosition.top
    )
    const { screenWidth } = useWindowSize()
    const history = useHistory()
    const [loadContentHome, setLoadContentHome] = useState(false)
    const [dataList, setDataList] = useState(null)
    const [eventList, setEventList] = useState(null)
    // const dataList = [
    //     {
    //         "id": 1,
    //         // "redirect": "/games/camp/m8bet/S-m8bet?play_game=true",
    //         "redirect": null,
    //         "image": "/images/home/slide/01.webp",
    //         "status": 2
    //         // "page_name": "ร้านค้า"
    //     },
    //     {
    //         "id": 2,
    //         // "redirect": "/games/camp/sexybaccarat/none?play_game=true",
    //         "redirect": null,
    //         "image": "/images/home/slide/02.webp",
    //         "status": 2
    //         // "page_name": "ร้านค้า"
    //     },
    //     // {
    //     //     "id": 3,
    //     //     // "redirect": "/games/camp/pgsoft",
    //     //     "redirect": null,
    //     //     "image": "/images/home/slide/03.webp",
    //     //     "status": 2
    //     //     // "page_name": "ร้านค้า"
    //     // },
    //     // {
    //     //     "id": 4,
    //     //     // "redirect": "/games/camp/joker",
    //     //     "redirect": null,
    //     //     "image": "/images/home/slide/04.webp",
    //     //     "status": 2
    //     //     // "page_name": "ร้านค้า"
    //     // },
    //     {
    //         "id": 5,
    //         // "redirect": "/games/camp/kingmaker",
    //         "redirect": null,
    //         "image": "/images/home/slide/05.webp",
    //         "status": 2
    //         // "page_name": "ร้านค้า"
    //     },
    //     {
    //         "id": 6,
    //         // "redirect": "/games/camp/pakyok/S-pakyok?play_game=true",
    //         "redirect": null,
    //         "image": "/images/home/slide/06.webp",
    //         "status": 2
    //         // "page_name": "ร้านค้า"
    //     }
    // ];

    // const eventList = [
    //     // {
    //     //     "id": 1,
    //     //     // "redirect": "/games/camp/m8bet/S-m8bet?play_game=true",
    //     //     "redirect": null,
    //     //     "image": "/images/event/01.webp",
    //     //     "status": 2
    //     //     // "page_name": "ร้านค้า"
    //     // },
    //     {
    //         "id": 2,
    //         // "redirect": "/games/camp/sexybaccarat/none?play_game=true",
    //         "redirect": null,
    //         "image": "/images/event/02.webp",
    //         "status": 2
    //         // "page_name": "ร้านค้า"
    //     },
    //     {
    //         "id": 3,
    //         // "redirect": "/games/camp/sexybaccarat/none?play_game=true",
    //         "redirect": null,
    //         "image": "/images/event/03.webp",
    //         "status": 2
    //         // "page_name": "ร้านค้า"
    //     },
    //     {
    //         "id": 4,
    //         // "redirect": "/games/camp/sexybaccarat/none?play_game=true",
    //         "redirect": null,
    //         "image": "/images/event/04.webp",
    //         "status": 2
    //         // "page_name": "ร้านค้า"
    //     }
    // ];

    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))

    const settings = {
        className: 'slider variable-width',
        infinite: true,
        // fade: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 4000,
        arrows: isLargeScreen ? true : false,
        variableWidth: true,
    }

    const settingsVideo = {
        infinite: true,
        // fade: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 6000,
        arrows: isLargeScreen ? true : false,
        variableWidth: true,
    }

    const useStyles = makeStyles((theme) => ({
        cardCoverCamp: {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        textPadding: {
            paddingLeft: '10px',
        },
        slideSize: {
            width: '580px !important',
            height: '100%',
            [theme.breakpoints.down('xs')]: {
                width: `${screenWidth - 150}px !important`,
            },
        },
        slideFull: {
            width: '580px !important',
            height: '100%',
            [theme.breakpoints.down('xs')]: {
                width: `${screenWidth - 150}px !important`,
            },
        },
        cardVideo: {
            width: '100%',
            height: '320px',
            boxSizing: 'border-box',
            borderRadius: '10px',
            border: '0px',
            [theme.breakpoints.down('xs')]: {
                height: `${screenWidth / 2.6}px !important`,
            },
        },
    }))

    const classes = useStyles()

    const gotoPage = (item) => {
        let page = item.image_link
        let typeAction = item.image_link_type

        if (typeAction === 4) {
            if (page === "affiliate") {
                if(isUserLoggedIn() === false) {
                    openBox("รับทรัพย์");
                }
                else {
                    dispatch(openGetCredit({
                        tab: "affiliate",
                        show: true,
                        type: null
                    }));
                }
            }
            else if (page === "inviteFrined") {
                if(isUserLoggedIn() === false) {
                    openBox("เชิญเพื่อน");
                }
                else {
                    dispatch(openInviteFriend({
                        show: true
                    }));
                }
            }
            else if (page === "wheelSpin") {
                if(isUserLoggedIn() === false) {
                    openBox("กงล้อลุ้นโชค");
                }
                else {
                    dispatch(setOpenWheelStatus(true));
                }
            }
        } else if (typeAction === 3) {
            let menuList = dataSetting.menu_camp
            let dataObj = {}

            dataObj = menuList.find((item, index) => {
                dataObj['id'] = index
                return item.code === page
            })

            if (dataObj !== undefined && dataObj !== null) {
                dispatch(
                    setHomeMenuType({
                        id: dataObj.id - 1,
                        renderPage: dataObj.code,
                        pageTitle: dataObj.title,
                        game_camp_image: dataObj.game_camp_image,
                        column: {
                            desktop: dataObj.column_desktop,
                            mobile: dataObj.column_mobile,
                        },
                        typeGame: dataObj.game_camp_type,
                    })
                )

                window.scrollTo({
                    top: reduxAppScrollPosition,
                    left: 0,
                    behavior: 'smooth',
                })
            }
        } else if (typeAction === 1) {
            if (page !== null && page !== '') {
                window.location.href = page
            }
        } else {
            if (page !== null && page !== '') {
                history.push(page)
            }
        }
    }

    const openBox = (page) => {
        dispatch(
            setDataAlert({
                type: 'warning',
                title: 'แจ้งเตือน',
                text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "${page}"`,
                action: true,
                redirect: '/login',
                show: true,
                buttonText: 'ตกลง',
            })
        )
    }

    useEffect(() => {
        setLoadContentHome(true)
    }, [])

    useEffect(() => {
        if (dataSetting.agent_image !== null) {
            const dataImagesList = filterImages(
                dataSetting.agent_image,
                'coming-on'
            )

            setDataList(dataImagesList)
            
            const dataImagesEvent = filterImages(
                dataSetting.agent_image,
                'event'
            )
            
            setEventList(dataImagesEvent)
        }
    }, [dataSetting.agent_image])

    // console.log(dataList)
    return (
        loadContentHome && (
            <div className="left">
                <div>
                    <div
                        className={[
                            styles.cardOtherListHeadText,
                            classes.textPadding,
                        ].join(' ')}
                    >
                        กำลังมาแรง
                    </div>

                    <div className={styles.boxMarginMediumTop}>
                        {dataList !== null && (
                            <Slider {...settings}>
                                {
                                    dataList.map((item) => {
                                        return (
                                            // item.redirect !== null ?
                                            <div
                                                key={item.id}
                                                className={
                                                    classes.slideSize
                                                }
                                            >
                                                <div
                                                    style={{
                                                        padding:
                                                            '0px 6px 0px 0px',
                                                    }}
                                                >
                                                    <ProgressiveImage className={classes.cardCoverCamp} placeholder="/images/bluring/400x240.jpg" src={item.image_picture} onClick={() => { gotoPage(item) }}></ProgressiveImage>
                                                </div>
                                            </div>
                                        )
                                    })
                                }  
                            </Slider>
                        )}
                    </div>
                </div>

                <div className={styles.boxMarginFormTop}>
                    <div
                        className={[
                            styles.cardOtherListHeadText,
                            classes.textPadding,
                        ].join(' ')}
                    >
                        อีเวนต์
                    </div>

                    <div className={styles.boxMarginMediumTop}>
                        {
                            eventList !== null  && (
                                <Slider {...settingsVideo}>
                                    {eventList.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                className={classes.slideSize}
                                            >
                                                <div
                                                    style={{
                                                        padding: '0px 6px 0px 0px',
                                                    }}
                                                >
                                                    <ProgressiveImage className={classes.cardCoverCamp} placeholder="/images/bluring/400x280.jpg" src={item.image_picture} onClick={() => { gotoPage(item) }}></ProgressiveImage>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    )
}
