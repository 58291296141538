import { useEffect, useState } from "react";

// Hooks
import { useAddToHomescreenPrompt } from "../../../../@utility/hooks/useAddToHomescreenPrompt";

// Component
import { Grid } from "@material-ui/core";
import ProgressiveImage from "./ProgressiveImage";

// CSS;
import buttonStyles from "../assets/css/Button.module.css";

export default function NotificationSubscription({
  notificationStatus,
  configInstallPWA,
  setStorage,
}) {
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  // const applicationServerKeyData = 'BIVW5jjodYeBNcX5fO1S9CinSgldVY0JTbFRCyZ_auqrxL2BJOXlXiSE8nEdRk2BiCneGHMTwSqe3cAN5LlEXs4';
  let initializedEvent = false;
    console.log("notificationStatus: ", notificationStatus);
  const handleSubscribeClick = async () => {
    promptToInstall();
    setStorage(["notificationStatus"], true);
    setIsAppInstalled(true);
  };

  const handleCancelClick = () => {
    setStorage(["notificationStatus"], true);
    setIsAppInstalled(true);
  };

  const isPWAInstalled = async () => {
    try {
      const relatedApps = await navigator.getInstalledRelatedApps();
      const installed = relatedApps.find(
        (app) => app.url === configInstallPWA.data.url
      );
      if (installed !== undefined) {
        setIsAppInstalled(true);
        setStorage(["notificationStatus"], true);
      } else {
        setIsAppInstalled(false);
      }
    } catch (error) {
      setIsAppInstalled(true);
      setStorage(["notificationStatus"], true);
    }
  };

  useEffect(() => {
    // ดัก Event ให้ทำงานก่อนเขียน Component
    if (!initializedEvent) {
      initializedEvent = true;

      // ตรวจสอบบราวเซอร์รองรับการติดตั้งแอพที่หน้าจอโฮม
      if ("getInstalledRelatedApps" in window.navigator) {
        if (prompt === null) {
          isPWAInstalled(prompt);
        }
      } else {
        setStorage(["notificationStatus"], true);
        setIsAppInstalled(true);
      }
    }
  }, [prompt]);

  return (
    prompt !== null &&
    !isAppInstalled &&
    notificationStatus && (
      <div className="pwaPopupInstallAppDesktop">
       <div className="positionFixed topLeft leftCenter traslateXCenter zIndexLarge zIndexMax">
           <div className='pwaPopupInstallApp displayInlineBlock leftCenter'>
              <Grid container spacing={1}>
                  <Grid item xs={3}>
                      <ProgressiveImage className="pwaIconSize"  placeholder="" src={configInstallPWA?.icon} />
                  </Grid>
                  <Grid item xs={9}>
                      <div className="left pwaIconTitle textBoldMedium">
                          {configInstallPWA?.text}
                      </div>
                  </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                      <button className={[buttonStyles.btnPWA, buttonStyles.btnPWACancel].join(" ")} onClick={handleCancelClick}>ยกเลิก</button>
                  </Grid>
                  <Grid item xs={4}>
                      <button className={[buttonStyles.btnPWA, buttonStyles.btnPWAInstall].join(" ")} onClick={handleSubscribeClick}>ติดตั้งแอพ</button>
                  </Grid>
              </Grid>
              </div>
          </div>
        </div>
      
    )
  );
}
