import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// auth
import { isUserLoggedIn } from "./../../../../../../@core/auth/utils";

// @utility
import alertText from "./../../../../../../@utility/app/alertText";
import currencyFormat from "./../../../../../../@utility/numbers/currencyFormat";

// Services
import {
    likeGame,
    // loadGameAll, 
    // loadGameAnalyzeAll, 
    loadGameLikeAll
} from "../../../../../../@core/services/gameService";

// Redux
import { setStatusSpinner } from "./../../../../../../@core/redux/actions/app/spinner";
import { setDataDialog } from "./../../../../../../@core/redux/actions/app/dialog";
import { setDataAlert } from "./../../../../../../@core/redux/actions/app/alert";

// Component
import ProgressiveImage from "./../../../components/ProgressiveImage";
import SnackbarSet from "./../../../components/SnackbarSet";
import GameNone from "./GameNone";
import OpenGame from "./../../../components/OpenGame";

// Icon
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// CSS
import styles from "./../../../assets/css/Style.module.css";
import bagdeStyles from "./../../../assets/css/Bagde.module.css";
import buttonStyles from "./../../../assets/css/Button.module.css";

export default function GameList({ campType, dataGame, typePage, typeSelect }) {
    const [gameList, setGameList] = useState([]);
    const [pageGame, setPageGame] = useState(null);
    const [hearthStatus, setHearthStatus] = useState(null);
    // const history = useHistory();
    const childRef = useRef();
    const dispatch = useDispatch();
    let snackbarJson = {};
    const homeData = useSelector(state => state.reduxHome.result);
    const [checkPromotion, setCheckPromotion] = useState(null);
    const gameRef = useRef();

    const useStyles = makeStyles((theme) => ({
        contentGameGrid: {
            position: "relative",
            borderRadius: "8px",
            cursor: "pointer",
            display: "inline-block",
            width: "100%",
            '&:hover': {
                top: "4px"
            }
        },
        gameListShape: {
            width: "100%",
            height: "100%",
            borderRadius: "16px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "10px"
            }
        },
        heartContentPosition: {
            position: "absolute",
            top: "-6px",
            right: "-6px"
        },
        heartContent: {
            width: "44px",
            height: "auto",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                width: "34px"
            }
        },
        contentGamePosition: {},
        contentGameAnalyze: {
            position: "relative"
        },
        gameAnalyzeListShape: {
            width: "100%",
            height: "100%",
            borderRadius: "16px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "10px"
            }
        },
        gameAnalyze: {
            background: "linear-gradient(169.65deg, #FFF960 -34.09%, #FF8C22 100.99%)",
            boxSizing: " border-box",
            boxShadow: "0px 7px 0px #23004F",
            borderRadius: "10px",
            margin: "12px 0px",
            width: "100%",
            padding: "10px 0px 6px 0px",
            position: "relative",
            zIndex: "500",
            fontSize: "18px",
            fontWeight: "600",
            '&::after': {
                content: "''",
                display: "block",
                position: "absolute",
                border: "2px solid #23004F",
                borderRight: "0px",
                top: "0px",
                left: "-4px",
                width: "80%",
                height: "100%",
                borderRadius: "14px 0 0 10px",
                transform: "skewX(12deg)",
                background: "linear-gradient(169.65deg, #FFF960 -34.09%, #FF8C22 100.99%)",
                zIndex: "-1"
            },
            '&::before': {
                content: "''",
                display: "block",
                position: "absolute",
                border: "2px solid #23004F",
                borderLeft: "0px",
                top: "0px",
                right: "-6px",
                width: "80%",
                height: "100%",
                borderRadius: "0 14px 10px 0",
                transform: "skewX(-12deg)",
                background: "linear-gradient(169.65deg, #FFF960 -34.09%, #FF8C22 100.99%)",
                zIndex: "-1"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px",
            }
        },
        gameAnalyzeNumber: {
            background: "#23004F",
            color: "white",
            fontWeight: "400",
            borderRadius: "8px",
            padding: "2px 0px",
            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
        },
        analyzeName: {
            fontSize: "18px",
            color: "#dddcdc",
            [theme.breakpoints.down('sm')]: {
                marginTop: "-8px",
                fontSize: "12px"
            }
        },
        analyzeNumber: {
            fontSize: "20px",
            color: "rgb(255, 193, 7)",
            fontWeight: "bold",
            [theme.breakpoints.down('sm')]: {
                fontSize: "14px"
            }
        },
        contentGameList: {
            overflow: "scroll",
            marginBottom: "40px",
            color: "white"
        },
        itemList: {
            padding: "20px",
            border: "2px solid #8A2EFF",
            marginBottom: "20px",
            background: "linear-gradient(180deg, #38058E 0%, #1A0046 100%)",
            borderRadius: "15px",
            [theme.breakpoints.down('xs')]: {
                padding: "10px"
            }
        },
        gameListJackPotCustoemr: {
            width: "100%",
            height: "100%",
            borderRadius: "14px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "10px"
            }
        },
        contentGameListTextGameName: {
            fontSize: "20px",
            fontWeight: "500",
            color: "#e1d424",
            [theme.breakpoints.down('sm')]: {
                fontSize: "16px"
            }
        },
        contentGameListTextDetail: {
            fontSize: "16px",
            color: "#dddcdc",
            [theme.breakpoints.down('sm')]: {
                fontSize: "10px"
            }
        },
        btnPlayGame: {
            position: "relative",
            background: "#f12425",
            padding: "8px 16px",
            fontSize: "18px",
            borderRadius: "8px",
            cursor: "pointer",
            '&:hover': {
                top: "2px",
                background: "#c20001"
            },
            [theme.breakpoints.down('sm')]: {
                padding: "6px 8px",
                fontSize: "12px",
                borderRadius: "4px",
            }
        },
        boxGameLikeNone: {
            background: "rgba(0, 41, 148, 0.4)",
            border: "1px solid rgba(255, 255, 255, 0.5)",
            boxShadow: "inset 0px 0px 16px #1F43FF",
            display: "inline-block",
            padding: "26px",
            borderRadius: "10px",
            [theme.breakpoints.down('sm')]: {
                padding: "18px"
            }
        },
        gameLikeTitle: {
            fontSize: "28px",
            color: "rgb(255, 193, 7)",
            marginBottom: "10px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "20px"
            }
        },
        gameLikeDetail: {
            fontSize: "18px",
            color: "white",
            [theme.breakpoints.down('sm')]: {
                fontSize: "16px"
            }
        },
        gameLikeImgPosition: {
            margin: "20px 0px"
        },
        gameLikeImg: {
            width: "280px",
            height: "auto",
            borderRadius: "12px",
            [theme.breakpoints.down('sm')]: {
                width: "160px",
            }
        },
        boxGameLikeNonePosition: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            margin: "0px 10px",
            [theme.breakpoints.down('xs')]: {
                left: "0",
                transform: "translateY(-50%)"
            }
        },
        boxMargin: {
            marginTop: "30px",
            width: "300px",
            [theme.breakpoints.down('xs')]: {
                marginTop: "14px",
                width: "100%"
            }
        },
        iconSize: {
            marginTop: "8px",
            width: "24px",
            height: "24px",
            [theme.breakpoints.down('xs')]: {
                marginTop: "4px",
                width: "18px",
                height: "18px"
            }
        }
    }));

    const classes = useStyles();

    // const loadGame = (item) => {
    //     history.push(`/games/camp/${item.camp.toLowerCase()}/${item.code}?play_game=true&redirect=other`);
    // };

    const openBox = (item) => {
        let dialogJson = {
            pageType: "openGame",
            statusShow: true,
            type: "html",
            image: `${window.location.protocol}//${window.location.host}/images/icon/bell.png`,
            title: "โหมดทดลองเล่นเกม",
            content: `ยอดที่ได้รับจะไม่สามารถนำถอนเงินได้ หากต้องการวางเดิมพันกรุณา`,
            action: `/games/camp/${item.camp.toLowerCase()}/${item.code}?play_game=true`
        };

        dispatch(setDataDialog(dialogJson));
    };

    const openGame = (item) => {
        let statusHorizontal = true;
        if (item.camp === "PGSoft") {
            statusHorizontal = false;
        }

        if (isUserLoggedIn()) {
            gameRef.current.loadGame(item, "game");
            // loadGame(item, statusHorizontal);
        }
        else {
            openBox(item, statusHorizontal);
        }
    };

    const clickLikeGame = (item, index, statusActive) => {
        if (isUserLoggedIn()) {
            let myJson = {};

            if (typeSelect === "like_game") {
                myJson = {
                    gameId: Number(item.game_id),
                    statusLike: false
                };
            }
            else {
                if (item.like === false) {
                    myJson = {
                        gameId: Number(item.id),
                        statusLike: true
                    };
                }
                else {
                    myJson = {
                        gameId: Number(item.id),
                        statusLike: false
                    };
                }
            }

            if (typeSelect !== "like_game") {
                let gameListBackup = gameList;
                if (pageGame === "gameLast") {
                    if (statusActive) {
                        gameListBackup[index].game.like = true;
                    }
                    else {
                        gameListBackup[index].game.like = false;
                    }
                }
                else {
                    if (statusActive) {
                        gameListBackup[index].like = true;
                    }
                    else {
                        gameListBackup[index].like = false;
                    }
                }

                setGameList(gameListBackup);
                setHearthStatus(statusActive);
            }
            else {
                dispatch(setStatusSpinner({status: true, textProcess: null}));
            }

            likeGame(myJson).then((data) => {
                const statusCode = data.code;
                if (statusCode === 0 || statusCode === 200) {
                    setHearthStatus(null);
                    if (typeSelect === "like_game") {
                        loadGameLikeAll().then((result) => {
                            dispatch(setStatusSpinner({status: false, textProcess: null}));
                            setGameList(result === null ? [] : result);
                        });
                    }
                }
                else {
                    snackbarJson = alertText(statusCode);
                    childRef.current.snackbar(snackbarJson);
                }
            });
        }
        else {
            dispatch(setDataAlert({
                type: "warning",
                title: "แจ้งเตือน",
                text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "เพิ่มที่เกมชื่นชอบ"`,
                action: true,
                redirect: "/login",
                show: true,
                buttonText: "ตกลง"
            }));
        }
    };

    const renderBagde = (item, type) => {
        if (item.status === 3) {
            return (
                <div className={type === "gameList" ? bagdeStyles.ribbonPosition : bagdeStyles.ribbonPositionAnalyze}>
                    <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonDanger].join(" ")}>
                        ปิดปรับปรุง
                    </div>
                </div>
            );
        }
        else if (item.status === 2) {
            if (item.spin === 1 || item.promotion === 1) {
                if (checkPromotion === 1 && item.promotion === 1) {
                    return (
                        <div className={type === "gameList" ? bagdeStyles.ribbonPosition : bagdeStyles.ribbonPositionAnalyze}>
                            <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonDanger].join(" ")}>
                                ติดโปรโมชั่น
                            </div>
                        </div>
                    );
                }
                else if (item.spin === 1) {
                    return (
                        <div className={type === "gameList" ? bagdeStyles.ribbonPosition : bagdeStyles.ribbonPositionAnalyze}>
                            <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonWarning].join(" ")}>
                                ซื้อฟรีสปิน
                            </div>
                        </div>
                    );
                }
            }
            else {
                if (item.specials !== "") {
                    let specialType = item.specials.split(",");

                    if (specialType.length > 1) {
                        return (
                            <div className={type === "gameList" ? bagdeStyles.ribbonPosition : bagdeStyles.ribbonPositionAnalyze}>
                                <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonPrimary].join(" ")}>
                                    มาใหม่
                                </div>
                            </div>
                        );
                    }
                    else {
                        let selectTypeNew = specialType.find((str) => str === "new");
                        let selectTypeHot = specialType.find((str) => str === "hot");

                        if (selectTypeNew != undefined) {
                            return (
                                <div className={type === "gameList" ? bagdeStyles.ribbonPosition : bagdeStyles.ribbonPositionAnalyze}>
                                    <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonPrimary].join(" ")}>
                                        มาใหม่
                                    </div>
                                </div>
                            );
                        }

                        if (selectTypeHot != undefined) {
                            return (
                                <div className={type === "gameList" ? bagdeStyles.ribbonPosition : bagdeStyles.ribbonPositionAnalyze}>
                                    <div className={[bagdeStyles.ribbon, bagdeStyles.ribbonYellow].join(" ")}>
                                        มาแรง
                                    </div>
                                </div>
                            );
                        }
                    }
                }
            }
        }
        else {
            return null;
        }
    };

    const renderListData = (item, index) => {
        return (
            <div className={classes.contentGameGrid}>
                {
                    renderBagde(item, "gameList")
                }
                <div className={classes.contentGamePosition} style={{ opacity: ((item.status === 3 || (checkPromotion === 1 && item.promotion === 1)) ? "0.7" : "1") }}>
                    <div style={{ position: "relative" }}>
                        <ProgressiveImage 
                            placeholder="/images/bluring/215x280.jpg" 
                            className={classes.gameListShape}
                            src={`${item.image}`}
                            onClick={() => (item.status === 3 || (checkPromotion === 1 && item.promotion === 1)) ? null : openGame(item)}
                        ></ProgressiveImage>
                        <div className={classes.heartContentPosition}>
                            {
                                renderHearth(item, index)
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderGameList = () => {
        if (gameList !== null) {
            if (typeSelect === "jackpot_customer") {
                if (gameList.length <= 0) {
                    return <GameNone gameType="other" gameImgUrl="/images/game/no_game.webp" gameTitle="ไม่มีข้อมูลเกม" gameDetail="null" gameRedirect="null"></GameNone>;
                }
                else {
                    return (
                        <div className={classes.contentGameList}>
                            {
                                gameList.map((item, index) => {
                                    return (
                                        <div key={`${item.id + index}`} className={classes.itemList}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={6} sm={4}>
                                                    <img src={`${item.image}`} className={classes.gameListJackPotCustoemr} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                </Grid>
                                                <Grid item xs={6} sm={8}>
                                                    <div className="left">
                                                        <div className={[classes.contentGameListTextGameName, "chakra"].join(" ")}>
                                                            {item.game_name}
                                                        </div>
                                                        <div className={classes.contentGameListTextDetail}>
                                                            <div>
                                                                วันที่แตก : <span style={{ color: "white" }}>{item.time_create}</span>
                                                            </div>
                                                            <div>
                                                                ยูสเซอร์เนม : <span style={{ color: "white" }}>{item.customer_name}</span>
                                                            </div>
                                                            <div>
                                                                จำนวนเท่าที่แตก : <span style={{ color: "white" }}>{currencyFormat(item.jackpot, 0)}</span> เท่า
                                                            </div>
                                                        </div>
                                                        <div className={[buttonStyles.btnRedirect, classes.boxMargin].join(" ")} onClick={() => (item.status === 3 || (checkPromotion === 1 && item.promotion === 1)) ? null : openGame(item)}>
                                                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                                <Grid item>
                                                                    เล่นทันที
                                                                </Grid>
                                                                <Grid item>
                                                                    <ArrowForwardIcon className={classes.iconSize}></ArrowForwardIcon>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    );
                }
            }
            else if (typeSelect === "like_game") {
                if (gameList.length <= 0) {
                    return <GameNone gameType="like" gameImgUrl="/images/game/preview_likegame.webp" gameTitle="คุณยังไม่มีเกมที่ชื่นชอบ" gameDetail="เพิ่มเกมที่ชื่นชอบของคุณได้ เพียงกดที่ ไอคอนรูปหัวใจในรายเกมต่าง ๆ ได้เลย" gameRedirect="/games/camp"></GameNone>;
                }
                else {
                    return (
                        <Grid container spacing={1}>
                            {
                                gameList.map(item => {
                                    return (
                                        <Grid item xs={4} sm={3} lg={2} key={`${item.id}`} >
                                            <div className={classes.contentGameGrid}>
                                                {
                                                    renderBagde(item.game, "gameList")
                                                }
                                                <div className={classes.contentGamePosition} style={{ opacity: ((item.status === 3 || (checkPromotion === 1 && item.promotion === 1)) ? "0.7" : "1") }}>
                                                    <div style={{ position: "relative" }}>
                                                        <ProgressiveImage 
                                                            placeholder="/images/bluring/215x280.jpg" 
                                                            className={classes.gameListShape}
                                                            src={`${item.game.image}`}
                                                            onClick={() => (item.status === 3 || (checkPromotion === 1 && item.promotion === 1)) ? null : openGame(item)}
                                                        ></ProgressiveImage>
                                                        <div className={classes.heartContentPosition}>
                                                            {
                                                                renderHearth(item)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    );
                }
            }
            else {
                if (gameList.length <= 0) {
                    return <GameNone gameType="other" gameImgUrl={pageGame === "search" ? "/images/game/no_game.webp" : "/images/game/no_game.webp"} gameTitle={pageGame === "search" ? "ไม่มีข้อมูลเกมที่คุณค้นหา" : "ไม่มีข้อมูลเกม"} gameDetail="null" gameRedirect="null"></GameNone>;
                }
                else {
                    return (
                        <Grid container spacing={2}>
                            {
                                gameList.map((item, index) => {
                                    return (
                                        <Grid item xs={4} sm={3} lg={2} key={`${item.id}`} >
                                            {
                                                pageGame !== null && (
                                                    pageGame === "gameLast" ?
                                                        renderListData(item.game, index)
                                                        :
                                                        (pageGame === "camp" || pageGame === "search" || pageGame === "gameAll") ?
                                                            renderListData(item, index)
                                                            :
                                                            <div className={classes.contentGameAnalyze}>
                                                                {
                                                                    renderBagde(item, "analyze")
                                                                }
                                                                <div className={classes.bgAnalyzeShape}>
                                                                    <div style={{ position: "relative" }}>
                                                                        <ProgressiveImage 
                                                                            placeholder="/images/bluring/215x280.jpg" 
                                                                            className={classes.gameAnalyzeListShape}
                                                                            src={`${item.image}`}
                                                                            onClick={() => (item.status === 3 || (checkPromotion === 1 && item.promotion === 1)) ? null : openGame(item)}
                                                                        ></ProgressiveImage>
                                                                        
                                                                        <div className={classes.heartContentPosition}>
                                                                            {
                                                                                renderHearth(item, index)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {renderGameAnlyza(item)}
                                                                </div>
                                                            </div>
                                                )
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    )
                }
            }
        }
        else {
            return null;
        }
    };

    const renderHearth = (item, index) => {
        return (
            item.like === false ?
                <img src="/images/icon/hearth_not_active.webp" className={classes.heartContent} onClick={() => { clickLikeGame(item, index, true) }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                :
                <img src="/images/icon/hearth_active.webp" className={classes.heartContent} onClick={() => { clickLikeGame(item, index, false) }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
        );
    };

    const renderGameAnlyza = () => {
        return null;
    };

    useEffect(() => {
        setGameList(dataGame);
        setPageGame(typePage);

        if (isUserLoggedIn()) {
            if (homeData !== null) {
                setCheckPromotion(homeData.promotion_status);
            }
        }
    }, [dataGame, typePage, homeData, dispatch, hearthStatus]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            <OpenGame ref={gameRef}></OpenGame>

            <div className="cardMargin">
                <div className={styles.cardOtherListBody} style={{ background: "unset" }}>
                    {
                        renderGameList()
                    }
                </div>
            </div>
        </>
    );
}