import { checkPromotionApply, getPromotion } from "./../../../services/promotionService";
import { setLoadProcessing } from "./../app/processing";

const OPEN_PROMOTION_POPUP = 'app/OPEN_PROMOTION_POPUP';
const LOAD_CHECK_PROMOTION = 'app/LOAD_CHECK_PROMOTION';
const LOAD_PROMOTION_ALL = 'app/LOAD_PROMOTION_ALL';

function openPromotionPopup(data) {
    return {
        type: OPEN_PROMOTION_POPUP,
        payload: {
            items: data
        }
    }
}

function loadCheckPromotion() {
    return dispatch => {
        dispatch(setLoadProcessing(true));
        
        checkPromotionApply().then((data) => {
            const statusCode = data.code;
            dispatch(setLoadProcessing(false));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_CHECK_PROMOTION,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_CHECK_PROMOTION,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadPromotionAll() {
    return dispatch => {
        getPromotion().then((data) => {
            const statusCode = data.code
            if (statusCode === 0 || statusCode === 200) {
                dispatch({
                    type: LOAD_PROMOTION_ALL,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_PROMOTION_ALL,
                    payload: {
                        items: []
                    }
                })
            }
        });
    }
};

export {
    OPEN_PROMOTION_POPUP,
    LOAD_CHECK_PROMOTION,
    LOAD_PROMOTION_ALL,
    openPromotionPopup,
    loadCheckPromotion,
    loadPromotionAll
}