import {
    SET_SHOP_ALL
} from './../../actions/shop/index';

const initialState = {
    items: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_SHOP_ALL:
            return { ...state, items: action.payload.items };
        default:
            return state;
    }
}