import React from 'react'
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// @Utility
import currencyFormat from './../../../../@utility/numbers/currencyFormat'

export default function RankListItem(props) {
    const useStyles = makeStyles((theme) => ({
        rankUserList: {
            background: 'rgb(255,255,255)',
            background:
                'linear-gradient(180deg, #747474 0%, rgba(0,0,0,1) 100%)',
            padding: "10px 8px",
            border: '1px solid #ffc700',
            color: '#fff',
            fontSize: '16px',
            [theme.breakpoints.down("xs")]: {
                fontSize: '12px',
            },
        },
        rankUserStatus: {
            background: 'rgb(255,255,255)',
            background:'linear-gradient(180deg, #747474 0%, rgba(0,0,0,1) 100%)',
            paddingTop: "10px",
            paddingBottom: "12px",
            border: '1px solid #a8a8a8',
            color: '#fff',
            fontSize: '16px',
            marginTop: "12px",
            marginBottom: theme.spacing(1),
            marginRight: "4px",
            width: "99% !important",
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(3),
                paddingRight: "0px",
                marginTop: "6px",
                paddingTop: "8px",
                paddingBottom: "10px",
                fontSize: '12px',
                width: "91% !important",
            },
            [theme.breakpoints.down("sm")]: {
                marginTop: "6px",
            }
        },
        textGold: {
            color: '#ffc700',
        },
        rankContainer: {
            marginTop: "100px",
            [theme.breakpoints.down("xs")]: {
                marginTop: "80px",
            }
        },
        rankHead: {
            width: "100%", 
            height: "80px",
            [theme.breakpoints.down("xs")]: {
                height: "70px",
            }
        },
        rankLogo: {
            width: "280px", 
            marginLeft: "auto", 
            display: "block", 
            marginRight: "auto", 
            marginTop: "-175px", 
            marginBottom: "25px",
            [theme.breakpoints.down("xs")]: {
                width: "246px",
                marginTop: "-155px"
            }
        },
        rankBlock: {
            borderStyle: "solid", 
            borderWidth: "0px 3px", 
            borderColor: "#c69a01", 
            backgroundColor: "#000", 
            color: "#fff", 
            marginTop: "0px",
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                borderWidth: "0px 1px",
                paddingBottom: theme.spacing(3)
            }
        },
        middleLine: {
            margin: "30px 0", 
            height: "3px", 
            border: "0", 
            backgroundImage: "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 0.75), rgba(255,255,255,0))"
        },
        rankCup: {
            width: "75px", 
            marginLeft: "auto", 
            display: "block", 
            marginRight: "auto",
            [theme.breakpoints.down("xs")]: {
                width: "43px !important"
            },
            [theme.breakpoints.down("sm")]: {
                width: "65px"
            }
        },
        gameCampImg: {
            width: "75px", 
            height: "auto",
            float: "right",
            paddingRight: "12px",
            [theme.breakpoints.down("xs")]: {
                width: "55px",
                paddingRight: "5px !important",
                marginLeft: "10px"
            },
            [theme.breakpoints.down("sm")]: {
                paddingRight: "6px",
            }
        },
        rankFooter: {
            width: "100%", 
            height: "70px", 
            marginBottom: "40px",
            marginTop: "-6px",
            [theme.breakpoints.down("xs")]: {
                height: "36px",
                marginBottom: "20px"
            }
        },
    }))

    const classes = useStyles()

    const isTop = props.top

    return (
        <Paper key={`${props?.rankData?.quantity}-${Math.random()}`} className={isTop ? classes.rankUserList : classes.rankUserStatus}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={4} sm={3} md={3}>
                    <img
                        src={`/images/home/rankuser/gamecamp/${props?.rankData?.camp_name}.webp`} 
                        alt={props?.rankData?.camp_name}
                        className={classes.gameCampImg}
                    />
                </Grid>
                <Grid item xs={8} sm={6} md={6}>
                    ยูส:{' '}
                    <span className={classes.textGold}>
                        {props?.rankData?.username}
                    </span>{' '}
                    เล่น: <span>{props?.rankData?.camp_name}</span>
                    <br />
                    ชนะรางวัล:{' '}
                    <span className={classes.textGold}>
                        ฿{currencyFormat(props?.rankData?.quantity)}
                    </span>
                </Grid>
            </Grid>
        </Paper>
    )
}
