import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// Services
import { loadHomeData } from "./../../../../../@core/services/homeService";

// Redux
import { setHomeData } from "./../../../../../@core/redux/actions/home/index";

export default function NavbarDesktop({ redirect, reduxAppCMS }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        navbarFixed: {
            position: "fixed",
            top: "0px",
            width: "100%",
            left: "0px",
            zIndex: "1200"
        },
        navbarPosition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-navbar"] : "#270058",
            width: "100%",
            padding: "10px 0px 5px 0px",
            [theme.breakpoints.down('xs')]: {
                padding: "14px 0px 0px 0px"
            }
        },
        navbarPositionGame: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-navbar"] : "#270058",
            width: "100%",
            padding: "10px 0px 10px 0px",
            fontSize: "20px"
        },
        navbarContent: {
            padding: "0px 22px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            [theme.breakpoints.down('xs')]: {
                padding: "0px 8px"
            }
        },
        // imgLogo: {
        //     width: "210px",
        //     height: "auto",
        //     [theme.breakpoints.down('xs')]: {
        //         width: "76%"
        //     }
        // },
        iconPlayerClose: {
            width: "50px",
            position: "relative",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            },
            [`${theme.breakpoints.up('sm')} and (max-device-height: 1000px)`]: {
                width: "28px",
            }
        },
        iconPlayerPadding: {
            [`${theme.breakpoints.up('sm')} and (max-device-height: 1000px)`]: {
                padding: "0 4px 0 4px !important;",
                fontSize: "14px"
            }
        },
        loginClick: {
            background: "transparent",
            width: "160px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            position: "relative",
            cursor: "pointer",
            borderRadius: "10px",
            fontSize: "18px",
            padding: "10px",
            border: "1px solid white",
            fontWeight: "500",
            '&:hover': {
                top: "2px"
            }
        },
        registerClick: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
            width: "160px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            position: "relative",
            cursor: "pointer",
            borderRadius: "10px",
            fontSize: "18px",
            padding: "10px",
            fontWeight: "500",
            '&:hover': {
                top: "2px"
            }
        },
        // userSize: {
        //     width: "62px",
        //     height: "60px",
        //     background: "#2F0363",
        //     border: "3px solid #D4BDFF",
        //     borderRadius: "100%",
        //     [theme.breakpoints.down('sm')]: {
        //         width: "72px",
        //         height: "72px"
        //     },
        //     [theme.breakpoints.down('xs')]: {
        //         width: "50px",
        //         height: "46px"
        //     }
        // },
        // cardLeftUsername: {
        //     fontSize: "16px",
        //     color: "white"
        // },
        // iconCardSize: {
        //     color: "white",
        //     width: "32px",
        //     height: "32px",
        //     marginTop: "2px",
        //     [theme.breakpoints.down('xs')]: {
        //         width: "32px",
        //         height: "26px",
        //         marginTop: "6px"
        //     }
        // },
        // backTextSize: {
        //     color: "white",
        //     fontSize: "18px",
        //     [theme.breakpoints.down('xs')]: {
        //         fontSize: "14px"
        //     }
        // },
        alertDemo: {
            background: "#A80000",
            color: "white",
            padding: "4px 12px",
            borderRadius: "6px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        alertTrue: {
            background: "linear-gradient(180deg, #06C755 0%, #00712E 100%)",
            color: "white",
            padding: "4px 12px",
            borderRadius: "6px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        // boxUserLevelPosition: {
        //     position: "absolute",
        //     right: "-12px",
        //     bottom: "8px",
        //     [theme.breakpoints.down('sm')]: {
        //         right: "-2px",
        //     }
        // },
        // userLevelSize: {
        //     width: "32px",
        //     height: "32px",
        //     filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        //     [theme.breakpoints.down('sm')]: {
        //         width: "36px",
        //         height: "36px"
        //     },
        //     [theme.breakpoints.down('xs')]: {
        //         width: "28px",
        //         height: "28px"
        //     }
        // },
        menuList: {
            color: "white",
            cursor: "pointer",
            '&:hover': {
                color: "#FFDA7A"
            }
        },
        menuListActive: {
            color: "#FFDA7A"
        },
        footerIconSize: {
            height: "32px",
            width: "32px"
        },
        menuListText: {
            marginTop: "-6px"
        },
        cardPlayerHeight: {
            height: "64px",
            [`${theme.breakpoints.up('sm')} and (max-device-height: 1000px)`]: {
                height: "30px",
            }
        }
    }));

    const classes = useStyles();

    const reloadBalance = () => {
        if (isUserLoggedIn()) {
            loadHomeData().then((data) => {
                if (data.code === 0 || data.code === 200) {
                    dispatch(setHomeData(data.result));
                }
            });
        }

        if(redirect !== undefined && redirect !== null) {
            if(redirect === "home") {
                history.push("/");
            }
            else {
                history.goBack();
            }
        }
        else {
            history.goBack();
        }
    };

    return (
        <div className={classes.navbarPositionGame}>
            <div className={classes.navbarContent}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={2} className={classes.cardPlayerHeight}>
                    <Grid item className={classes.iconPlayerPadding}>
                        {
                            isUserLoggedIn() ?
                                <div className={[classes.alertTrue, classes.iconPlayerPadding].join(" ")} >
                                    โหมดเดิมพัน
                                </div>
                                :
                                <div className={[classes.alertDemo, classes.iconPlayerPadding].join(" ")} >
                                    โหมดทดลองเล่น
                                </div>
                        }
                    </Grid>
                    <Grid item className={classes.iconPlayerPadding} >
                        <img className={classes.iconPlayerClose} src="/images/game/btn_close_circle.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" onClick={() => { reloadBalance() }} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}