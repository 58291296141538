import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const ProgressiveImage = (props) => {
    // const [loading, setLoading] = useState(true);
    const [currentSrc, setCurrentSrc] = useState(props.placeholder);

    useEffect(() => {
        const { src } = props;
        const imageToLoad = new Image();
        imageToLoad.src = src;
        imageToLoad.onload = () => {
            // setLoading(false);
            setCurrentSrc(src);
        };
    }, [props]);

    return (
        <img
            src={currentSrc}
            className={["progressiveImage", props.className].join(" ")}
            style={{
                transition: "opacity .15s linear",
                borderRadius: "8px"
            }}

            ref={props.imageRef}
            onClick={props.onClick}
            alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม"
        />
    );
}

ProgressiveImage.propTypes = {
    src: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
};

export default ProgressiveImage;