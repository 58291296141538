import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

// Hooks
import { useAddToHomescreenPrompt } from "../../../../@utility/hooks/useAddToHomescreenPrompt";

// Component
import { Grid, Container } from "@material-ui/core";
import ProgressiveImage from "./ProgressiveImage";

// CSS;
import buttonStyles from "../assets/css/Button.module.css";

export default function NotificationSubscriptionMobile({
  notificationStatus,
  configInstallPWA,
  setNotificationStatusMobile,
}) {
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const reduxAppCMS = useSelector((state) => state.reduxAppCMS.cms);

  // const applicationServerKeyData = 'BIVW5jjodYeBNcX5fO1S9CinSgldVY0JTbFRCyZ_auqrxL2BJOXlXiSE8nEdRk2BiCneGHMTwSqe3cAN5LlEXs4';
  let initializedEvent = false;

  const handleSubscribeClick = async () => {
    promptToInstall();
    setNotificationStatusMobile(true);
    setIsAppInstalled(true);
  };

  const handleCancelClick = () => {
    setNotificationStatusMobile(true);
    setIsAppInstalled(true);
  };

  const isPWAInstalled = async () => {
    try {
      const relatedApps = await navigator.getInstalledRelatedApps();
      const installed = relatedApps.find(
        (app) => app.url === configInstallPWA.data.url
      );
      if (installed !== undefined) {
        setIsAppInstalled(true);
        setNotificationStatusMobile(true);
      } else {
        setIsAppInstalled(false);
      }
    } catch (error) {
      setIsAppInstalled(true);
      setNotificationStatusMobile(true);
    }
  };

  useEffect(() => {
    // ดัก Event ให้ทำงานก่อนเขียน Component
    if (!initializedEvent) {
      initializedEvent = true;

      // ตรวจสอบบราวเซอร์รองรับการติดตั้งแอพที่หน้าจอโฮม
      if ("getInstalledRelatedApps" in window.navigator) {
        if (prompt === null) {
          isPWAInstalled(prompt);
        }
      } else {
        setNotificationStatusMobile(true);
        setIsAppInstalled(true);
      }
    }
  }, [prompt]);

  const useStyles = makeStyles((theme) => ({
    navbarFixed: {
      position: "fixed",
      top: "0px",
      width: "100%",
      left: "0px",
      zIndex: "1200",
    },
    navbarPosition: {
      background: reduxAppCMS !== null ? reduxAppCMS["bg-navbar"] : "#270058",
      width: "100%",
    },
    navbarPositionMobile: {
      width: "100%",
      backgroundColor: "#ffffff",
    },
    navbarPositionGame: {
      background: reduxAppCMS !== null ? reduxAppCMS["bg-navbar"] : "#270058",
      width: "100%",
      padding: "10px 0px 10px 0px",
      fontSize: "20px",
    },
    navbarContent: {
      padding: "0px 22px",
      color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 8px",
      },
    },
    navbarContentMore: {
      padding: "0px 22px",
      color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 4px 0px 8px",
      },
    },
    cardPlayerHeight: {
      height: "64px",
      [`${theme.breakpoints.up("sm")} and (max-device-height: 1000px)`]: {
        height: "30px",
      },
    },
    iconPlayerClose: {
      width: "40px",
      position: "relative",
      cursor: "pointer",
      "&:hover": {
        top: "2px",
      },
      [`${theme.breakpoints.up("sm")} and (max-device-height: 1000px)`]: {
        width: "28px",
      },
    },
    iconPlayerPadding: {
      [`${theme.breakpoints.up("sm")} and (max-device-height: 1000px)`]: {
        padding: "0 4px 0 4px !important;",
        fontSize: "14px",
      },
    },
    loginClick: {
      background: "transparent",
      width: "160px",
      color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
      position: "relative",
      cursor: "pointer",
      borderRadius: "10px",
      fontSize: "18px",
      padding: "10px",
      border: "1px solid white",
      fontWeight: "500",
      "&:hover": {
        top: "2px",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "5px",
        padding: "6px 4px",
        fontSize: "14px",
        width: "85px",
      },
    },
    registerClick: {
      background:
        reduxAppCMS !== null
          ? reduxAppCMS["bt-confirm"]
          : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
      width: "160px",
      color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
      position: "relative",
      cursor: "pointer",
      borderRadius: "10px",
      fontSize: "18px",
      padding: "10px",
      fontWeight: "500",
      "&:hover": {
        top: "2px",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "5px",
        padding: "6px 4px",
        fontSize: "14px",
        width: "85px",
      },
    },
    alertDemo: {
      background: "#A80000",
      color: "white",
      padding: "4px 12px",
      borderRadius: "6px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
    alertTrue: {
      background: "linear-gradient(180deg, #06C755 0%, #00712E 100%)",
      color: "white",
      padding: "4px 12px",
      borderRadius: "6px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
  }));

  const classes = useStyles();

  return (
    prompt !== null &&
    !isAppInstalled &&
    !notificationStatus && (
      <div className={classes.navbarPositionMobile}>
        <div className={classes.navbarContentMore}>
          <Container maxWidth="lg" style={{ padding: "0px" }}>
            <div className="pwaPopupInstallAppMobie">
              <div className="positionFixed topLeft leftCenter traslateXCenter zIndexLarge zIndexMax">
                <div className="pwaPopupInstallApp displayInlineBlock">
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      <ProgressiveImage
                        className="pwaIconSize"
                        placeholder=""
                        src={configInstallPWA?.icon}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <div className="left pwaIconTitle pwaTitle textBoldMedium">
                        {configInstallPWA?.text}
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <button
                        className={[
                          buttonStyles.btnPWA,
                          buttonStyles.btnPWAInstall,
                        ].join(" ")}
                        onClick={handleSubscribeClick}
                      >
                        <div className="center pwaIconTitle textBoldMedium">
                          ติดตั้ง
                        </div>
                      </button>
                    </Grid>
                    <Grid item xs={1}>
                      <img
                        className={[
                          buttonStyles.btnPWA,
                          buttonStyles.btnPWACancel,
                        ].join(" ")}
                        src="/images/icon/cancel.svg"
                        alt="close"
                        onClick={handleCancelClick}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Container>
          {/* </div> */}
        </div>
      </div>
    )
  );
}
