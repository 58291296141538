// import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Hooks
import useParameterUrl from "./../../../../@utility/hooks/useParameterUrl";

// Component
import ProgressiveImage from "./ProgressiveImage";

// Icon
export default function FooterHome() {
    const { path } = useRouteMatch();
    const parsed = useParameterUrl();
    const { play_game } = parsed;
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const dataList = {
        "bank": [
            {
                "id": 1,
                "img": "/images/bank/v4/ture.webp",
                "status": 2
            },
            {
                "id": 2,
                "img": "/images/bank/v4/kbank.webp",
                "status": 2
            },
            {
                "id": 3,
                "img": "/images/bank/v4/scb.webp",
                "status": 2
            },
            {
                "id": 4,
                "img": "/images/bank/v4/ktb.webp",
                "status": 2
            },
            {
                "id": 5,
                "img": "/images/bank/v4/bay.webp",
                "status": 2
            },
            {
                "id": 6,
                "img": "/images/bank/v4/bbl.webp",
                "status": 2
            },
            {
                "id": 7,
                "img": "/images/bank/v4/cimb.webp",
                "status": 2
            },
            {
                "id": 8,
                "img": "/images/bank/v4/icbc.webp",
                "status": 2
            },
            {
                "id": 9,
                "img": "/images/bank/v4/k.webp",
                "status": 2
            },
            {
                "id": 10,
                "img": "/images/bank/v4/lh.webp",
                "status": 2
            },
            {
                "id": 11,
                "img": "/images/bank/v4/tmb.webp",
                "status": 2
            },
            {
                "id": 12,
                "img": "/images/bank/v4/tisco.webp",
                "status": 2
            }
        ],
        "camp": [
            {
                "id": 1,
                "img": "/images/camp/black/new/Joker-gaming-logo.webp",
                "status": 2
            },
            {
                "id": 2,
                "img": "/images/camp/black/new/PG-logo.webp",
                "status": 2
            },
            {
                "id": 3,
                "img": "/images/camp/black/new/Nextspin-logo.webp",
                "status": 2
            },
            {
                "id": 4,
                "img": "/images/camp/black/new/Pragmatic-play-logo.webp",
                "status": 2
            },
            {
                "id": 5,
                "img": "/images/camp/black/new/Playngo-logo.webp",
                "status": 2
            },
            {
                "id": 6,
                "img": "/images/camp/black/new/Spadegaming-logo.webp",
                "status": 2
            },
            {
                "id": 7,
                "img": "/images/camp/black/new/Microgaming-logo.webp",
                "status": 2
            },
            {
                "id": 8,
                "img": "/images/camp/black/new/Habanero.webp",
                "status": 2
            },
            {
                "id": 9,
                "img": "/images/camp/black/new/Kingmaker-logo.webp",
                "status": 2
            },
            {
                "id": 15,
                "img": "/images/camp/black/new/pakyok-logo.webp",
                "status": 2
            },
            {
                "id": 11,
                "img": "/images/camp/black/new/M8BET-logo.webp",
                "status": 2
            },
            {
                "id": 10,
                "img": "/images/camp/black/new/Dream-gaming-logo.webp",
                "status": 2
            },
            {
                "id": 12,
                "img": "/images/camp/black/new/Sexy-Baccarat-logo.webp",
                "status": 2
            },
            {
                "id": 13,
                "img": "/images/camp/black/new/WM-Casino-logo.webp",
                "status": 2
            },
            {
                "id": 14,
                "img": "/images/camp/black/new/SA-Gaming-logo.webp",
                "status": 2
            }
        ],
        "cer": [
            {
                "id": 1,
                "img": "/images/cer/new/Bmm-Testlab-logo.webp",
                "status": 2
            },
            {
                "id": 2,
                "img": "/images/cer/new/Pagcor-logo.webp",
                "status": 2
            },
            {
                "id": 3,
                "img": "/images/cer/new/GODADDY-logo.webp",
                "status": 2
            },
            {
                "id": 4,
                "img": "/images/cer/new/GA-Gaming-logo.webp",
                "status": 2
            }
        ]
    };

    const useStyles = makeStyles((theme) => ({
        bgWhite: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white"
        },
        cardBox: {
            // color: "black",
            borderRadius: "15px 15px 0px 0px",
            padding: "30px",
            [theme.breakpoints.down('xs')]: {
                padding: "25px 15px",
                borderRadius: "0px"
            }
        },
        textTitle: {
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "20px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "17px"
            }
        },
        boxImgSize: {
            width: "100%",
            height: "100%",
            borderRadius: "10px"
        },
        textCopyR: {
            fontSize: "18px"
        }
    }));

    const classes = useStyles();

    // console.log(reduxAppCMS)

    return (
        (play_game !== "true" && play_game !== true) && (
            <>
                <div className={[classes.bgWhite, classes.cardBox].join(" ")}>
                    {
                        reduxAppCMS !== null && (
                            <>
                                {
                                    (reduxAppCMS?.footer_pay === "1" || reduxAppCMS?.footer_pay === 1) && (
                                        <>
                                            <div className={classes.textTitle}>
                                                รองรับการชำระเงิน
                                            </div>
        
                                            <Grid container alignItems="center" spacing={2}>
                                                {
                                                    dataList["bank"].map((item) => {
                                                        return (
                                                            <Grid key={item.id} item xs={2} md={1}>
                                                                {/* <img className={classes.boxImgSize} src={item.img} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" /> */}
                                                                <ProgressiveImage 
                                                                    placeholder="/images/bluring/74x75.jpg" 
                                                                    className={classes.boxImgSize}
                                                                    src={item.img}
                                                                ></ProgressiveImage>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
        
                                            <br />
                                        </>
                                    )
                                }

                                {
                                    (reduxAppCMS?.footer_alliance === "1" || reduxAppCMS?.footer_alliance === 1) && (
                                        <>
                                            <div className={classes.textTitle}>
                                                ค่ายเกมพันธมิตร
                                            </div>

                                            <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                                                {
                                                    dataList["camp"].map((item) => {
                                                        return (
                                                            <Grid key={item.id} item xs={3} md={2}>
                                                                {/* <img className={classes.boxImgSize} src={item.img} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" /> */}
                                                                <ProgressiveImage 
                                                                    placeholder="/images/bluring/150x65.jpg" 
                                                                    className={classes.boxImgSize}
                                                                    src={item.img}
                                                                ></ProgressiveImage>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>

                                            <br />
                                        </>
                                    )
                                }

                                {
                                    (reduxAppCMS?.footer_certificate === "1" || reduxAppCMS?.footer_certificate === 1) && (
                                        <>
                                            <div className={classes.textTitle}>
                                                ใบรับรอง
                                            </div>

                                            <Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
                                                {
                                                    dataList["cer"].map((item) => {
                                                        return (
                                                            <Grid key={item.id} item xs={3} md={2}>
                                                                {/* <img className={classes.boxImgSize} src={item.img} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" /> */}
                                                                <ProgressiveImage 
                                                                    placeholder="/images/bluring/150x65.jpg" 
                                                                    className={classes.boxImgSize}
                                                                    src={item.img}
                                                                ></ProgressiveImage>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </>
                                    )
                                }
                            </>
                        )
                    }

                    <br /><br />
                    <div className={[classes.textCopyR, "center"].join(" ")}>
                        <Grid container alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item>
                                Copyright { process.env.REACT_APP_OG_SITE_NAME } @ 2022.
                            </Grid>
                            <Grid item>
                                <img src="/images/icon/18+.webp" style={{ width: "42px", height: "auto" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            </Grid>
                        </Grid>
                    </div>
                    <br /><br /><br /><br /><br />
                </div>
            </>
        )
    );
}