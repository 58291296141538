export default function LoadContent() {
    return (
        <div className="loadContent">
            <div>
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="loadContentText">
                    กรุณารอสักครู่...
                </div>
            </div>
        </div>
    );
}