import { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TagManager from 'react-gtm-module';

// @Utility
import { setStorage, getStorage, removeStorage } from "./../../@utility/app/storage";
import loadLiveChat from "./../../@utility/app/loadLiveChat";

// auth
import { isUserLoggedIn } from "./../../@core/auth/utils";

// Hooks
import useParameterUrl from "./../../@utility/hooks/useParameterUrl";

// Services
import { loadHomeData } from "../../@core/services/homeService";
import { loadCampAll } from "./../../@core/services/gameService";

// Redux
import { setHomeData } from "./../../@core/redux/actions/home/index";
import {
    setLoadHome,
    setLoadImages
} from "./../../@core/redux/actions/app/processing";
import { setDataCMS } from "./../../@core/redux/actions/app/cms";
import { setCampAll } from './../../@core/redux/actions/game/index';
import { setGameHitAll } from "./../../@core/redux/actions/game/index";
import { loadRank } from './../../@core/redux/actions/rank';
import { loadPromotionAll } from './../../@core/redux/actions/promotion';
import { setHomeMenuType } from "./../../@core/redux/actions/home/index";

// Routes
import VerticalRoutes from "./vertical/VerticalRoutes";

// Component
import Navbar from "./vertical/components/Navbar/index";
import FooterMenu from "./vertical/components/Footer/index";
import BrannerSlide from "./vertical/components/BrannerSlide";
import TextAnimate from "./vertical/components/TextAnimate";
import TextAnimate2 from './vertical/components/TabInstallApp';
import NavbarMenu from "./vertical/components/NavbarMenu";
import FooterHome from "./vertical/components/Footer";
import ListContact from "./vertical/components/ListContact";
import ConfigSet from "./vertical/components/ConfigSet";
import NotificationSubscription from "./vertical/components/NotificationSubscription";
import NotificationSubscriptionMobile from './vertical/components/NotificationSubscriptionMobile';

const Layouts = (action) => {
    const [loadRoutes, setLoadRoutes] = useState(false);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const imagesProcessing = useSelector(state => state.reduxAppProcessing.images);
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [pathName, setPathName] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [processingNavbar, setProcessingNavbar] = useState(false);
    const homeData = useSelector(state => state.reduxHome.result);
    const [configInstallPWA, setConfigInstallPWA] = useState(null);
    const [notificationStatus, setNotificationStatus] = useState("load");
    const [notificationStatusMobile, setNotificationStatusMobile] = useState("load");

    const parsed = useParameterUrl();
    const { iframe_load } = parsed;
    const [iframeStatus, setIframeStatus] = useState(false);

    const setLoadDataCms = (dataCms) => {
        dispatch(setDataCMS(dataCms));
        setStorage("data_cms", JSON.stringify(dataCms));
        document.getElementsByTagName("body")[0].style.background = dataCms["bg-foter"];
        document.documentElement.style.setProperty("--bg-card", dataCms["bg-card"]);
        document.documentElement.style.setProperty("--bg-foter", dataCms["bg-foter"]);
        document.documentElement.style.setProperty("--bg-main", dataCms["bg-main"]);
        document.documentElement.style.setProperty("--bg-modal", dataCms["bg-modal"]);
        document.documentElement.style.setProperty("--bg-navbar", dataCms["bg-navbar"]);
        document.documentElement.style.setProperty("--bg-popup", dataCms["bg-popup"]);
        document.documentElement.style.setProperty("--bg-text-animation", dataCms["bg-text-animation"]);
        document.documentElement.style.setProperty("--bt-confirm", dataCms["bt-confirm"]);
        document.documentElement.style.setProperty("--bt-danger", dataCms["bt-danger"]);
        document.documentElement.style.setProperty("--bt-defult", dataCms["bt-defult"]);
        document.documentElement.style.setProperty("--bt-info", dataCms["bt-info"]);
        document.documentElement.style.setProperty("--bt-login", dataCms["bt-login"]);
        document.documentElement.style.setProperty("--bt-register", dataCms["bt-register"]);
        document.documentElement.style.setProperty("--bt-secondary", dataCms["bt-secondary"]);
        document.documentElement.style.setProperty("--bt-sucess", dataCms["bt-sucess"]);
        document.documentElement.style.setProperty("--bt-undefult", dataCms["bt-undefult"]);
        document.documentElement.style.setProperty("--bt-warning", dataCms["bt-warning"]);

        if (dataCms["google_key"] !== "") {
            TagManager.initialize({ gtmId: dataCms["google_key"] });
        }

        let url_domain = `${window.location.protocol}//${window.location.host}`;
        let myDynamicManifest = {
            "short_name": dataCms["manifest_short_name"],
            "name": dataCms["manifest_name"],
            "icons": [
                {
                    "src": dataCms?.manifest_icon_xxl !== undefined ? dataCms?.manifest_icon_xxl : "",
                    "type": "image/webp",
                    "sizes": "512x512"
                },
                {
                    "src": dataCms?.manifest_icon_xxl_png !== undefined ? dataCms?.manifest_icon_xxl_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "512x512"
                },
                {
                    "src": dataCms?.manifest_icon_xl !== undefined ? dataCms?.manifest_icon_xl : "",
                    "type": "image/webp",
                    "sizes": "196x196"
                },
                {
                    "src": dataCms?.manifest_icon_xl_png !== undefined ? dataCms?.manifest_icon_xl_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "196x196"
                },
                {
                    "src": dataCms?.manifest_icon_lg !== undefined ? dataCms?.manifest_icon_lg : "",
                    "type": "image/webp",
                    "sizes": "144x144"
                },
                {
                    "src": dataCms?.manifest_icon_lg_png !== undefined ? dataCms?.manifest_icon_lg_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "144x144"
                },
                {
                    "src": dataCms?.manifest_icon_md !== undefined ? dataCms?.manifest_icon_md : "",
                    "type": "image/webp",
                    "sizes": "128x128"
                },
                {
                    "src": dataCms?.manifest_icon_md_png !== undefined ? dataCms?.manifest_icon_md_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "128x128"
                },
                {
                    "src": dataCms?.manifest_icon_sm !== undefined ? dataCms?.manifest_icon_sm : "",
                    "type": "image/webp",
                    "sizes": "96x96"
                },
                {
                    "src": dataCms?.manifest_icon_sm_png !== undefined ? dataCms?.manifest_icon_sm_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "96x96"
                },
                {
                    "src": dataCms?.manifest_icon_xs !== undefined ? dataCms?.manifest_icon_xs : "",
                    "type": "image/webp",
                    "sizes": "64x64"
                },
                {
                    "src": dataCms?.manifest_icon_xs_png !== undefined ? dataCms?.manifest_icon_xs_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "64x64"
                }
            ],
            "scope": dataCms["manifest_scope"],
            "start_url": url_domain,
            "display": dataCms["manifest_display"],
            "theme_color": dataCms["manifest_theme_color"],
            "background_color": dataCms["manifest_background_color"]
        };

        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector("#manifest-project").setAttribute("href", manifestURL);
        document.querySelector("#apple-touch-project").setAttribute("href", dataCms["manifest_icon_lg"]);
        document.querySelector("#icon-rel").setAttribute("href", dataCms["rel_icon_big"]);

        if (dataCms["section_notificaion_pwa"] === 1 || dataCms["section_notificaion_pwa"] === "1") {
            setConfigInstallPWA({
                title: `แอพ ${dataCms["manifest_name"]}`,
                body: `คลิกเพื่อติดตั้งแอพ ${dataCms["manifest_name"]}`,
                text: dataCms["title_notificaion_pwa"],
                icon: dataCms["manifest_icon_lg"],
                data: {
                    url: url_domain,
                },
            });

            const notificationData = getStorage("notificationStatus");
            setNotificationStatus(notificationData);
        }

        if (dataCms["section_notificaion_mobile_pwa"] === 1 || dataCms["section_notificaion_mobile_pwa"] === "1") {
            setConfigInstallPWA({
                title: `แอพ ${dataCms["manifest_name"]}`,
                body: `คลิกเพื่อติดตั้งแอพ ${dataCms["manifest_name"]}`,
                text: dataCms["title_notificaion_pwa"],
                icon: dataCms["manifest_icon_lg"],
                data: {
                    url: url_domain,
                },
            });

            setNotificationStatusMobile(true);
        }
    };

    useEffect(() => {
        const pathData = window.location.pathname;
        setStorage("allusion", document.referrer);
        setPathName(pathData);
        setLoadRoutes(true);

        if (pathData !== "/") {
            if (isUserLoggedIn()) {
                loadHomeData().then((data) => {
                    if (data.code === 0 || data.code === 200) {
                        dispatch(setHomeData(data.result));
                    }
                });
            }

            dispatch(setLoadHome(true));
            dispatch(setLoadImages(true));
        }
    }, [pathName]);

    useEffect(() => {
        if (dataSetting?.cms !== undefined && dataSetting?.cms !== null) {
            removeStorage("data_cms");
            const dataCms = dataSetting?.cms;
            setLoadDataCms(dataCms);
            setProcessing(true);
            setProcessingNavbar(true);
        }
        else {
            if (dataSetting?.cms === undefined || dataSetting?.cms === null) {
                let checkCookie = getStorage("data_cms");

                if (checkCookie !== undefined && checkCookie !== null && checkCookie !== "") {
                    let data_cms = JSON.parse(checkCookie);
                    if (data_cms !== undefined && data_cms !== null) {
                        setLoadDataCms(data_cms);
                        setProcessingNavbar(true);
                    }
                    else {
                        setProcessingNavbar(false);
                    }
                }
            }
            else {
                setProcessingNavbar(false);
            }
        }
    }, [dataSetting?.cms]);

    useEffect(() => {
        if (dataSetting !== null) {
            let dataAgentContact = dataSetting?.agent_contact;
            if (dataAgentContact !== undefined && dataAgentContact !== null) {
                if (dataAgentContact.length > 0) {
                    let dataLiveChat = dataAgentContact.filter((item) => (item.contact_channel === 1));

                    if (dataLiveChat.length > 0) {
                        loadLiveChat(dataLiveChat[0].channel_appid);
                    }
                }
            }
        }
    }, [dataSetting]);

    useEffect(() => {
        if (iframe_load === true || iframe_load === "true") {
            setIframeStatus(true);
        }
        else {
            setIframeStatus(false);
        }
    }, [iframe_load]);

    useEffect(() => {
        const reloadCamp = () => {
            loadCampAll(99, true).then((result) => {
                dispatch(setCampAll(result));
            });
        };

        if (processing) {
            reloadCamp();
        }
    }, [processing]);

    useEffect(() => {
        if (imagesProcessing) {
            if (reduxAppCMS !== null) {
                if (reduxAppCMS?.section_games_rank === "1" || reduxAppCMS?.section_games_rank === 1) {
                    dispatch(setGameHitAll());
                }

                if (reduxAppCMS?.section_user_rank === "1" || reduxAppCMS?.section_user_rank === 1) {
                    dispatch(loadRank());
                }
            }

            dispatch(loadPromotionAll());
        }
    }, [reduxAppCMS, imagesProcessing]);

    useEffect(() => {
        if (dataSetting.menu_camp !== null) {
            let menuArr = dataSetting.menu_camp;
            if (menuArr.length > 0) {
                let item = menuArr[0];
                dispatch(setHomeMenuType({
                    id: 0,
                    renderPage: item.code,
                    pageTitle: item.title,
                    game_camp_image: item.game_camp_image,
                    column: {
                        desktop: item.column_desktop,
                        mobile: item.column_mobile
                    },
                    typeGame: item.game_camp_type
                }));
            }
        }
    }, [dataSetting.menu_camp]);

    return <>
        {
            loadRoutes && (
                iframeStatus ?
                    <Container maxWidth="lg" className="bgContainer">
                        <VerticalRoutes dataSetting={dataSetting}></VerticalRoutes>
                    </Container>
                    :
                    <>
                        {
                            processingNavbar && (
                                <Navbar isUserLoggedIn={isUserLoggedIn()} 
                                isLargeScreen={isLargeScreen} 
                                notificationStatus={notificationStatusMobile === null || notificationStatusMobile === undefined || notificationStatusMobile === ""}
                                setNotificationStatusMobile={setNotificationStatusMobile}
                                configInstallPWA={configInstallPWA}
                                >
                                </Navbar>
                            )
                        }

                        <div className="homePosition">
                            <BrannerSlide isLargeScreen={isLargeScreen}></BrannerSlide>
                            {/* <NotificationSubscriptionMobile
                                        setNotificationStatusMobile={setNotificationStatusMobile}
                                        configInstallPWA={configInstallPWA}
                                        notificationStatus={notificationStatusMobile === null || notificationStatusMobile === undefined || notificationStatusMobile === ""}
                                    /> */}
                            {
                                
                            // (notificationStatusMobile !== "load" && !isLargeScreen && (notificationStatusMobile !== null || notificationStatusMobile !== undefined || notificationStatusMobile !== "")) && (
                            //     configInstallPWA !== null && (
                            //         <NotificationSubscriptionMobile
                            //             setNotificationStatusMobile={setNotificationStatusMobile}
                            //             configInstallPWA={configInstallPWA}
                            //             notificationStatus={notificationStatusMobile === null || notificationStatusMobile === undefined || notificationStatusMobile === ""}
                            //         />
                            //     )
                            // )
                        }
                            <TextAnimate></TextAnimate>
                        </div>

                        {
                            homeData !== null && (
                                <NavbarMenu isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></NavbarMenu>
                            )
                        }

                        <Container maxWidth="lg" className="bgContainer">
                            <div className={isUserLoggedIn() ? "wrapperActive" : "wrapper"}>
                                {
                                    action.loadRoutes && (
                                        <VerticalRoutes dataSetting={dataSetting}></VerticalRoutes>
                                    )
                                }

                                {
                                    processing && (
                                        imagesProcessing && (
                                            <div className="cardMargin relative">
                                                <div className="left">
                                                    <ListContact isLargeScreen={isLargeScreen}></ListContact>
                                                </div>
                                                <div className="cardMargin relative" style={{ marginTop: "0px" }}>
                                                    <div className="left">
                                                        <FooterHome></FooterHome>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </Container>

                        {
                            processingNavbar && (
                                <FooterMenu isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></FooterMenu>
                            )
                        }

                        <ConfigSet
                            loadRoutes={loadRoutes}
                            processing={processing}
                            isUserLoggedIn={isUserLoggedIn}
                        />
                        {
                            (notificationStatus !== "load" && isLargeScreen && (notificationStatus === null || notificationStatus === undefined || notificationStatus === "")) && (
                                configInstallPWA !== null && (
                                    <NotificationSubscription
                                        setStorage={setStorage}
                                        configInstallPWA={configInstallPWA}
                                        notificationStatus={notificationStatus === null || notificationStatus === undefined || notificationStatus === ""}
                                    />
                                )
                            )
                        }
                    </>
            )
        }
    </>;
}

export default Layouts;