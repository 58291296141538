import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import axios from 'axios';

// Store
import { Provider } from 'react-redux';
import storeConfig from "./@core/redux/storeConfig/store";

// @Utility
// import apiDomain from "./@utility/app/apiDomain";

const store = storeConfig();
const apiUrl = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = apiUrl;
// axios.defaults.baseURL = (apiUrl === undefined || apiUrl === null || apiUrl === "") ? apiDomain(window.location.host) : apiUrl;
// axios.defaults.baseURL = "https://api.tony16888.com/api/v1";
// axios.defaults.baseURL = "http://localhost:8082/api/v1";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();