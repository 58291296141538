import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

// const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const getWheelDataAll = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/customer/minigame/wheel/data`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const clickWheelSpin = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_post,
    url: `/customer/minigame/wheel/bet`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

export {
    getWheelDataAll,
    clickWheelSpin
};