import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

// Component
import WheelSpinnig from "./WheelSpinnig";

// CSS
import styles from "./../../../assets/css/Style.module.css";
import modalStyles from "./../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../assets/css/Button.module.css";

const SpinGame = ({ spinDataAll, setRewardContent, gotoPage, conditionJson }) => {
    const useStyles = makeStyles((theme) => ({
        boxContentWidthFull: {
            padding: "0px",
            width: "550px",
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        bgImages: {
            backgroundImage: `url(${spinDataAll?.asset.background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            padding: "30px 30px 30px 30px",
            borderRadius: "16px 16px 0px 0px",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 16px 20px 16px",
                borderRadius: "0px"
            }
        },
        boxDetailRewardGame: {
            padding: "30px 30px 30px 30px",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 16px 20px 16px"
            }
        },
        boxDetailRewardGameText: {
            fontSize: "18px",
            fontWeight: "600",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        spinImgHistory: {
            width: "36px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "26px"
            }
        },
        spinImgReward: {
            width: "280px",
            height: "auto",
            marginRight: "10px",
            marginBottom: "20px",
            borderRadius: "16px",
            [theme.breakpoints.down('xs')]: {
                width: "240px"
            }
        }
    }));

    const classes = useStyles();

    return (
        <div className={[modalStyles.boxContentWidthFull, classes.boxContentWidthFull].join(" ")}>
            <div className={classes.bgImages}>
                <div className="center">
                    <div className={modalStyles.boxTitle}>
                        {
                            spinDataAll?.info.name
                        }
                    </div>
                    <div className={[styles.boxMarginTop, styles.boxContentTitle].join(" ")} style={{ textAlign: "center" }}>
                        {
                            spinDataAll?.info.topic
                        }
                    </div>
                    <div className={styles.boxMarginFormTop}>
                        <br />
                        <WheelSpinnig spinDataAll={spinDataAll}></WheelSpinnig>
                        <br /><br />
                    </div>
                </div>
            </div>

            <div className={classes.boxDetailRewardGame}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={6} className="center">
                        <div className={[buttonStyles.btnSpin, buttonStyles.btnSpinHistory].join(" ")} onClick={gotoPage}>
                            <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                <Grid item>
                                    <img className={classes.spinImgHistory} src="/images/spin/history.webp" />
                                </Grid>
                                <Grid item>
                                    ประวัติรับรางวัล
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="center">
                        <div className={[buttonStyles.btnSpin, buttonStyles.btnSpinReward].join(" ")} onClick={() => setRewardContent(true)}>
                            <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                <Grid item>
                                    <img className={classes.spinImgHistory} src="/images/spin/gift.webp" />
                                </Grid>
                                <Grid item>
                                    รางวัลกงล้อ
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                {
                    conditionJson !== null && (
                        <div className={styles.boxMarginFormTop}>
                            <div className={classes.boxDetailRewardGameText}>
                                เงื่อนไขหมุนกงล้อ
                            </div>
                            <div style={{ marginLeft: "-22px", marginTop: "-10px" }}>
                                {
                                    ReactHtmlParser(conditionJson?.text)
                                }
                            </div>
                        </div>
                    )
                }
                <div className={styles.boxMarginFormTop}>
                    <div className={classes.boxDetailRewardGameText}>
                        รับเเต้มเพื่อสะสมลุ้นของรางวัลได้ทุกวันจากยอดฝากเงิน โปรโมชั่นต่าง ๆ เเละกิจกรรมสุดพิเศษ
                    </div>
                </div>
                <div className={styles.boxMarginFormTop}>
                    <div
                        className={styles.menuPositionWidthScroll}
                    >
                        <div className="flexContent">
                            {
                                spinDataAll.promotion !== null && (
                                    spinDataAll.promotion.map((item, index) => (
                                        <div key={index}>
                                            <img className={classes.spinImgReward} src={item.image} />
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpinGame;