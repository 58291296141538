import Slider from 'react-slick';

// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Profit({ modalStyles }) {
    const dataList = [
        {
            "id": 1,
            "img": "/images/game/profit/01.webp"
        },
        {
            "id": 2,
            "img": "/images/game/profit/02.webp"
        }
    ];

    const settings = {
        infinite: true,
        // fade: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 6000,
        arrows: true
    };

    return (
        <>
            <div className={[modalStyles.boxTitle, "center"].join(" ")}>
                ระดับรางวัล
            </div>
            <br />
            <Slider {...settings}>
                {
                    dataList.map((item) => {
                        return (
                            <div key={item.id}>
                                <div style={{ padding: "0px 4px" }}>
                                    <img src={item.img} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" style={{ width: "100%", borderRadius: "16px" }} />
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
            <br />
        </>
    );
}