// import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Hooks
import useParameterUrl from "./../../../../../@utility/hooks/useParameterUrl";

// Component
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

export default function NavbarMenu({ isUserLoggedIn, isLargeScreen }) {
    const { path } = useRouteMatch();
    const homeData = useSelector(state => state.reduxHome.result);
    let pathName = window.location.pathname;
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const parsed = useParameterUrl();
    const { redirect } = parsed;

    return (
        isLargeScreen ?
            <NavbarDesktop redirect={redirect} pathName={pathName} homeData={homeData} isUserLoggedIn={isUserLoggedIn} isLargeScreen={isLargeScreen} reduxAppCMS={reduxAppCMS}></NavbarDesktop>
            :
            <NavbarMobile redirect={redirect} pathName={pathName} homeData={homeData} isUserLoggedIn={isUserLoggedIn} isLargeScreen={isLargeScreen} reduxAppCMS={reduxAppCMS}></NavbarMobile>
    );
}