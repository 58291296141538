const SET_DATA_CONFIRM = 'app/SET_DATA_CONFIRM';

function setDataConfirm(data) {
    return {
        type: SET_DATA_CONFIRM,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_CONFIRM,
    setDataConfirm
}