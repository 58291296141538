import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// CSS
import styles from "./../../../assets/css/Style.module.css";
import modalStyles from "./../../../assets/css/Modal.module.css";

const SpinGame = ({ spinDataAll }) => {
    window.scrollTo(0, 0);
    
    const useStyles = makeStyles((theme) => ({
        boxContentWidthFull: {
            padding: "0px",
            width: "550px",
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        boxDetailRewardGame: {
            padding: "30px 30px 30px 30px",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 16px 20px 16px"
            }
        },
        rewardList: {
            background: "linear-gradient(180deg, #434343 0%, #2F2F2F 12.5%, #1C1C1C 89.06%)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            padding: "8px 0px 0px 0px",
            marginBottom: "15px"
        },
        rewardImg: {
            width: "50px",
            height: "auto",
            background: "black",
            padding: "12px",
            borderRadius: "10px"
        }
    }));

    const classes = useStyles();

    return (
        spinDataAll !== null && (
            <div className={[modalStyles.boxContentWidthFull, classes.boxContentWidthFull].join(" ")}>
                <div className={classes.boxDetailRewardGame}>
                    <div className="center">
                        <div className={modalStyles.boxTitle}>
                            รางวัลกงล้อ
                        </div>
                        <div className={styles.boxMarginFormTop}>
                            {
                                spinDataAll.wheel.slice(0).reverse().map((item) => (
                                    <div className={classes.rewardList}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={4}>
                                                <img className={classes.rewardImg} src={item.icon} alt=" back" />
                                            </Grid>
                                            <Grid item xs={8} className="left">
                                                {
                                                    item.name
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <br /><br />
                </div>
            </div>
        )
    );
}

export default SpinGame;