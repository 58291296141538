import { SET_WITHDRAW_DATA, SET_WITHDRAW_RANK_DATA } from './../../actions/withdraw/index';

const initialState = {
    result: false,
    rank: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_WITHDRAW_DATA:
            return { ...state, result: action.payload.items };
        case SET_WITHDRAW_RANK_DATA:
            return { ...state, rank: action.payload.items };
        default:
            return state;
    }
}