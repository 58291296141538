import { useState, useEffect } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

// Redux
import * as appActions from "../../../../../@core/redux/actions/app/snackbar";

export default function SnackbarApp() {
    const { statusShow, statusAlert, titleAlert, textAlert } = useSelector(state => state.reduxAppSnackbar.snackbarData);
    const dispatch = useDispatch();
    const [state] = useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = useState(statusShow);
    const { vertical, horizontal } = state;

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        const action = appActions.setSnackbarData({
            statusShow: false,
            statusAlert: "",
            titleAlert: "",
            textAlert: ""
        });
        dispatch(action);
    };

    useEffect(() => {
        let timer1 = setTimeout(() => {
            setOpen(false);
        }, 6000);

        return () => {
            const action = appActions.setSnackbarData({
                statusShow: false,
                statusAlert: "",
                titleAlert: "",
                textAlert: ""
            });

            dispatch(action);
            clearTimeout(timer1);
        };
    }, [dispatch]);

    return (
        <div style={{ zIndex: "9999 !important" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} style={{ textAlign: "left", fontSize: "16px", zIndex: "99999" }}>
                <Alert severity={statusAlert} onClose={handleClose}>
                    <AlertTitle style={{ fontSize: "18px" }}>
                        <span className="kanit">
                            {titleAlert}
                        </span>
                    </AlertTitle>
                    <span className="kanit">
                        {textAlert}
                    </span>
                </Alert>
            </Snackbar>
        </div>
    );
}