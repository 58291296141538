import { useSelector } from 'react-redux';

export default function GameLoad() {
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    return (
        <div className="loadContent">
            <div>
                <div className="lds-ring">
                    <div style={{ borderColor: `${reduxAppCMS !== null ? reduxAppCMS["bt-login"] : "#68008C"} transparent transparent transparent` }}></div>
                    <div style={{ borderColor: `${reduxAppCMS !== null ? reduxAppCMS["bt-login"] : "#68008C"} transparent transparent transparent` }}></div>
                    <div style={{ borderColor: `${reduxAppCMS !== null ? reduxAppCMS["bt-login"] : "#68008C"} transparent transparent transparent` }}></div>
                    <div style={{ borderColor: `${reduxAppCMS !== null ? reduxAppCMS["bt-login"] : "#68008C"} transparent transparent transparent` }}></div>
                </div>
                <div className="loadContentText" style={{ color: reduxAppCMS !== null ? reduxAppCMS["bt-login"] : "#68008C" }}>
                    กำลังโหลดข้อมูลเกม...
                </div>
            </div>
            <br />
        </div>
    );
}