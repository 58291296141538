import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

// Redux
import { setDataContact } from "./../../../../../@core/redux/actions/app/contact";

// Icon
import ChatIcon from '@material-ui/icons/Chat';

export default function FooterNotActive({ reduxAppCMS }) {
    const dispatch = useDispatch();

    const useStyles = makeStyles(() => ({
        footerPosition: {
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "999"
        },
        footerBtn: {
            // background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            // padding: "14px 14px 8px 14px",
            padding: "8px",
            borderRadius: "50px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
            position: "relative",
            '&:hover': {
                top: "2px"
            }
        },
        footerIconSize: {
            width: "58px",
            height: "58px"
        }
    }));

    const classes = useStyles();

    const openContact = () => {
        dispatch(setDataContact(true));
    };

    return (
        <div className={classes.footerPosition}>
            <div className={classes.footerBtn} onClick={openContact}>
                <img className={classes.footerIconSize} src="/images/icon/v3/line-icon-svg.svg" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
            </div>
        </div>
    );
}