import { useEffect } from 'react';

// @Utility
import setBodyScroll from "./../../../../@utility/app/setBodyScroll";

//  CSS
import modalStyles from "./../assets/css/Modal.module.css";

// Component
import CloseButton from "./CloseButton";

export default function ModalFull(props) {
    const close = () => {
        setBodyScroll(false);
        props.onShow(false);
    };

    const back = () => {
        props.onStep(props.step - 1)
    };

    useEffect(() => {
        setBodyScroll(props.show);
    }, [props.show]);

    return (
        props.show ?
            <>
                <div className={modalStyles.contentPosition} style={{ zIndex: "1900" }}>
                    <div className={modalStyles.boxBodyFull}>
                        <div className={modalStyles.boxFull}>

                            {
                                !props.disable && (
                                    props.step > 1 ?
                                    <div className={modalStyles.backButtonFull} onClick={back}>
                                        <img className={modalStyles.iconClose} src="/images/game/btn_back_circle.webp" alt=" back" />
                                    </div> : null
                                )
                            }

                            {
                                !props.disable ?
                                    <div className={modalStyles.closeButtonFull} onClick={close}>
                                        <CloseButton backPage={props.exit}></CloseButton>
                                    </div> : null
                            }

                            <div className={modalStyles.boxContentWidthFull}>
                                {
                                    props.children
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={modalStyles.fixedBackdrop} style={{ zIndex: "1800" }}></div>
            </>
            :
            null
    );
}