import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const loadActivityAll = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  let config = {
    method: svConfig.method_get,
    url: `/customer/activity/list`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return {
          result: result === null ? [] : result,
          code
        };
      }
      else {
        return [];
      }
    }
  }
}

const loadHistoryDataList = async (path) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: path,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return {
          result: result === null ? [] : result,
          code
        };
      }
      else {
        return [];
      }
    }
  }
}

const loadActivityDetail = async (id) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/customer/activity/view/${id}`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return {
          result: result === null ? [] : result,
          code
        };
      }
      else {
        return [];
      }
    }
  }
}

const loadActivitySelectData = async (id) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/customer/activity/prediction/register/${id}`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return {
          result: result === null ? [] : result,
          code
        };
      }
      else {
        return [];
      }
    }
  }
}

const selectVoteActivity = async (choose_id, activity_id) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let myJson = {
    "choose_id": choose_id,
    "activity_id": activity_id
  };

  let config = {
    method: svConfig.method_post,
    url: `/customer/activity/prediction/apply`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return {
          result: result === null ? [] : result,
          code
        };
      }
      else {
        return [];
      }
    }
  }
}

const confirmActivityCoupon = async (activity_id, codeCoupon) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let myJson = {
    "activity_id": activity_id,
    "code": codeCoupon
  };

  let config = {
    method: svConfig.method_post,
    url: "/customer/activity/coupon/apply",
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      result: result,
      code: code
    };
  }
}

const confirmActivityReward = async (activity_apply_id) => {
  let myJson = {
    "activity_apply_id": activity_apply_id
  };

  let config = {
    method: svConfig.method_post,
    url: "/customer/activity/claim",
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      result: result,
      code: code
    };
  }
}

export {
  loadActivityAll,
  confirmActivityCoupon,
  loadActivityDetail,
  selectVoteActivity,
  loadHistoryDataList,
  loadActivitySelectData,
  confirmActivityReward
};