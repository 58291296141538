import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';

export default function SkeletionScreen(props) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles((theme) => ({
        contentPosition: {
            width: "100%",
            height: "100%"
        },
        cardOther: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: "6px",
                paddingRight: "6px"
            }
        },
        homeList: {
            borderRadius: "10px",
            padding: "40px 0px",
            marginBottom: "10px",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 0px"
            }
        },
        homeRight: {
            height: "20vh",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            marginBottom: "10px",
            borderRadius: "10px"
        },
        homeCardOther: {
            height: "28vh",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "#1E2330",
            marginBottom: "10px",
            borderRadius: "10px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "0px"
            }
        },
        homeCardLast: {
            height: "60vh",
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        if(props.dataCampAll !== null) {
            if(props.dataCampAll.length > 0) {
                if (props.processing === false) {
                    if (document.readyState === "complete") {
                        props.setProcessing(true);
                    }
                    else {
                        window.addEventListener('load', () => {
                            if (document.readyState === "complete") {
                                props.setProcessing(true);
                            }
                        });
                    }
                }
            }
        }
    }, [props.processing, props.dataCampAll]);

    return (
        <div className={classes.contentPosition}>
            <div className="boxGame">
                <div className={classes.cardOther}>
                    <Grid container spacing={isLargeScreen ? 2 : 1}>
                        <Grid item xs={3} md={2}>
                            <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                            <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                            <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                            <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                            <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                        </Grid>
                        <Grid item xs={9} md={10}>
                            <Skeleton animation="wave" variant="rect" className={classes.homeRight} />
                            <Skeleton animation="wave" variant="rect" className={classes.homeRight} />
                            {/* {
                                    renderPageType === "home" ?
                                        <MenuHomeContent></MenuHomeContent>
                                        :
                                        <MenuOtherContent dataCampAll={dataCampAll} isLargeScreen={isLargeScreen}></MenuOtherContent>
                                } */}
                        </Grid>
                    </Grid>
                </div>

                <div className="cardMargin relative">
                    <Skeleton animation="wave" variant="rect" className={classes.homeCardOther} />
                </div>
                <div className="cardMargin relative">
                    <Skeleton animation="wave" variant="rect" className={classes.homeCardOther} />
                </div>
                <div className="cardMargin relative">
                    <Skeleton animation="wave" variant="rect" className={[classes.homeCardOther, classes.homeCardLast].join(" ")} />
                </div>
            </div>
        </div>
    );
}