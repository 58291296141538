import axios from 'axios';
import { decodeToken } from 'react-jwt';
import jwtDefaultConfig from "./jwtDefaultConfig";
import { getToken } from "./../utils";

// @Utility
import { setStorage, getStorage } from "./../../../@utility/app/storage";

const queryString = require('query-string');
const jwtConfig = { ...jwtDefaultConfig };

function getGeneralToken() {
    return getStorage("generalToken");
}

function getCustomerToken() {
    return getStorageJWT("customerToken");
}

function getStorageJWT(key) {
    try {
        return localStorage.getItem(key);
    } 
    catch(e) {
        var nameEQ = key + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }

        return undefined;
    }
}

function setGeneralToken(value) {
    setStorage("generalToken", value);
}

function setAgentHash(value) {
    setStorage("agent_hash", value);
}

function setCustomerToken(value) {
    setStorage("customerToken", value);
}

async function login(data) {
    const { username, password, verify } = data;
    let customerToken = getCustomerToken();

    if (customerToken === null || customerToken === undefined) {
        customerToken = getGeneralToken();
    }

    let myJson = new URLSearchParams({
        "username": username,
        "password": password,
        "verify": verify
    });

    let config = {
        method: jwtConfig.method_post,
        url: jwtConfig.loginEndpoint,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${customerToken}`,
            "Content-Type": jwtConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, token } = dataPromise;

    if (code === 200) {
        setCustomerToken(token);
        return code;
    }
    else {
        return code;
    }
}

async function loginLine(data) {
    const { code: codeLine, url } = data;
    let customerToken = getCustomerToken();

    if (customerToken === null || customerToken === undefined) {
        customerToken = getGeneralToken();
    }

    let myJson = new URLSearchParams({
        "code": codeLine,
        // "affiliate": affiliate,
        // "refer": refer,
        // "know_web": know_web,
        "url": url
    });

    let config = {
        method: jwtConfig.method_post,
        url: jwtConfig.loginLine,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${customerToken}`,
            "Content-Type": jwtConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, token, result } = dataPromise;

    if (code === 200 && token !== "") {
        if (result === null || result === undefined) {
            setCustomerToken(token);

            return {
                code,
                result
            };
        }
        else {
            return {
                code,
                result
            };
        }
    }
    else {
        return {
            code,
            result
        };
    }
}

async function register(data) {
    const {
        username,
        password,
        phone,
        refer,
        affiliate,
        know_web,
        bank,
        bank_account,
        bank_account_name,
        tm_phone,
        allusion
    } = data;
    let customerToken = getCustomerToken();

    if (customerToken === null || customerToken === undefined) {
        customerToken = getGeneralToken();
    }

    let myJson = new URLSearchParams({
        "username": username,
        "password": password,
        "phone": phone,
        "refer": refer,
        "affiliate": affiliate,
        "know_web": know_web,
        "bank_swift": bank,
        "bank_account": bank_account,
        "bank_name": bank_account_name,
        "tm_phone": tm_phone,
        "allusion": allusion
    });

    let config = {
        method: jwtConfig.method_post,
        url: jwtConfig.registerEndpoint,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${customerToken}`,
            "Content-Type": jwtConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);

    const { code, token } = dataPromise;
    if (code === 200) {
        setCustomerToken(token);
        return code;
    }
    else {
        return code;
    }
}

async function registerShort(data) {
    const {
        username,
        password,
        affiliate,
        refer,
        know_web,
        allusion,
        bank_account,
        bank_name,
        bank_swift
    } = data;

    let customerToken = getCustomerToken();

    if (customerToken === null || customerToken === undefined) {
        customerToken = getGeneralToken();
    }

    let myJson = {
        "username": username,
        "password": password,
        "refer": refer,
        "affiliate": affiliate,
        "know_web": know_web,
        "bank_swift": bank_swift,
        "bank_account": bank_account,
        "bank_name": bank_name,
        "allusion": allusion
    };

    let config = {
        method: jwtConfig.method_post,
        url: jwtConfig.registerEndpointShort,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${customerToken}`,
            "Content-Type": jwtConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);

    const { code, token, password: passwordCustomer } = dataPromise;
    if (code === 200) {
        setCustomerToken(token);
        return {
            code,
            password: passwordCustomer
        };
    }
    else {
        return {
            code,
            password: null
        };
    }
}

async function checkUsername(data) {
    const {
        username,
        phone
    } = data;
    let customerToken = getCustomerToken();

    if (customerToken === null || customerToken === undefined) {
        customerToken = getGeneralToken();
    }

    let myJson = new URLSearchParams({
        "username": username,
        "phone": phone
    });

    let config = {
        method: jwtConfig.method_post,
        url: jwtConfig.checkAccount,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${customerToken}`,
            "Content-Type": jwtConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);

    const { code, token } = dataPromise;
    if (code === 200) {
        setCustomerToken(token);
        return code;
    }
    else {
        return code;
    }
}

async function checkBankAccount(data) {
    const {
        bank,
        bank_account
    } = data;
    let customerToken = getCustomerToken();

    if (customerToken === null || customerToken === undefined) {
        customerToken = getGeneralToken();
    }

    let myJson = new URLSearchParams({
        "account": bank_account,
        "swift": bank
    });

    let config = {
        method: jwtConfig.method_post,
        url: jwtConfig.checkBankAccount,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${customerToken}`,
            "Content-Type": jwtConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);

    const { code, token } = dataPromise;
    if (code === 200) {
        setCustomerToken(token);
        return code;
    }
    else {
        return code;
    }
}

function checkExpireToken() {
    let customerToken = getCustomerToken();
    let tokenJson = decodeToken(customerToken);
    let expireToken = tokenJson.exp - 172800;
    let dateCurrent = Math.floor((new Date().getTime()) / 1000);
    if (dateCurrent > expireToken) {
        return true;
    }
    else {
        return false;
    }
}

function checkExpireTokenGeneral() {
    let customerToken = getGeneralToken();
    let tokenJson = decodeToken(customerToken);
    let expireToken = tokenJson.exp - 172800;
    let dateCurrent = Math.floor((new Date().getTime()) / 1000);
    if (dateCurrent > expireToken) {
        return true;
    }
    else {
        return false;
    }
}

async function refreshToken() {
    let customerToken = getCustomerToken();
    let config = {
        method: jwtConfig.method_get,
        url: jwtConfig.refreshToken,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${customerToken}`,
            "Content-Type": jwtConfig.contentType
        },
        data: {}
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);

    const { code, token } = dataPromise;
    if (code === 200) {
        setCustomerToken(token);
        return code;
    }
    else {
        return code;
    }
}

async function rememberPassword(username) {
    let myJson = new URLSearchParams({
        "username": username
    });

    let config = {
        method: jwtConfig.method_post,
        url: `/auth/reset-password`,
        headers: {
            "Authorization": `${jwtConfig.headerType} ${getToken()}`,
            "Content-Type": jwtConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);

    const { code, token } = dataPromise;
    if (code === 200) {
        setCustomerToken(token);
        return code;
    }
    else {
        return code;
    }
}

export {
    getGeneralToken,
    getCustomerToken,
    setGeneralToken,
    setCustomerToken,
    login,
    loginLine,
    register,
    setAgentHash,
    checkExpireToken,
    checkExpireTokenGeneral,
    checkUsername,
    checkBankAccount,
    refreshToken,
    rememberPassword,
    registerShort
}