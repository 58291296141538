import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// @utility
import { setStorage } from "./../../../../../../../@utility/app/storage";
import alertText from "./../../../../../../../@utility/app/alertText";

// auth
import { isUserLoggedIn } from "./../../../../../../../@core/auth/utils";

// Services
import {
    confirmDeposit
} from "./../../../../../../../@core/services/depositService";

// Redux
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";
import { setDataAlert } from "./../../../../../../../@core/redux/actions/app/alert";
import { setDepositData } from "./../../../../../../../@core/redux/actions/deposit/index";

// Component
import MenuHome from './MenuHome';

const DepositMenuApp = ({ reduxAppCMS }) => {
    const depositData = useSelector(state => state.reduxDeposit.result);
    const history = useHistory();
    const dataBankCustomerList = useSelector(state => state.reduxDeposit.dataBank);
    const dispatch = useDispatch();
    let snackbarJson = {};

    // ทดสอบ 
    const gotoPage = (item) => {
        if (item.key === "automatic") {
            if (isUserLoggedIn()) {
                if (depositData.nick_name) {
                    history.push(`/deposit/form/${item.key}`);
                }
                else {
                    let dataRes = dataBankCustomerList;

                    if (dataRes !== null) {
                        let dataFilter = {};

                        if (item.key === "truemoney") {
                            dataFilter = dataRes.find((item) => (item.swift_code === "TRUEMONEY"));
                        }
                        else {
                            dataFilter = dataRes.find((item) => (item.swift_code !== "TRUEMONEY"));
                        }

                        if (dataFilter !== undefined) {
                            let milliseconds = new Date().getTime();
                            setStorage("timeDepositProcess", milliseconds);
                            dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังโหลดข้อมูล กรุณารอสักครู่" }));

                            let myJson = {
                                amount: -1,
                                bankId: dataFilter.id,
                                typePage: item.key,
                                channel: item.key,
                                channelId: item.id
                            };

                            confirmDeposit(myJson).then((data) => {
                                const statusCode = data.code;
                                dispatch(setStatusSpinner({ status: false, textProcess: null }));

                                if (statusCode === 200 || statusCode === 0) {
                                    dispatch(setDepositData(data.result));
                                    setStorage("processDeposit", data.result.id);

                                    history.push(`/deposit/form/${item.key}`);
                                }
                                else {
                                    if (statusCode === 2 || statusCode === 4) {
                                        snackbarJson = alertText(statusCode);
                                        openBoxAlertWarning(snackbarJson);

                                        setTimeout(function () {
                                            window.location.href = `/`;
                                        }, 5000);
                                    }
                                    else {
                                        snackbarJson = alertText(statusCode);
                                        openBoxAlertWarning(snackbarJson);
                                    }
                                }
                            });
                        }
                        else {
                            history.push(`/deposit/form/${item.key}`);
                        }
                    }
                    else {
                        history.push(`/deposit/form/${item.key}`);
                    }
                }
            }
            else {
                history.push(`/deposit/form/${item.key}`);
            }
        }
        else if (item.key === "truemoney") {
            history.push(`/deposit/form/${item.key}`);
        }
        else {
            history.push(`/deposit/form/${item.key}`);
        }
    };

    const openBoxAlertWarning = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    // useEffect(() => {
    //     if(depositData !== null) {
    //         if(depositData.mode === "deposit") {
    //             if(dataBankCustomerList === null) {
    //                 // loadBankDeposit();
    //             }
    //         }
    //     }
    // }, [depositData, dataBankCustomerList]);

    // useEffect(() => {
    //     if (loadBank) {
    //         if (depositData?.mode === "deposit") {
    //             if (depositData.channel.automatic === true && depositData.channel.truemoney === false && depositData.channel.manual === false) {
    //                 let getItem = dataBank.find((item) => (item.key === "automatic"));

    //                 if (getItem !== undefined) {
    //                     gotoPage(getItem);
    //                 }
    //             }
    //             else if (depositData.channel.automatic === false && depositData.channel.truemoney === true && depositData.channel.manual === false) {
    //                 let getItem = dataBank.find((item) => (item.key === "truemoney"));

    //                 if (getItem !== undefined) {
    //                     gotoPage(getItem);
    //                 }
    //             }
    //             else if (depositData.channel.automatic === false && depositData.channel.truemoney === true && depositData.channel.manual === false) {
    //                 let getItem = dataBank.find((item) => (item.key === "qrcode"));

    //                 if (getItem !== undefined) {
    //                     gotoPage(getItem);
    //                 }
    //             }
    //         }
    //     }
    // }, [loadBank]);

    return <MenuHome depositData={depositData} reduxAppCMS={reduxAppCMS} gotoPage={gotoPage}></MenuHome>;
}

export default DepositMenuApp;