import {
    SET_LOAD_HOME,
    SET_LOAD_IMAGES,
    SET_LOAD_PROCESSING
} from '../../actions/app/processing';

const initialState = {
    home: false,
    images: false,
    processing: true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOAD_HOME:
            return { ...state, home: action.payload.items };
        case SET_LOAD_IMAGES:
            return { ...state, images: action.payload.items };
        case SET_LOAD_PROCESSING:
            return { ...state, processing: action.payload.items };
        default:
            return state;
    }
}