// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Card 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// @Utility
import timeZone from "./../../../../../../../@utility/app/timeZone";
import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";
import alertText from "./../../../../../../../@utility/app/alertText";
// import validateInput from "./../../../../../../../@utility/app/validateInput";

// Services
import { claimInviteAll } from "./../../../../../../../@core/services/affiliateService";
import { loadHomeData } from "../../../../../../../@core/services/homeService";

// Redux
import { setDataAlert } from "./../../../../../../../@core/redux/actions/app/alert";
import { 
    loadDataInviteAll,
    loadCliamInvite
} from "./../../../../../../../@core/redux/actions/affiliate/index";
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";
import { setHomeData } from "../../../../../../../@core/redux/actions/home/index";

// CSS
import buttonStyles from "./../../../../assets/css/Button.module.css";

export default function ModeTwoCommission({ reduxAppCMS, inviteData }) {
    const dispatch = useDispatch();
    const cliamData = useSelector(state => state.reduxAffiliate.cliamData);
    let snackbarJson = {};

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            padding: "20px 0px"
        },
        cardWhite: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        cardBlack: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black"
        },
        textDetail: {
            padding: "0px 20px",
            fontSize:"16px"
        },
        textAmountHead: {
            fontSize: "18px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white"
        },
        textAmount: {
            fontSize: "24px",
            fontWeight: "500",
            paddingTop: "12px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        textTitle: {
            fontSize: "18px",
            fontWeight: "500",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            paddingBottom: "10px"
        },
        inputContainer: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            borderRadius: "6px 0px 0px 6px",
            padding: "8px 6px"
        },
        inputStyles: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            border: "0px",
            padding: "8px 0px",
            borderRadius: "6px 0px 0px 6px",
            width: "100%",
            fontSize: "16px"
        },
        // btnContainer: {
        //     background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
        //     borderRadius: "6px",
        //     cursor: "pointer",
        //     fontSize: "18px",
        //     fontWeight: "600",
        //     display: "inline-block",
        //     width: "180px",
        //     padding: "8px",
        //     '&:hover': {
        //         opacity: "0.8"
        //     }
        // },
        textComment: {
            fontSize: "14px",
            opacity: "0.8"
        },
        tableSize: {
            width: "100%",
            borderSpacing: "0px",
            fontSize: "14px"
        },
        tableTextHead: {
            fontSize: "16px",
            fontWeight: "400 !important",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white",
            borderBottom: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"}`,
            paddingBottom: "8px"
        },
        rowList: {
            padding: "8px 0px",
            borderBottom: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"}`
        },
        dataNonePadding: {
            textAlign: "center",
            padding: "40px 80px",
            fontSize: "16px",
            color: "rgb(255, 193, 7)",
            [theme.breakpoints.down('sm')]: {
                padding: "40px 60px"
            }
        },
        textNone: {
            fontSize: "18px",
            color: "white",
            marginBottom: "30px"
        },
        reportNone: {
            width: "100%"
        },
        textColorDanger: {
            fontSize: "16px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "white"
        },
        btnFooter: {
            textAlign: "center",
            padding: "12px 24px",
            borderRadius: "12px",
            fontSize: "18px",
            cursor: "pointer",
            color: "white",
            border: "2px solid #FFFFFF",
            background: "transparent",
            position: "relative",
            '&:hover': {
                top: "2px",
                opacity: "0.8"
            }
        },
        tableLabel: {
            display: "inline-block",
            padding: "2px 12px",
            borderRadius: "12px",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-sucess"] : "green",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        }
    }));

    const classes = useStyles();

    // const handleNumber = (event) => {
    //     setAmount(event.target.value);
    // };

    const withdrawAmount = () => {
        if(inviteData.info.balance >= inviteData.withdraw.min_withdraw) {
            dispatch(setStatusSpinner({status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..."}));
            claimInviteAll("/customer/affiliate/claim").then((data) => {
                dispatch(setStatusSpinner({status: false, textProcess: null}));
                const statusCode = data.code;
                if (data.code === 0 || data.code === 200) {
                    reloadBalance();
                    snackbarJson = {
                        statusShow: true,
                        statusAlert: "success",
                        titleAlert: "แจ้งเตือน",
                        textAlert: "ถอนรายได้สำเร็จ !"
                    };

                    dispatch(loadDataInviteAll());
                    dispatch(loadCliamInvite(10, 0));
                    alertPopup(snackbarJson);
                }
                else {
                    alertResponse(statusCode);
                }
            });
        }
        else {
            alertPopup({
                statusShow: true,
                statusAlert: "warning",
                titleAlert: "แจ้งเตือน",
                textAlert: `ถอนรายได้ ขั้นต่ำ ${inviteData.withdraw.min_withdraw} บาท`
            });
        }
    };

    const reloadBalance = () => {
        loadHomeData().then((data) => {
            const statusCode = data.code;
            if (data.code === 0 || data.code === 200) {
                dispatch(setHomeData(data.result));
            }
            else {
                alertResponse(statusCode);
            }
        });
    };

    const alertResponse = (statusCode) => {
        if (statusCode === 2 || statusCode === 4) {
            snackbarJson = alertText(statusCode);
            alertPopup(snackbarJson);

            setTimeout(function () {
                window.location.href = `/`;
            }, 5000);
        }
        else {
            snackbarJson = alertText(statusCode);
            alertPopup(snackbarJson);
        }
    };

    const alertPopup = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    const loadMore = () => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));
        dispatch(loadCliamInvite((cliamData.length + 10), 0));
    };

    return (
        inviteData !== null && (
            <>
                <Card className={[classes.root, classes.cardWhite].join(" ")}>
                    <div className={classes.textDetail}>
                        รายได้จากระบบแนะนำ จะถอนเข้าเป็นเงินเครดิตของคุณ และสามารถถอนเป็นเงินสด หรือนำไปเดิมพันเกมอื่นได้
                    </div>
                </Card>
                <br />
                <div className={classes.textTitle}>
                    ถอนรายได้ (ขั้นต่ำ {inviteData.withdraw.min_withdraw} บาท)
                </div>
                <Card className={[classes.root, classes.cardBlack].join(" ")}>
                    <div className="center">
                        <div className={classes.textAmountHead}>
                            รายได้คงเหลือ
                        </div>
                        <div className={classes.textAmount}>
                            { currencyFormat((inviteData.info.balance), 2) }
                        </div>
                        <br /> 
                        <button type="submit" className={buttonStyles.btnConfirm} onClick={withdrawAmount}>
                            ถอน
                        </button>
                        {/* <div  className={classes.btnContainer} onClick={withdrawAmount}>
                            ถอน
                        </div> */}
                    </div>
                </Card>
                <br /><br /> 
                {/* <div className={classes.textTitle}>
                    ถอนรายได้ (ขั้นต่ำ {inviteData.withdraw.min_withdraw} บาท)
                </div>
                <Grid container>
                    <Grid item xs={8} md={9} className={classes.inputContainer}>
                        <input type="number" className={[classes.inputStyles, "kanit"].join(" ")} min={inviteData.withdraw.min_withdraw} max={inviteData.withdraw.max_withdraw} placeholder="ระบุจำนวนที่ต้องการถอน" onKeyPress={(event) => validateInput(event, "number")} onChange={handleNumber} required />
                    </Grid>
                    <Grid item xs={4} md={3} className={classes.btnContainer} onClick={withdrawAmount}>
                        <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                            <Grid item>
                                ถอน
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.textComment}>
                    ถอนได้สูงสุดไม่เกิน {inviteData.withdraw.max_withdraw} บาท
                </div>
                <br /><br /> */}
                {
                    cliamData !== null && (
                        <div className="center">
                            <div className={classes.textTitle}>
                                ประวัติการถอนรายได้
                            </div>
                            <table className={classes.tableSize}>
                                <thead>
                                    <tr className="center">
                                        <th className={[classes.tableTextHead, "left"].join(" ")}>วันที่กดถอน</th>
                                        <th className={[classes.tableTextHead, "right"].join(" ")}>จำนวน</th>
                                        <th className={classes.tableTextHead}>สถานะ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cliamData.length > 0 ?
                                        cliamData.map((item, index) => (
                                            <tr key={index}>
                                                <td className={[classes.rowList, "left"].join(" ")}>{timeZone(item.time_create)}</td>
                                                <td className={[classes.rowList, "right"].join(" ")}>
                                                    <span className={classes.textColorDanger}>-{currencyFormat((item.amount), 2)}</span> บาท
                                                </td>
                                                <td className={[classes.rowList, "center"].join(" ")}>
                                                    <div className={[classes.tableLabel, classes.statusSuccess].join(" ")}>
                                                        สำเร็จ
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={12}>
                                                <div className={classes.dataNonePadding}>
                                                    <div className={classes.textNone}>
                                                        ไม่มีข้อมูล
                                                    </div>
                                                    <img src={"/images/game/no_game.webp"} className={classes.reportNone} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            {
                                cliamData.length >= 10 && (
                                    <>
                                        <br /><br />
                                        <div className={classes.btnFooter} onClick={loadMore}>
                                            โหลดข้อมูลเพิ่มเติม
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    )
                }
            </>
        )
    );
}