import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const changePassword = async (data, path = `/customer/profile/reset-password`) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
    const {
        password_old,
        password,
        password_confirm
    } = data;

    let myJson = {
        "password_old": password_old,
        "password": password,
        "password_confirm": password_confirm
    };

    let config = {
        method: svConfig.method_post,
        url: path,
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: queryString.stringify(myJson)
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
    const { code } = dataPromise;

    if (checkStatusCode(code)) {
        if (code === undefined) {
            return 500;
        }
        else {
            return code;
        }
    }
}

export {
    changePassword
};