import axios from 'axios';
import * as jwtService from "./../auth/jwt/jwtService";
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const setTokenGeneral = async (web = process.env.REACT_APP_DEFAULT_AGENT_CODE) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let myJson = {};
  if (web === undefined) {
    myJson = {
      "agent_code": "tonyslot168"
    };
  }
  else {
    myJson = {
      "agent_code": web
    };
  }

  let config = {
    method: svConfig.method_post,
    url: '/auth/login-general',
    headers: {
      'Content-Type': svConfig.contentType,
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, token } = dataPromise;

  if (code === 200) {
    jwtService.setGeneralToken(token);
    jwtService.setAgentHash(web);
  }
  else {
    jwtService.setGeneralToken(undefined);
    return { code: code };
  }
}

const checkAuthToken = async (item = null) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  if (item !== null) {
    let myJson = {
      "token": item
    };

    let config = {
      method: svConfig.method_post,
      url: '/auth/login-token',
      headers: {
        'Content-Type': svConfig.contentType,
      },
      data: queryString.stringify(myJson)
    };
  
    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, token } = dataPromise;
  
    if (code === 200) {
      jwtService.setCustomerToken(token);
    }

    return code;
  } 
  else {
    return null;
  }
}

const loadSetting = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  try {
    let config = {
      method: svConfig.method_get,
      url: `/general/setting/${process.env.REACT_APP_DEFAULT_AGENT_CODE}`,
      headers: {
        "Authorization": `${svConfig.headerType} ${getToken()}`,
        "Content-Type": svConfig.contentType
      },
      data: {}
    };
  
    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  
    if (checkStatusCode(dataPromise.code)) {
      return dataPromise;
    }
  } catch (error) {
    return null;
  }
}

const loadUrlLineLogin = async (data) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let myJson = {
    "url": data
  };

  let config = {
    method: svConfig.method_post,
    url: "/general/generate/login-line",
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: queryString.stringify(myJson)
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const loadBalance = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  
  let config = {
    method: svConfig.method_get,
    url: `/customer/profile/balance`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0 || code === 200) {
      return {
        result: result,
        code: code
      };
    }
    else {
      return {
        result: [],
        code: code
      };
    }
  }
}

export {
  setTokenGeneral,
  loadSetting,
  loadUrlLineLogin,
  loadBalance,
  checkAuthToken
};