import { 
    SET_DATA_CASHBACK,
    OPEN_CASHBACK
}  from './../../actions/cashback/index';

const initialState = {
    tab: "cashback",
    show: false,
    result: null
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case OPEN_CASHBACK:
            return { ...state, ...action.payload.items };
        case SET_DATA_CASHBACK:
            return { ...state, result: action.payload.items };
        default: 
            return state;
    } 
}