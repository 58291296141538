import { loadWithdrawRank } from "./../../../../@core/services/withdrawService";

const SET_WITHDRAW_DATA = 'app/SET_WITHDRAW_DATA';
const SET_WITHDRAW_RANK_DATA = 'app/SET_WITHDRAW_RANK_DATA';

function setWithdrawData(data) {
    return {
        type: SET_WITHDRAW_DATA,
        payload: {
            items: data
        }
    }
}

function setWithdrawRankData() {
    return dispatch => {
        loadWithdrawRank().then((data) => {
            dispatch({
                type: SET_WITHDRAW_RANK_DATA,
                payload: {
                    items: data
                }
            })
        });
    }
}

export {
    SET_WITHDRAW_DATA,
    SET_WITHDRAW_RANK_DATA,
    setWithdrawData,
    setWithdrawRankData
}