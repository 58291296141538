const OPEN_PROFILE = 'app/OPEN_PROFILE';

function openProfile(data) {
    return {
        type: OPEN_PROFILE,
        payload: {
            items: data
        }
    }
}

export {
    OPEN_PROFILE,
    openProfile
}