import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// Hooks 1
import useParameterUrl from "./../@utility/hooks/useParameterUrl";

// Redux
import { setSetting } from './../@core/redux/actions/app/menuSetting';
import { setStatusDropdown } from "./../@core/redux/actions/app/dropdrown";

// Check token
import { isToken } from "./../@core/auth/utils";
import { isUserLoggedIn } from "./../@core/auth/utils";

// Services
import { loadSetting } from './../@core/services/appService';
import { checkAuthToken } from './../@core/services/appService';

// Layout
import Layouts from "./app-mode/Layouts";

const ViewsModule = ({ loadRoutes }) => {
  const dispatch = useDispatch();
  const statusDropdown = useSelector(state => state.reduxAppDropdown.status);
  const parsed = useParameterUrl();
  const { token } = parsed;
  const [loadContent, setLoadContent] = useState(false);

  const clickFullScreen = () => {
    if (statusDropdown === true) {
      dispatch(setStatusDropdown(false));
    }
  };

  useEffect(() => {
    if(loadRoutes) {
      if (isToken()) {
        loadSetting().then((data) => {
          if(data?.result !== undefined) {
            dispatch(setSetting(data.result));
          }
        });
      }
    }
  }, [dispatch, loadRoutes]);

  useEffect(() => {
    if(loadRoutes) {
      if (isToken()) {
        if(isUserLoggedIn()) {
          setLoadContent(true);
        }
        else {
          if(token !== null && token !== undefined && token !== "undefined" &&  token !== "") {
            checkAuthToken(token).then(() => {
              setLoadContent(true);
            });
          }
          else {
            setLoadContent(true);
          } 
        }
      }
    }
  }, [token, loadRoutes]);

  return (
    loadContent && (
      <div onClick={() => { clickFullScreen() }}>
        <Router>
          <Layouts loadRoutes={loadRoutes}></Layouts>
        </Router>
      </div>
    )
  );
}

export default ViewsModule;