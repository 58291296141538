const SET_DATA_DIALOG = 'app/SET_DATA_DIALOG';

function setDataDialog(data) {
    return {
        type: SET_DATA_DIALOG,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_DIALOG,
    setDataDialog
}