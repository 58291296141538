import { lazy } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// Routes page
const WithdrawMenuApp = lazy(() => import("./page/menu/index"));
const MenuChannelApp = lazy(() => import("./page/channel/index"));
const WithdrawFormApp = lazy(() => import("./page/form-action/index"));
// const WithdrawPendingApp = lazy(() => import("./page/pending/index"));

export default function Routes({ isUserLoggedIn, withdrawItems }) {
    const { path } = useRouteMatch(); // useRouteMatch สามารถเปลี่ยนได้แบบ Dynamic
    // const withdrawItems = useSelector(state => state.reduxWithdraw.result);

    const renderHomeRoutes = () => {
        return (
            <Switch>
                <Route path={`${path}/home`} exact>
                    <WithdrawMenuApp withdrawItems={withdrawItems}></WithdrawMenuApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/withdraw/home" />
                </Route>
            </Switch>
        );
    };

    const renderChannelRoutes = (typeSelect) => {
        return (
            <Switch>
                <Route path={`${path}/form/:type`} exact>
                    <WithdrawFormApp withdrawItems={withdrawItems} typeSelect={typeSelect}></WithdrawFormApp>
                </Route>
                <Route path={`${path}/home`} exact>
                    <MenuChannelApp withdrawItems={withdrawItems}></MenuChannelApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/withdraw/home" />
                </Route>
            </Switch>
        );
    };

    const renderOtherRoutes = () => {
        return (
            <Switch>
                {/* <Route path={`${path}/pending`} exact>
                    <WithdrawPendingApp></WithdrawPendingApp>
                </Route> */}
                <Route path={`${path}/home`} exact>
                    <WithdrawFormApp withdrawItems={withdrawItems}></WithdrawFormApp>
                </Route>
                <Route path="*" >
                    <Redirect to="/withdraw/home" />
                </Route>
            </Switch>
        );
    };

    const renderRouter = () => {
        if (!withdrawItems) return null;

        if (!isUserLoggedIn) {
            return renderHomeRoutes();
        }
        else {
            if (withdrawItems.mode === "withdraw") {
                return renderChannelRoutes("form");
            }
            else if (withdrawItems.mode === "wait-withdraw") {
                return renderOtherRoutes();
            }
            else {
                if (withdrawItems.mode === "promotion" && (withdrawItems.target_customer >= withdrawItems.target)) {
                    return renderChannelRoutes("form");
                }
                else {
                    return renderHomeRoutes();
                }
            }
        }
    }

    return renderRouter();
};