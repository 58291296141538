import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { setWithdrawData } from "./../../../../../@core/redux/actions/withdraw/index";
import { setStatusSpinner } from "./../../../../../@core/redux/actions/app/spinner";

// Routes
import WithdrawRoutes from "./Routes";

// auth
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// Services
import { loadWithdrawFirst } from "./../../../../../@core/services/withdrawService";

// @utility
import alertText from "./../../../../../@utility/app/alertText";

// Components
import CloseButton from "./../../components/CloseButton";
import SnackbarSet from "./../../components/SnackbarSet";

//  CSS
import modalStyles from "./../../assets/css/Modal.module.css";

const Layouts = ({ menu_status = 1 }) => {
    document.title = process.env.REACT_APP_OG_SITE_NAME + " - ถอนเงิน";
    const childRef = useRef();
    const history = useHistory();
    let snackbarJson = {};
    const dispatch = useDispatch();
    const withdrawItems = useSelector(state => state.reduxWithdraw.result);
    // const [loadRoutes, setLoadRoutes] = useState(false);
    // const [withdrawItems, setWithdrawItems] = useState(null);

    useEffect(() => {
        if (menu_status !== 1 && menu_status !== undefined) {
            history.push("/");
        }

        if (isUserLoggedIn()) {
            if (withdrawItems === false) {
                dispatch(setStatusSpinner({ status: true, textProcess: null }));
            }

            // setLoadRoutes(false);

            loadWithdrawFirst().then((data) => {
                const statusCode = data.code;
                dispatch(setStatusSpinner({ status: false, textProcess: null }));
                // setLoadRoutes(true);

                if (statusCode === 200 || statusCode === 0) {
                    // setWithdrawItems(data.result);
                    dispatch(setWithdrawData(data.result));
                }
                else {
                    dispatch(setWithdrawData(null));

                    if (statusCode === 2 || statusCode === 4) {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);

                        setTimeout(function () {
                            window.location.href = `/`;
                        }, 5000);
                    }
                    else {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);
                    }
                }
            });
        }

        // setLoadRoutes(true)
    }, [dispatch]);

    return <>
        <div className={modalStyles.contentPosition}>
            <div className={modalStyles.boxBodyFull}>
                <div className={modalStyles.boxFull}>
                    <div className={modalStyles.closeButtonFull}>
                        <CloseButton backPage={"/"}></CloseButton>
                    </div>
                    <div className={modalStyles.boxContentWidthFull}>
                        {
                            // loadRoutes && (
                            (withdrawItems || !isUserLoggedIn()) && (
                                <WithdrawRoutes isUserLoggedIn={isUserLoggedIn()} withdrawItems={withdrawItems}></WithdrawRoutes>
                            )
                            // )
                        }
                    </div>
                </div>
            </div>
        </div>


        <div className={modalStyles.fixedBackdrop}></div>

        <SnackbarSet ref={childRef} />
    </>;
}

export default Layouts;