import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

// @utility
import alertText from "./../../../../../../@utility/app/alertText";
import isHtml from "./../../../../../../@utility/app/isHtml";

// auth
import { isUserLoggedIn } from "./../../../../../../@core/auth/utils";

// Services
import { openGame } from "./../../../../../../@core/services/gameService";

// Redux
import { openAuthenApp } from "./../../../../../../@core/redux/actions/auth/index";

// Components
import LoadSpinner from './../../../components/LoadSpinner';

// Hooks
import useParameterUrl from "./../../../../../../@utility/hooks/useParameterUrl";

// Icon
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// CSS
import buttonStyles from "./../../../assets/css/Button.module.css";

export default function ContentGame({ camp, gamecode }) {
    const [loading, setLoadding] = useState(false);
    const [urlGame, setUrlGame] = useState("");
    const [dataTextAlert, setDataTextAlert] = useState("");
    const parsed = useParameterUrl();
    const { selectPage } = parsed;
    const history = useHistory();
    const [statusPlay, setStatusPlay] = useState(null);
    const [urlImg, setUrlImg] = useState('');
    const [height, setHeight] = useState(window.innerHeight);
    const dispatch = useDispatch();
    const iframeRef = useRef();
    window.scrollTo(0, 0);
    const [iframeHtml, setIframeHtml] = useState(false);

    const useStyles = makeStyles((theme) => ({
        playGame: {
            width: "100%",
            height: "100%",
            minHeight: (height - 64) + "px",
            [`${theme.breakpoints.up("sm")} and (max-device-height: 1000px)`]: {
                minHeight: (height - 29) + "px",
            },
            overflow: "hidden",
        },
        close: {
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: "100010",
        },
        boxGameLikeNone: {
            background: "white",
            // border: "2px solid #8A2EFF",
            boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
            display: "inline-block",
            padding: "40px",
            borderRadius: "10px",
            width: "340px",
            [theme.breakpoints.down('xs')]: {
                width: "80%",
                padding: "18px"
            }
        },
        boxGameNone: {
            textAlign: "center"
        },
        gameLikeTitle: {
            fontSize: "24px",
            color: "black",
            marginBottom: "10px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "18px"
            }
        },
        gameTitle: {
            fontSize: "24px",
            color: "black",
            marginBottom: "10px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "18px"
            }
        },
        gameLikeDetail: {
            fontSize: "18px",
            color: "black",
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px"
            }
        },
        gameLikeImgPosition: {
            margin: "40px 0px"
        },
        gameLikeImg: {
            width: "220px",
            height: "auto",
            borderRadius: "12px",
            [theme.breakpoints.down('sm')]: {
                width: "160px",
            }
        },
        boxGameLikeNonePosition: {
            margin: "40px 0px",
            [theme.breakpoints.down('xs')]: {
                margin: "20px 0px",
            }
        },
        iconSize: {
            marginTop: "8px",
            width: "28px",
            height: "28px"
        },
        boxMargin: {
            marginTop: "50px"
        },
        boxGameNonePosition: {
            marginTop: "60px"
        }
    }));

    const classes = useStyles();

    const gotoPage = () => {
        history.goBack();
    }

    const gotoRegister = () => {
        let myObj = {
            tab: "register",
            show: true
        };

        dispatch(openAuthenApp(myObj));
        history.push("/");
    }

    const iframeLoad = () => {
        setLoadding(false);

        try {
            let baseUrl = iframeRef.current.contentWindow.location.href;
            let currentUrl = window.location.origin;

            if (baseUrl === currentUrl || baseUrl === `${currentUrl}/` || baseUrl === "" || baseUrl === undefined || baseUrl === null) {
                history.push("/");
            }
        }
        catch (err) {
            console.log(err)
        }
    };

    // console.log(window.location.origin)

    function handleResize() {
        setHeight(window.innerHeight)
    };

    useEffect(() => {
        setLoadding(true);
        openGame(camp, gamecode).then((data) => {
            setLoadding(false);
            const statusCode = data.code;
            if (statusCode === 0) {
                if (data.result.open === 2) {
                    let windowReference = window.open();
                    // const userAgent = window.navigator.userAgent;
                    const link = data.result.url;
                    setStatusPlay(null);

                    window.addEventListener('popstate', function (event) {
                        if (history.location?.key === undefined) {
                            history.push("/");
                        }
                        else {
                            history.goBack();
                        }
                    });

                    try {
                        windowReference.location = link;

                        if (history.location?.key === undefined) {
                            history.push("/");
                        }
                        else {
                            history.goBack();
                        }
                    } catch (error) {
                        document.location.href = link;
                    }
                }
                else {
                    const checkTypePlay = isHtml(data.result.url);
                    if (checkTypePlay === "html") {
                        setIframeHtml(true);
                    }

                    setUrlGame(data.result.url);
                    setStatusPlay(true);
                }
                // setStatusPromotion(false);
            }
            else {
                // ผิดพลาด
                let dataText = alertText(statusCode);
                setDataTextAlert(dataText.textAlert)
                setStatusPlay(false);
                setUrlImg(data?.result?.image);
            }
        });

        window.addEventListener('resize', handleResize);
    }, [camp, gamecode, selectPage]);

    return (
        <>
            {
                statusPlay !== null && (
                    statusPlay ?
                        iframeHtml ?
                            <iframe
                                title="เล่นเกม"
                                className={classes.playGame}
                                srcDoc={urlGame}
                                frameBorder="0"
                                allowFullScreen
                                onLoad={iframeLoad}
                                ref={iframeRef}
                                height="100%"
                                width="100%"
                                crossOrigin="anonymous"
                            />
                            :
                            <iframe
                                title="เล่นเกม"
                                className={classes.playGame}
                                src={urlGame}
                                frameBorder="0"
                                allowFullScreen
                                onLoad={iframeLoad}
                                ref={iframeRef}
                                height="100%"
                                width="100%"
                                crossOrigin="anonymous"
                            />
                        :
                        <div className={classes.boxGameNonePosition}>
                            <div className={classes.boxGameNone}>
                                <div className={classes.boxGameLikeNone}>
                                    <div className={classes.gameLikeTitle}>
                                        แจ้งเตือน
                                    </div>
                                    <div className={classes.gameLikeDetail}>
                                        {
                                            isUserLoggedIn() ?
                                                dataTextAlert
                                                :
                                                "เกมนี้ไม่สามารถเล่นในโหมดทดลองได้ กรุณาเลือกเล่นเกมอื่น ๆ"
                                        }
                                    </div>
                                    <div className={[classes.gameLikeImgPosition, "relative"].join(" ")}>
                                        <img src={urlImg} className={classes.gameLikeImg} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                    </div>
                                    {
                                        isUserLoggedIn() ?
                                            <div className={[buttonStyles.btnRedirect, classes.boxMargin].join(" ")} onClick={gotoPage}>
                                                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                    <Grid item>
                                                        ไปยังหน้าเกม
                                                    </Grid>
                                                    <Grid item>
                                                        <ArrowForwardIcon className={classes.iconSize}></ArrowForwardIcon>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            :
                                            <div className={[buttonStyles.btnRedirect, classes.boxMargin].join(" ")} onClick={gotoRegister}>
                                                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                    <Grid item>
                                                        สมัครสมาชิก
                                                    </Grid>
                                                    <Grid item>
                                                        <ArrowForwardIcon className={classes.iconSize}></ArrowForwardIcon>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                )
            }

            {
                loading && (
                    <LoadSpinner></LoadSpinner>
                )
            }
        </>
    );
}