import { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import "moment/locale/th";

// @utility
import { removeStorage } from "./../../../../../../../@utility/app/storage";
import alertText from "./../../../../../../../@utility/app/alertText";
// import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";
import validateInput from "./../../../../../../../@utility/app/validateInput";

// Redux
import { setDataAlert } from "./../../../../../../../@core/redux/actions/app/alert";
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";
import { setDepositData } from "./../../../../../../../@core/redux/actions/deposit/index";
import { setDataContact } from "./../../../../../../../@core/redux/actions/app/contact";


// Hooks
// import useParameterUrl from "./../../../../../../../@utility/hooks/useParameterUrl";
// import useContact from "./../../../../../../../@utility/hooks/useContact";

// Services
import {
    checkDeposit,
    cancenDeposit,
    loadDepositFirst,
    // confirmStep,
    confirmPaySlip
} from "./../../../../../../../@core/services/depositService";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Components  
// import CloseButton from "./../../../../components/CloseButton";
import SnackbarSet from "./../../../../components/SnackbarSet";

// Icons
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

moment.locale("th");

export default function PendingSlip({ depositItems }) {
    const locale = "th";
    // const [timeShow, setTimeShow] = useState(null);
    const [selectedDate, handleDateChange] = useState(moment().format("MM/DD/yyyy HH:mm"));
    const childRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    let snackbarJson = {};
    const [imageUpload, setImageUpload] = useState(null);
    // const parsed = useParameterUrl();
    // const { transactionConfirm } = parsed;
    // const [transactionConfirmStatus, setTransactionConfirmStatus] = useState(null);

    // const getContact = useContact(dataSetting, 0);

    const checkTransaction = (checkTime) => {
        checkDeposit(depositItems.id).then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                switch (data.result.status) {
                    case 0:
                    case 1: break;
                    case 2:
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "success",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "ทำรายการเติมเงินสำเร็จ !"
                        };

                        openBox(snackbarJson);

                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        reloadData("home");
                        break;
                    case 3:
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "error",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "ทำรายการเติมเงินไม่สำเร็จ ! กรุณาติดต่อผู้ดูแลระบบ"
                        };

                        openBox(snackbarJson);

                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        reloadData("/");
                        break;
                    default:
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "warning",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "ไม่มีข้อมูลธุรกรรมในระบบ"
                        };

                        openBox(snackbarJson);
                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        break;
                }
            }
            else {
                if (statusCode === 2 || statusCode === 4) {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);

                    setTimeout(function () {
                        window.location.href = `/`;
                    }, 5000);
                }
                else {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);
                }
            }
        });
    };

    const cancelTransaction = () => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));

        cancenDeposit().then((data) => {
            const statusCode = data.code;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "ยกเลิกรายการเติมเงินสำเร็จ !"
                };

                removeStorage("processDeposit");
                openBox(snackbarJson);
                reloadData("menu");
            }
            else {
                alertWarning(statusCode);
            }
        });
    };

    const reloadData = (status) => {
        loadDepositFirst().then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDepositData(data.result));

                if (status === "home") {
                    history.push("/deposit/form/manual");
                }

                if (status === "menu") {
                    history.push("/deposit/home");
                }

                if (status === "/") {
                    history.push("/");
                }
            }
            else {
                alertWarning(statusCode);
            }
        });
    };

    const openContact = () => {
        dispatch(setDataContact(true));
    };

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    const alertWarning = (statusCode) => {
        if (statusCode === 2 || statusCode === 4) {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);

            setTimeout(function () {
                window.location.href = `/`;
            }, 5000);
        }
        else {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);
        }
    };

    const onImageChange = (e) => {
        let imageData = e.target.files;
        if (imageData.length > 0) {
            if (imageData[0].type === "image/png" || imageData[0].type === "image/jpeg") {
                setImageUpload(imageData[0]);
            }
            else {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "warning",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "ไฟล์รูปภาพไม่ถูกต้อง กรุณาเลือกรูปภาพใหม่อีกครั้ง !"
                };

                openBox(snackbarJson);
            }
        }
        else {
            setImageUpload(null);
        }
    };

    const confirmOther = () => {
        if (depositItems.situation === 1) {
            if (imageUpload === null) {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "warning",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "กรุณาเลือกรูปภาพสลิปโอนเงิน !"
                };

                openBox(snackbarJson);
            }
            else {
                const dataUpload = new FormData();
                dataUpload.append('slip', imageUpload);
                dataUpload.append('td_date', moment(selectedDate).utc().format("YYYY-MM-DDTHH:mm:00Z"));
                dataUpload.append('td_id', depositItems.id);

                dispatch(setStatusSpinner({status: true, textProcess: null}));
                confirmPaySlip(dataUpload).then((data) => {
                    dispatch(setStatusSpinner({status: false, textProcess: null}));
                    const statusCode = data.code;

                    if (statusCode === 200 || statusCode === 0) {
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "success",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "ส่งข้อมูลเพิ่มเติมสำเร็จ !"
                        };

                        openBox(snackbarJson);
                        reloadData();
                    }
                    else {
                        alertWarning(statusCode);
                    }
                });
            }
        }
    };

    const renderContent = () => {
        if (depositItems.situation === 2) {
            return (
                <div className={[styles.boxDetailTop, "center"].join(" ")}>
                    <div className="svg-box" style={{ marginTop: "0px" }}>
                        <svg className="circular green-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                        </svg>
                        <svg className="checkmark green-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                                <path className="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                            </g>
                        </svg>
                    </div>
                    <div className={styles.confirmText} style={{ color: "black", fontWeight: "700" }}>
                        เเจ้งหลักฐานการโอนสำเร็จ
                    </div>

                    <div className={[styles.bankPromotionComment, styles.boxMarginTop].join(" ")}>
                        * ยอดเงินไม่เข้าภายใน 2 นาที กรุณาเเจ้งแอดมิน *
                    </div>
                </div>
            );
        }
        else if (depositItems.situation === 1) {
            return (
                <div className={styles.boxDetailTop}>
                    <div className={"center"}>
                        <div className={styles.bankPromotionComment}>
                            * แจ้งข้อมูลเพิ่มเติม หากยอดเข้าช้า เกิน 1 นาที *
                        </div>
                    </div>
                    <div className={styles.boxMarginTop}>
                        <div className={styles.boxAccountAgentTitle}>
                            แจ้งเวลาที่โอน
                        </div>
                        <div id="DepositDate" className={styles.depositDate} style={{ marginTop: "6px" }}>
                            <Grid container alignItems="center">
                                <Grid item xs={2}>
                                    <AccessTimeIcon></AccessTimeIcon>
                                </Grid>
                                <Grid item xs={10}>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                                        <DateTimePicker
                                            value={selectedDate}
                                            onChange={date => handleDateChange(date)}
                                            style={{ width: "100%" }}
                                            format="DD/MM/yyyy HH:mm"
                                            ampm={false}
                                            onKeyPress={(event) => validateInput(event, "datetime")}
                                            readOnly={depositItems.situation === 2 ? true : false}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className={styles.boxMarginTop}>
                        <div className={styles.boxAccountAgentTitle}>
                            อัพโหลดสลิปโอนเงิน
                        </div>
                        <div className={styles.depositDate} style={{ marginTop: "6px" }}>
                            <Grid container alignItems="center">
                                <Grid item xs={2}>
                                    <CloudUploadIcon></CloudUploadIcon>
                                </Grid>
                                <Grid item xs={10}>
                                    <div className={styles["file-upload-wrapper"]} data-text={imageUpload !== null ? imageUpload.name : "ไฟล์รูปภาพสลิป"}>
                                        <input name="file-upload-field" type="file" className={styles["file-upload-field"]} onChange={onImageChange} accept="image/png, image/jpeg" />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <br /><br />
                        <div className="center">
                            <button className={[buttonStyles.btnConfirm, buttonStyles.btnSendAgain, "center"].join(" ")} onClick={() => { confirmOther() }}>
                                ส่งข้อมูลเพิ่มเติม
                            </button>
                        </div>
                        <br />
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    };

    useEffect(() => {
        if (depositItems.mode === "deposit") {
            history.push("/deposit/form/decimal");
        }
        else {
            // if (depositItems.time === 0) {
                checkTransaction();
            // }
            // else {
                let timer = depositItems.time, minutes, seconds;
                let countTime = 0;
                const checkTime = setInterval(() => {
                    minutes = parseInt(timer / 60, 10);
                    seconds = parseInt(timer % 60, 10);

                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;

                    // setTimeShow(`${minutes} : ${seconds}`);
                    if (--timer < 0) {
                        // setTimeShow(0);
                        clearInterval(checkTime);
                        return null;
                    }
                    else {
                        countTime++;
                        if (countTime === 3) {
                            checkTransaction(checkTime);
                            countTime = 0;
                        }
                    }

                }, 1000);

                return () => {
                    clearInterval(checkTime);
                }
            }
        // }
    }, [dispatch, depositItems, history]);

    return (
        <>
            <SnackbarSet ref={childRef} />

            {/* <div> */}
            <div className={modalStyles.boxTitle}>
                กำลังตรวจสอบ
            </div>
            <div className={styles.boxDetailDeposit}>
                <div className={styles.boxDetailTop} style={{ marginTop: "0px", borderBottom: "2px dashed #000000" }}>
                    <div className={[styles.boxAccountAgentTitle, "center"].join(" ")}>
                        ระบบกำลังตรวจสอบยอดโอน กรุณารอสักครู่...
                    </div>
                </div>
                {
                    renderContent()
                }
            </div>

            <div className={styles.boxMarginFormTop}>
                <div className="center">
                    <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={openContact}>
                        ติดต่อแอดมิน
                    </button>
                </div>
                <br />
                <div className="center">
                    <button className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} onClick={cancelTransaction}>
                        ยกเลิกทำรายการ
                    </button>
                </div>
            </div>
            {/* <Grid container spacing={2} className="center">
                <Grid item xs={6}>
                    <button className={[buttonStyles.btnRedirect, buttonStyles.btnCancel, "center"].join(" ")} style={{ width: "100%" }} onClick={() => { cancelTransaction() }}>
                        ยกเลิกทำรายการ
                    </button>
                </Grid>
                <Grid item xs={6}>
                    <button className={[buttonStyles.btnDemo, buttonStyles.btnConfirm, "center"].join(" ")} style={{ width: "100%" }} onClick={() => { confirmTransaction() }}>
                        ประวัติเติมเงิน
                    </button>
                </Grid>
            </Grid> */}
        </>
    );
}