const LOAD_SETTING = 'app/LOAD_SETTING';
const LOAD_FAILURE = 'app/LOAD_FAILURE';

function setSetting(data) {
    return {
        type: LOAD_SETTING,
        payload: {
            items: data
        }
    }
}

export {
    LOAD_SETTING,
    LOAD_FAILURE,
    setSetting
}