import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ListUserRank from './component/ListUserRank';
import * as actions from '../../../../../@core/redux/actions/rank'

export default function RankIframeIndex(iframe_load) {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - การจัดอันดับรางวัล";
    
    const dispatch = useDispatch()
    const rankData = useSelector(state => state.reduxRank.rankData)

    useEffect(() => {
        const action = actions.loadRank()

        dispatch(action)
    }, [dispatch])

    return (
        <>
            <ListUserRank pageName="การจัดอันดับรางวัล" {...rankData}></ListUserRank>
        </>
    )
}
