import {
    SET_SHOW_POPUP_IMAGE,
    SET_SHOW_POPUP_PROMOTION,
    SET_SHOW_POPUP_LOGIN,
    SET_SHOW_POPUP_REGISTER
} from '../../actions/app/popup';

const initialState = {
    popupImage: false,
    popupPromotion: false,
    popupLogin: false,
    popupRegister: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_SHOW_POPUP_IMAGE:
            return { ...state, popupImage: action.payload.items };
        case SET_SHOW_POPUP_PROMOTION:
            return { ...state, popupPromotion: action.payload.items };
        case SET_SHOW_POPUP_LOGIN:
            return { ...state, popupLogin: action.payload.items };
        case SET_SHOW_POPUP_REGISTER:
            return { ...state, popupRegister: action.payload.items };
        default:
            return state;
    }
}