import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Component
import ContentGame from './component/Content';
import NavbarPlay from './../../components/NavbarPlay/index';
import NotiEvent from "./../../components/NotiEvent";

const GamePlayApp = () => {
    const { camp, gamecode } = useParams();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        document.body.style.zoom = 1;
    }, []);

    return (
        <>
            <div className="GamePlayApp">
                <Container maxWidth="xl" className="bgContainer">
                    <NavbarPlay isLargeScreen={isLargeScreen}></NavbarPlay>
                    <ContentGame camp={camp} gamecode={gamecode}></ContentGame>
                </Container>
            </div>

            <NotiEvent></NotiEvent>
        </>
    );
}

export default GamePlayApp;