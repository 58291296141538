import { 
    LOAD_RANK_LIST 
} from "../../actions/rank/index";

const initialState = {
    rankData: null
}

export default function RankReducers (state = initialState, action) {
    switch (action.type) {
        case LOAD_RANK_LIST:
            return { ...state, rankData: action.payload.items }
        default:
            return state
    }
}