import {
    SET_DATA_CONTACT
} from '../../actions/app/contact';

const initialState = {
    statusShow: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_CONTACT:
            return { ...state, statusShow: action.payload.items };
        default:
            return state;
    }
}