function isHtml(url) {
    const htmlRegex = /<[^>]*>/;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (htmlRegex.test(url)) {
        return "html";
    }

    if (urlRegex.test(url)) {
        return "url";
    }

    return "no";
}

export default isHtml;