// import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Hooks
import useParameterUrl from "./../../../../../@utility/hooks/useParameterUrl";

// Component
import FooterActive from "./FooterActive";
import FooterNotActive from "./FooterNotActive";

export default function FooterMenu({ isUserLoggedIn, isLargeScreen }) {
    const { path } = useRouteMatch();
    let pathUrl = window.location.pathname;
    const parsed = useParameterUrl();
    const { play_game } = parsed;
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles((theme) => ({
        footerPosition: {
            position: "fixed",
            bottom: "0px",
            left: "0px",
            zIndex: "999",
            width: "100%",
            [theme.breakpoints.down('xs')]: {
                bottom: "-1px"
            }
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.footerPosition}>
            {
                isLargeScreen !== true && (
                    (play_game !== true && play_game !== "true") && (
                        isUserLoggedIn ?
                        <FooterActive play_game={play_game} pathUrl={pathUrl} reduxAppCMS={reduxAppCMS}></FooterActive>
                        :
                        <FooterNotActive play_game={play_game} pathUrl={pathUrl} reduxAppCMS={reduxAppCMS}></FooterNotActive>
                    )
                )
            }
             {/* {
                isLargeScreen !== true && (
                    isUserLoggedIn && (
                        <FooterActive play_game={play_game} pathUrl={pathUrl} reduxAppCMS={reduxAppCMS}></FooterActive>
                    )
                )
            } */}
        </div>
    );
}