// @Utility
import { cleatStorage, getStorage, setStorage } from "./../../@utility/app/storage";

function checkStatusCode(statusCode) {
    if (statusCode === 403 || statusCode === 402 || statusCode === 401 || statusCode === 400) {
        // removeStorage("customerToken");
        // window.location.reload();
        let market = getStorage("market");
        cleatStorage();
        setStorage("market", market, 30);
        window.location.reload(true);
    }
    else {
        return true;
    }
}

export {
    checkStatusCode
}