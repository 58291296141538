import { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

// Components
import GameList from "./../../component/GameList";
// import SelectType from "./../../../../components/SelectType";

export default function GameCamp({ camp, loadData, processingGame, setProcessingGame, rowGameList, selectTypeData, filterGameType }) {
    const [dataGame, setDataGameList] = useState(null);
    const [gameType, setGameType] = useState(0);
    const itemsGameList = useSelector(state => state.reduxGame.itemsGameList);
    const scrollRef = useRef();
    const [scrollY, setScrollY] = useState(0);
    
    const useStyles = makeStyles(() => ({
        contentPosition: {
            width: "100%",
            height: "100%"
            // minHeight: "100vh"
        }
    }));

    const classes = useStyles();

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (scrollY < window.scrollY) {
                let boxGameHeight = scrollRef.current.offsetHeight;
                if (scrollY > (boxGameHeight / 2)) {
                    if (processingGame === false) {
                        setProcessingGame(true);
                        if (itemsGameList !== false) {
                            let gameTypeList = filterGameType();
                            let countAll = gameTypeList.length;
                            let specials = "hot";
                            let selectType = gameType;

                            if(gameType === 200) {
                                specials = "hot";
                            }
                            else if(gameType === 201) {
                                specials = "new";
                            }
                            else {
                                specials = "";
                            }

                            loadData(camp, selectType, "", 12, countAll, true, specials);
                        }
                    }
                }
            }

            setScrollY(window.scrollY);
        }, [scrollY]
    );

    // const filterGameType = useCallback(
    //     () => {
    //         let dataArr = itemsGameList[camp].filter((item) => {
    //             return item.type === selectTypeData.type;
    //         });

    //         return dataArr;
    //     }, [itemsGameList[camp]]
    // );

    // const filterGameType = () => {
    //     let selectType = selectTypeData.type;
    //     let dataArr = itemsGameList[camp][selectType];
    //     // console.log(dataArr)
    //     return dataArr;
    //     // if(selectType === 200 || selectType === 201) {
    //     //     let dataArr = itemsGameList[camp][selectType].filter((item) => {
    //     //         return item.specials === "new" || item.specials === "hot";
    //     //     });
    
    //     //     return dataArr;
    //     // }
    //     // else {
    //     //     let dataArr = itemsGameList[camp][selectType].filter((item) => {
    //     //         return item.type === selectTypeData.type;
    //     //     });
    
    //     //     return dataArr;
    //     // }
    // };

    const selectfilterGameType = () => {
        let gameTypeList = itemsGameList[camp][selectTypeData.type];

        setGameType(selectTypeData.type);
        if(gameTypeList !== undefined) {
            setDataGameList(gameTypeList);
        }
    };

    // console.log(rowGameList)

    const checkCountGameList = () => {
        let gameTypeList = itemsGameList[camp][selectTypeData.type];
        // console.log(gameTypeList)
        if(rowGameList <= 0) {
            return false;
        }
        else {
            if(gameTypeList !== undefined) {
                let countAll = gameTypeList.length;
                if(countAll <= 0) {
                    return false;
                }
                else {
                    if (countAll < rowGameList) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
        }
    };

    // useEffect(() => {
    //     let selectType = selectTypeData.type;
    //     setDataGameList(itemsGameList[camp][selectType]);
    // }, [camp]);

    // useEffect(() => {
    //     setProcessingGame(false);
    //     let gameTypeList = filterGameType();
    //     let countAll = 0;
    //     let specials = "hot";
    //     let selectType = selectTypeData.type;

    //     if(gameTypeList === undefined) {
    //         countAll = 0;
    //     }
    //     else {
    //         countAll = gameTypeList.length;
    //     }

    //     if(selectTypeData.type === 200) {
    //         specials = "hot";
    //     }
    //     else if(selectTypeData.type === 201) {
    //         specials = "new";
    //     }
    //     else {
    //         specials = "";
    //     }

    //     if (countAll <= 0) {
    //         loadData(camp, selectType, "", 12, countAll, false, specials);
    //     }
    //     else {
    //         if(selectTypeData.type === 200 || selectTypeData.type === 201) {
    //             loadData(camp, selectType, "", -1, 0, false, specials);
    //         }
    //         else {
    //             loadData(camp, selectType, "", 12, countAll, true, specials);
    //         }
    //     }

    //     selectfilterGameType();
    // }, []);

    useEffect(() => {
        selectfilterGameType();
    }, [itemsGameList[camp][selectTypeData.type]]);

    useEffect(() => {
        setScrollY(window.scrollY);
    }, []);

    useEffect(() => {
        if (checkCountGameList()) {
            setScrollY(window.scrollY);
            window.addEventListener("scroll", handleNavigation);
    
            return () => {
                window.removeEventListener("scroll", handleNavigation);
            };
        }
    }, [handleNavigation, itemsGameList, selectTypeData.type]);

    return (
        <>
            <div className={classes.contentPosition}>
                <div className="boxGame">
                    <div className={classes.contentGameListWrapper} ref={scrollRef}>
                        {
                            (dataGame !== null) && (
                                <GameList campType={camp} dataGame={dataGame} typePage="camp" typeSelect="gameCamp" gameType={gameType}></GameList>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}