import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// @Utility
import setBodyScroll from "./../../../../@utility/app/setBodyScroll";

//  CSS
import styles from "./../assets/css/Style.module.css";

export default function ClosePage({ backPage }) {
    const [page, setPage] = useState("/");
    // let setPage = page;
    const history = useHistory();

    const closePage = () => {
        if (backPage !== undefined && backPage !== null) {
            setBodyScroll(false);
            history.push(page);
        }
    };

    useEffect(() => {
        setPage(backPage);
    }, [backPage]);

    return <img className={styles.iconClose} src="/images/game/btn_close_circle.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" onClick={() => closePage()} />;
}