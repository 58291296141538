import { useDispatch } from 'react-redux';
import { 
    Grid,
    Card 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// @Utility
import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";
import copyText from "./../../../../../../../@utility/app/copyText";

// Redux
import { setDataAlert } from "./../../../../../../../@core/redux/actions/app/alert";
import { setDataDialog } from "./../../../../../../../@core/redux/actions/app/dialog";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";

export default function ModeTwoHome({ reduxAppCMS, inviteData }) {
    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            padding: "20px 0px"
        },
        textHead: {
            fontSize: "16px",
            fontWeight: "800"
        },
        textSub: {
            fontSize: "20px",
            fontWeight: "800",
            paddingTop: "8px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "16px"
            }
        },
        textColor: {
            color: reduxAppCMS !== null ? reduxAppCMS["bt-register"] : "black"
        },
        textColorGreen: {
            color: reduxAppCMS !== null ? reduxAppCMS["bt-sucess"] : "black"
        },
        textColorRed: {
            color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "black"
        },
        cardWhite: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black"
        },
        cardBlack: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black"
        },
        boxPadding: {
            padding: "20px",
            borderRadius: "6px",
            fontSize: "18px",
            color: "white"
        },
        boxBg: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-secondary"] : "black",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            padding: "10px"
        },
        socialBorder: {
            border: "solid 2px white",
            borderRadius: "6px",
            padding: "0px 6px",
            height: "50px",
            [theme.breakpoints.down('xs')]: {
                height: "50px"
            }
        },
        socialSize: {
            position: "relative",
            width: "36px",
            height: "auto",
            marginTop: "8px",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            }
        },
        btnCopy: {
            position: "relative",
            height: "50px",
            lineHeight: "50px",
            borderRadius: "6px",
            border: "solid 2px transparent",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            fontSize: "18px",
            fontWeight: "600",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px",
                height: "50px",
                lineHeight: "50px"
            }
        },
        // titleBox: {
        //     fontSize: "20px",
        //     color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
        //     marginBottom: "12px",
        //     textAlign: "left"
        // },
        textFriend: {
            fontSize: "16px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            marginTop: "10px",
            [theme.breakpoints.down('xs')]: {
                fontSize: "15px"
            }
        },
        textCondition: {
            fontSize: "16px",
            fontWeight: "600",
            display: "inline-block",
            cursor: "pointer",
            borderBottom: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white"}`,
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white",
            '&:hover': {
                opacity: "0.8"
            }
        },
        textHeadReport: {
            fontSize: "16px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white"
        },
        dataListPosition: {
            marginTop: "12px"
        },
        dataList: {
            borderBottom: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-info"] : "white"}`,
            paddingBottom: "8px",
            paddingTop: "8px"
        },
        textReport: {
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            fontSize: "16px"
        },
        textReportColor: {
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "white",
            fontWeight: "600"
        },
        textReportNumber: {
            fontSize: "16px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-sucess"] : "white"
        }
    }));

    const classes = useStyles();

    const gotoShare = (url) => {
        window.open(url, "_blank");
    };

    const copyUrl = () => {
        const val = inviteData !== null ? inviteData.link.link_web : "-";
        if(copyText(val)) {
            dispatch(setDataAlert({
                type: "success",
                title: "แจ้งเตือน",
                text: "คัดลอกลิงค์สำเร็จ !",
                action: true,
                redirect: "",
                show: true,
                buttonText: "ตกลง"
            }));
        }
    };

    const openCondition = () => {
        let dialogJson = {
            pageType: "htmlOther",
            statusShow: true,
            type: "html",
            image: `${window.location.protocol}//${window.location.host}/images/icon/bell.png`,
            title: "วิธีรับรายได้ค่าเเนะนำ",
            content: inviteData.condition,
            action: null
        };

        dispatch(setDataDialog(dialogJson));
    };

    return (
        inviteData !== null && (
            <>
                <Card className={[classes.root, classes.cardWhite].join(" ")}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={6} >
                            <div className="center">
                                <div className={classes.textHead}>
                                    จำนวนสมาชิก
                                </div>
                                <div className={[classes.textSub, classes.textColor].join(" ")}>
                                    { inviteData.info.member }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                            <div className="center">
                                <div className={classes.textHead}>
                                    รายได้ทั้งหมด
                                </div>
                                <div className={[classes.textSub, classes.textColor].join(" ")}>
                                    { currencyFormat(inviteData.info.income, 2) }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                            <div className="center">
                                <div className={classes.textHead}>
                                    ถอนทั้งหมด
                                </div>
                                <div className={[classes.textSub, classes.textColor].join(" ")}>
                                    { currencyFormat(inviteData.info.withdraw, 2) }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                            <div className="center">
                                <div className={classes.textHead}>
                                    รายได้คงเหลือ
                                </div>
                                <div className={[classes.textSub, classes.textColorGreen].join(" ")}>
                                    { currencyFormat(inviteData.info.balance, 2) }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                <br />
                <Card className={[classes.root, classes.cardBlack].join(" ")}>
                    <div className="center">
                        <div className={modalStyles.boxTitle}>
                            ลิงก์เเนะนำเพื่อน
                        </div>

                        <div className={classes.textFriend}>
                            คุณสามารถนำลิงก์ด้านล่างนี้ไปแชร์ในช่องทางต่างๆ
                        </div>

                        <div className={[classes.boxPadding, "center", styles.boxMarginTop].join(" ")}>
                            <Card className={[classes.boxBg].join(" ")}>
                                {
                                    inviteData.link.link_web
                                }
                            </Card>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={7} sm={6}>
                                    <div className={classes.socialBorder}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={5} md={7}>
                                                แชร์ลิงค์
                                            </Grid>
                                            <Grid item xs={7} md={5}>
                                                <Grid container spacing={1} justifyContent="center">
                                                    <Grid item>
                                                        <img src="/images/button/share_fb.webp" className={classes.socialSize} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" onClick={() => gotoShare(inviteData.link.link_fb)} />
                                                    </Grid>
                                                    <Grid item>
                                                        <img src="/images/button/share_line.webp" className={classes.socialSize} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" onClick={() => gotoShare(inviteData.link.link_line)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={5} sm={6}>
                                    <div className={classes.btnCopy} onClick={copyUrl}>
                                        คัดลอกลิงค์
                                    </div>
                                </Grid>
                            </Grid>
                            <br />
                            <div className={classes.textCondition} onClick={openCondition}>
                                เงื่อนไข วิธีรับรายได้ค่าเเนะนำ
                            </div>
                        </div>
                    </div>
                </Card>
                <br />
                <>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <div className={classes.textHeadReport}>
                                ประเภทเกม (ค่าเเนะนำ %)
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.textHeadReport}>
                                รายได้ทั้งหมด
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.dataListPosition}>
                        {
                            inviteData.game_type.map((item, index) => (
                                <div className={classes.dataList} style={{ borderWidth: index === (inviteData.game_type.length - 1) ? "0px" : "1px" }} key={index}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <div className={classes.textReport}>
                                                { item.game_type_name } <span className={classes.textReportColor}>({ currencyFormat(item.percent, 2) } %)</span>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div className={classes.textReportNumber}>
                                                { currencyFormat(item.balance, 2) }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                        }
                    </div>
                </>
            </>
        )
    );
}