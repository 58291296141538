import React from 'react';
import './App.css';
import { useState, useEffect } from 'react';

// JWT
import { getCustomerToken, getGeneralToken, checkExpireTokenGeneral } from "./@core/auth/jwt/jwtService";

// Page
import ViewsModule from "./views/Views.module";

// Services
import { setTokenGeneral } from "./@core/services/appService";

// Hooks
import useParameterUrl from "./@utility/hooks/useParameterUrl";

function App() {
  const [loadRoutes, setLoadRoutes] = useState(false);
  const parsed = useParameterUrl();

  useEffect(() => {
    let customerToken = getCustomerToken();
    const { web } = parsed;

    const loadGeneralToken = () => {
      setTokenGeneral(web).then(() => {
        setLoadRoutes(true);
      });
    };

    const renderToken = () => {
      if (customerToken === null || customerToken === undefined || customerToken === "undefined") {
        customerToken = getGeneralToken();
        if (customerToken === null || customerToken === "null" ||customerToken === undefined || customerToken === "undefined") {
          loadGeneralToken();
        }
        else {
          if (checkExpireTokenGeneral()) {
            loadGeneralToken();
          }
          else {
            setLoadRoutes(true);
          }
        }
      }
      else {
        setLoadRoutes(true);
      }
    }

    renderToken();
  }, [parsed]);

  return (
    <div className="App" id="App">
      {
        loadRoutes === true && (
          <ViewsModule loadRoutes={loadRoutes}></ViewsModule>
        )
      }
    </div>
  );
}

export default App;
