// import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Hooks
import useParameterUrl from "./../../../../../@utility/hooks/useParameterUrl";

// Component
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

export default function NavbarMenu({ isUserLoggedIn, isLargeScreen, notificationStatus, setNotificationStatusMobile, configInstallPWA}) {
    const { path } = useRouteMatch();
    const homeData = useSelector(state => state.reduxHome.result);
    let pathName = window.location.pathname;
    const parsed = useParameterUrl();
    const { play_game } = parsed;
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    return (
        (play_game !== true && play_game !== "true") && (
            isLargeScreen ?
            <NavbarDesktop play_game={play_game} pathName={pathName} homeData={homeData} isUserLoggedIn={isUserLoggedIn} isLargeScreen={isLargeScreen} reduxAppCMS={reduxAppCMS}></NavbarDesktop>
            :
            <NavbarMobile play_game={play_game} pathName={pathName} homeData={homeData} isUserLoggedIn={isUserLoggedIn} isLargeScreen={isLargeScreen} reduxAppCMS={reduxAppCMS} notificationStatus={notificationStatus} setNotificationStatusMobile={setNotificationStatusMobile} configInstallPWA={configInstallPWA}></NavbarMobile>
        )
    );
}