import { useEffect, useState } from 'react';

// Redux
// import { openInviteFriend } from "./../../../../../@core/redux/actions/affiliate/index";

// Services
import { loadDataAll } from "./../../../../../../../@core/services/affiliateService";

// Component
import Friends from './Friends';

const ModeApp = (props) => {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - แนะนำเพื่อน";
    const [dataObj, setDataObj] = useState(null);

    useEffect(() => {
        const loadData = () => {
            loadDataAll("/customer/commission").then((data) => {
                if (data.code === 0 || data.code === 200) {
                    // console.log(data.result)
                    setDataObj(data.result)
                    // dispatch(setAffiliateData(data.result));
                }
            });
        };

        if (props.linkFriend.show) {
            loadData();
        }
    }, [props.linkFriend.show]);

    return <Friends dataObj={dataObj} reduxAppCMS={props.reduxAppCMS} onShow={props.onShow}></Friends>;
}

export default ModeApp;