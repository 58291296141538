import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// @Utility
import { getStorage, cleatStorage, setStorage } from "./../../../../@utility/app/storage";
import currencyFormat from "./../../../../@utility/numbers/currencyFormat";

// Redux
import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";
import { openProfile } from "./../../../../@core/redux/actions/profile/index";
import * as authActions from "./../../../../@core/redux/actions/auth/index";
import { setOpenWheelStatus } from "./../../../../@core/redux/actions/app/wheel";

//  CSS
import styles from "./../assets/css/Style.module.css";
import buttonStyles from "./../assets/css/Button.module.css";
// Component
// import VipApp from "./VipApp";

// Icon
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import HistoryIcon from '@material-ui/icons/History';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import StarIcon from '@material-ui/icons/Star';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StyleIcon from '@material-ui/icons/Style';
import ShutterSpeedIcon from '@material-ui/icons/ShutterSpeed';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

export default function Sidebar({ homeData, isUserLoggedIn, showChildren, children }) {
    const [state, setState] = useState({ right: false });
    const history = useHistory();
    const dispatch = useDispatch();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    // const [vipData, setVipData] = useState({
    //     tab: "affiliate",
    //     show: false
    // });

    const useStyles = makeStyles((theme) => ({
        list: {
            width: 440,
            [theme.breakpoints.down('xs')]: {
                width: 300
            }
        },
        iconSize: {
            width: "42px",
            height: "42px",
            color: "white",
            [theme.breakpoints.down('xs')]: {
                width: "34px",
                height: "34px"
            }
        },
        btnToggleSize: {
            [theme.breakpoints.down('xs')]: {
                padding: "0px 2px 0px 8px"
            }
        },
        boxProfile: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-navbar"] : "#270058",
            borderRadius: "0px 0px 20px 20px",
            padding: "30px",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "white",
            [theme.breakpoints.down('xs')]: {
                padding: 15
            }
        },
        userSize: {
            width: "100%",
            height: "auto",
            borderRadius: "100%",
            border: `3px solid ${reduxAppCMS !== null ? reduxAppCMS["bg-main"] : "white"}`,
        },
        boxLv: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            color: "black",
            padding: "2px 18px",
            borderRadius: "16px"
        },
        boxUsername: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            padding: "4px",
            borderRadius: "20px",
            fontSize: "18px",
            [theme.breakpoints.down('xs')]: {
                // padding: "4px",
                fontSize: "14px"
            }
        },
        boxLevel: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-main"] : "black",
            padding: "4px 12px"
        },
        boxCredit: {
            margin: "5px 20px 5px 20px",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            padding: "4px 6px 0px 6px",
            borderRadius: "10px",
            fontSize: "18px",
            cursor: "pointer",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px"
            }
        },
        boxPoint: {
            margin: "5px 20px 5px 20px",
            padding: "4px 6px 0px 6px",
            fontSize: "18px",
            cursor: "pointer",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-sucess"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px"
            }
        },
        coinSize: {
            width: "32px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "24px"
            }
        },
        iconPlus: {
            width: "34px",
            height: "34px",
            [theme.breakpoints.down('xs')]: {
                width: "28px",
                height: "28px"
            }
        },
        levelText: {
            fontSize: "18px",
            marginLeft: "10px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "12px"
            }
        },
        usernameText: {
            fontSize: "22px",
            fontWeight: "700",
            marginLeft: "10px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        // boxUserLevelPosition: {
        //     position: "absolute",
        //     right: "0px",
        //     top: "0%",
        //     transform: "translateY(-50%)"
        // },
        userLevelSize: {
            width: "52px",
            height: "52px",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            [theme.breakpoints.down('xs')]: {
                width: "38px",
                height: "38px"
            }
        },
        boxOther: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-card"] : "white",
            padding: "12px",
            borderRadius: "16px",
            fontSize: "18px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-main"] : "black",
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px"
            }
        },
        menuPosition: {
            paddingRight: "20px",
            marginTop: "30px"
        },
        menuSize: {
            width: "32px",
            height: "32px",
            [theme.breakpoints.down('xs')]: {
                width: "26px",
                height: "26px"
            }
        },
        menuList: {
            padding: "12px 0px 12px 12px",
            fontSize: "22px",
            fontWeight: "500",
            color: reduxAppCMS !== null ? reduxAppCMS["bg-main"] : "black",
            cursor: "pointer",
            '&:hover': {
                opacity: 0.8,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "17px"
            }
        },
        menuTitle: {
            fontSize: "22px",
            fontWeight: "600",
            [theme.breakpoints.down('xs')]: {
                fontSize: "18px"
            }
        },
        menuTextSub: {
            fontSize: "18px",
            fontWeight: "300",
            [theme.breakpoints.down('xs')]: {
                fontSize: "14px"
            }
        },
        boxPaddibng: {
            padding: "40px 0px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 0px"
            }
        },
        menuLogin: {
            color: "#FFCC01",
            borderBottom: "1px solid #FFCC01",
            cursor: "pointer"
        }
    }));

    const classes = useStyles();

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const gotoPage = (page, pageName) => {
        // toggleDrawer("right", false);

        if (page === "/bank" || page === "/history" || page === "/activity") {
            if (isUserLoggedIn) {
                history.push(page);
            }
            else {
                openBox(pageName);
            }
        }
        else if (page === "/wheel") {
            gotoWheel(page, pageName);
        }
        else if (page === "/logout") {
            let market = getStorage("market")
            cleatStorage();
            setStorage("market", market)
            window.location.reload();
        }
        else {
            history.push(page);
        }
    };

    const gotoProfile = (type, pageName) => {
        if (isUserLoggedIn) {
            setOpenProfile(type);
        }
        else {
            openBox(pageName);
        }
    };

    const gotoWheel = (page, pageName) => {
        if (isUserLoggedIn) {
            dispatch(setOpenWheelStatus(true));
        }
        else {
            openBox(pageName);
        }
    };

    // const openVip = () => {
    //     setVipData({
    //         tab: "VIP",
    //         show: true
    //     });
    // };

    const setOpenProfile = (type) => {
        dispatch(openProfile({ tab: "profile", show: true, type: type, button: false }));
    };

    const openBox = (page) => {
        dispatch(setDataAlert({
            type: "warning",
            title: "แจ้งเตือน",
            text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "${page}"`,
            action: true,
            redirect: "/login",
            show: true,
            buttonText: "ตกลง"
        }));
    };

    const openLogin = () => {
        let myObj = {
            tab: "login",
            show: true
        };

        dispatch(authActions.openAuthenApp(myObj));
    };

    const openRegister = () => {
        let myObj = {
            tab: "register",
            show: true
        };

        dispatch(authActions.openAuthenApp(myObj));
    };

    const renderContentProfileActive = () => (
        <>
            <div className="relative">
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={3}>
                        <div className="center">
                            <div className={classes.boxUserPosition}>
                                <img className={classes.userSize} src="/images/user/profile-all.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                            <Grid item>
                                <div className={classes.usernameText}>
                                    {homeData !== null && (homeData.username)}
                                </div>

                                <div className={classes.levelText}>
                                    ระดับ{homeData !== null && (homeData.levels.name)}
                                </div>
                            </Grid>
                            <Grid item>
                                <img className={classes.userLevelSize} src={homeData !== null && (homeData.levels.image)} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <div className={[styles.boxMarginTop, classes.boxUsername, classes.boxLevel, "center"].join(" ")}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <span>
                                เติมเงิน
                            </span>
                        </Grid>
                        <Grid item>
                            <span>
                                {homeData !== null && `${homeData.exp_deposit}`} / <span style={{ color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000", fontWeight: "500" }}>{homeData !== null && `${homeData.levels.exp_deposit}`}</span>
                            </span>
                        </Grid>
                    </Grid>
                </div> */}
            </div>


            {/* <div className={[styles.boxMarginTop, classes.boxUsername, classes.boxLevel, "center"].join(" ")}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                    <Grid item>
                        <span>
                            เทิร์นโอเวอร์
                        </span>
                    </Grid>
                    <Grid item>
                        <span>
                            {homeData !== null && `${homeData.exp_turnover}`} / <span style={{ color: reduxAppCMS !== null ? reduxAppCMS["bt-sucess"] : "#00D408", fontWeight: "500" }}>{homeData !== null && `${homeData.levels.exp_turnover}`}</span>
                        </span>
                    </Grid>
                </Grid>
            </div> */}

            <div className={styles.boxMarginTop}>
                <div className={classes.boxOther}>
                    <Grid container alignItems="center">
                        <Grid item xs={2} className="center">
                            เครดิต
                        </Grid>
                        <Grid item xs={10}>
                            <div className={classes.boxCredit} onClick={() => { gotoPage("/deposit/home") }}>
                                <Grid container alignItems="center" className="center">
                                    <Grid item xs={2} className="left">
                                        <img className={classes.coinSize} src="/images/icon/money-2.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{ color: "#FFD600" }}>
                                            {
                                                homeData !== null && currencyFormat(homeData.balance, 2)
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <AddIcon className={classes.iconPlus}></AddIcon>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            {
                homeData !== null && (
                    homeData.wallets !== null && (
                        <div className={styles.boxMarginTop}>
                            <div className={classes.boxOther}>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        เเต้มสะสมทั้งหมด
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.boxPoint}>
                                            {
                                                currencyFormat(homeData.wallets[0].balance, 2)
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    )
                )
            }
        </>
    );

    const renderContentProfile = () => (
        <div className={classes.boxPaddibng}>
            <div className="center">
                <div className={classes.menuTitle}>
                    สมาชิกใหม่ลุ้นรับของรางวัลมากมาย
                </div>
                <div className={classes.menuTextSub}>
                    อยากสนุก ตื่นเต้น เร้าใจ สมัครเลย !
                </div>
                <br />
                <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={openRegister}>
                    สมัครสมาชิก ตอนนี้
                </button>
                <br /> <br />
                <div className={classes.menuTextSub}>
                    เป็นสมาชิกอยู่เเล้วใช่ หรือไม่ ? <span className={classes.menuLogin} onClick={openLogin}>เข้าสู่ระบบ</span>
                </div>
            </div>
        </div>
    );

    const rederContent = (anchor) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className={classes.boxProfile}>
                {
                    isUserLoggedIn ?
                        renderContentProfileActive()
                        :
                        renderContentProfile()
                }
            </div>

            <div className={classes.menuPosition}>
                <div className={classes.menuList} onClick={() => { gotoPage("/") }}>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            หน้าหลัก
                        </Grid>
                        <Grid item>
                            <HomeIcon className={classes.menuSize}></HomeIcon>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className={classes.menuList} onClick={() => { gotoPage("/wheel", "กงล้อลุ้นโชค") }}>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            กงล้อลุ้นโชค
                        </Grid>
                        <Grid item>
                            <ShutterSpeedIcon className={classes.menuSize}></ShutterSpeedIcon>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className={classes.menuList} onClick={() => { gotoPage("/activity", "กิจกรรม") }}>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            กิจกรรม
                        </Grid>
                        <Grid item>
                            <LocalActivityIcon className={classes.menuSize}></LocalActivityIcon>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className={classes.menuList} onClick={() => { gotoPage("/promotion") }}>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            โปรโมชั่น
                        </Grid>
                        <Grid item>
                            <StyleIcon className={classes.menuSize}></StyleIcon>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className={classes.menuList} onClick={() => { gotoPage("/history", "ประวัติทำรายการ") }}>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            ประวัติทำรายการ
                        </Grid>
                        <Grid item>
                            <HistoryIcon className={classes.menuSize}></HistoryIcon>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className={classes.menuList} onClick={() => { gotoPage("/bank", "บัญชีเติมเงิน") }}>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            บัญชีเติมเงิน
                        </Grid>
                        <Grid item>
                            <AccountBoxIcon className={classes.menuSize}></AccountBoxIcon>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                {/* onClick={() => { openVip() }} */}
                <div className={classes.menuList} >
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            สิทธิพิเศษ VIP
                        </Grid>
                        <Grid item>
                            <span className="labelDanger">เร็วๆนี้</span>
                            {/* <StarIcon className={classes.menuSize}></StarIcon> */}
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                {
                    isUserLoggedIn && (
                        <>
                            <div className={classes.menuList} onClick={() => { gotoProfile("password", "เปลี่ยนรหัสผ่าน") }}>
                                <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        เปลี่ยนรหัสผ่าน
                                    </Grid>
                                    <Grid item>
                                        <LockIcon className={classes.menuSize}></LockIcon>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider />
                            <div className={classes.menuList} onClick={() => { gotoPage("/logout") }}>
                                <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        ออกจากระบบ
                                    </Grid>
                                    <Grid item>
                                        <ExitToAppIcon className={classes.menuSize}></ExitToAppIcon>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider />
                        </>
                    )
                }
            </div>
        </div>
    );

    return (
        <div className="Sidebar">
            {
                showChildren ?
                    <div onClick={toggleDrawer("right", true)}>
                        {
                            children
                        }
                    </div>
                    :
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer("right", true)}
                            edge="start"
                            className={classes.btnToggleSize}
                        >
                            <MenuIcon className={classes.iconSize} />
                        </IconButton>

                        {/* <VipApp vipData={vipData} onSetVipData={setVipData} /> */}
                    </>
            }

            <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
                {rederContent("right")}
            </Drawer>
        </div>
    );
}