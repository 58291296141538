import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { Grid, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';

export default function TextAnimate() {
    const { path } = useRouteMatch();
    const [pathName, setPathName] = useState("/");
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    // console.log(reduxAppCMS)

    const useStyles = makeStyles((theme) => ({
        announcePosition: {
            // marginTop: "-4px",
            // paddingLeft: "8px",
            background: reduxAppCMS !== null ? reduxAppCMS["bg-text-animation"] : "black"
        },
        announceSize: {
            width: "40px",
            [theme.breakpoints.down('xs')]: {
                width: "25px"
            }
        },
        announceIconBg: {
            // background: "white",
            // borderRadius: "10px",
            // padding: "8px 0px 8px 0px",
            // [theme.breakpoints.down('xs')]: {
            //     padding: "6px 0px",
            //     height: "36px"
            // }
        },
        announceTextBg: {
            padding: "12px 0px",
            color: "white",
            fontSize: "16px",
            [theme.breakpoints.down('xs')]: {
                padding: "4px 0px 2px 0px",
                fontSize: "13px"
            }
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        setPathName(window.location.pathname);
    }, [window.location.pathname, path]);

    return (
        pathName === "/" && (
            <div className={classes.announcePosition}>
                <Container maxWidth="lg" style={{ padding: "0px" }}>
                    <Grid container alignItems="center">
                        <Grid item xs={2} lg={1}>
                            <div className={classes.announceIconBg}>
                                <img className={classes.announceSize} src="/images/icon/announcement.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            </div>
                        </Grid>
                        <Grid item xs={10} lg={11}>
                            <div className={classes.announceTextBg}>
                                {/* <marquee direction="left">"เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์" คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม.</marquee> */}
                                <marquee direction="left">
                                    {
                                        reduxAppCMS !== null ? reduxAppCMS["announce"] : ""
                                    }
                                </marquee>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    );
}