const SET_DATA_CMS = 'app/SET_DATA_CMS';

function setDataCMS(data) {
    return {
        type: SET_DATA_CMS,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_CMS,
    setDataCMS
}