import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    Tabs,
    Tab,
    Typography,
    Box,
    // Grid
} from '@material-ui/core';

// Redux
import { 
    loadDataInviteAll,
    loadCliamInvite,
    loadMemberInvite
} from "./../../../../../../../@core/redux/actions/affiliate/index";
import { setStatusSpinner } from "./../../../../../../../@core/redux/actions/app/spinner";

// Component
import ModeTwoHome from "./Home";
import ModeTwoCustomer from "./Customer";
import ModeTwoCommission from "./Commission";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";

function TabPanel(props) {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - แนะนำเพื่อน";
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function ModeTwo({ linkFriend, onShow, reduxAppCMS }) {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const inviteData = useSelector(state => state.reduxAffiliate.inviteData);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const useStyles = makeStyles(() => ({
        root: {
            opacity: "1 !important",
            color: reduxAppCMS !== null ? `${reduxAppCMS["bt-defult"]} !important` : "white !important"
        },
        tabText: {
            fontSize: "18px",
            '&:hover': {
                color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "#FFD600"
            }
        },
        tabTextActive: {
            fontSize: "18px",
            color: reduxAppCMS !== null ? `${reduxAppCMS["bt-warning"]} !important` : "#FFD600 !important",
            opacity: "1 !important"
            // borderBottom: "2px solid #FFCC01 !important"
        },
        tabContent: {
            padding: "20px 0px"
        },
        selctType: {
            padding: "12px 18px",
            fontSize: "18px",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            borderRadius: "10px",
            marginBottom: "10px"
        },
        contentText: {
            fontSize: "16px",
            fontWeight: "600"
        },
        btnHistory: {
            position: "relative",
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            borderRadius: "10px",
            padding: "8px 16px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            fontSize: "16px",
            cursor: "pointer",
            '&:hover': {
                top: "2px"
            }
        },
        boxAmount: {
            border: `2px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"}`,
            padding: "20px",
            borderRadius: "10px"
        },
        boxAmountTitle: {
            fontSize: "20px",
            fontWeight: "600"
        },
        boxAmountResult: {
            fontSize: "26px",
            fontWeight: "600",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-warning"] : "#FFD600"
        },
        boxCondition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            padding: "22px 12px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            borderRadius: "15px"
        },
        boxConditionTitle: {
            fontSize: "18px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000",
            fontWeight: "600",
            marginBottom: "10px"
        },
        indicator: {
            backgroundColor: "#FFCC01"
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        if(linkFriend.show) {
            if(inviteData === null) {
                dispatch(setStatusSpinner({status: true, textProcess: null}));
            }

            dispatch(loadDataInviteAll());
            dispatch(loadCliamInvite(10, 0));
            dispatch(loadMemberInvite(10, 0));
        }
    }, [linkFriend.show]);

    return (
        <>
            <div className={modalStyles.boxTitle}>
                แนะนำเพื่อน
            </div>

            <div className={styles.boxMarginFormTop}>
                <div className={classes.root}>
                    <Tabs
                        variant="fullWidth"
                        value={value}
                        onChange={handleChange}
                        classes={{
                            indicator: classes.indicator
                        }}
                    >
                        <LinkTab label="ภาพรวม" className={value === 0 ? classes.tabTextActive : classes.tabText} {...a11yProps(0)} />
                        <LinkTab label="สมาชิก" className={value === 1 ? classes.tabTextActive : classes.tabText} {...a11yProps(1)} />
                        <LinkTab label="ถอนรายได้" className={value === 2 ? classes.tabTextActive : classes.tabText} {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={value} index={0} className={classes.tabContent}>
                        <ModeTwoHome linkFriend={linkFriend} onShow={onShow} reduxAppCMS={reduxAppCMS} inviteData={inviteData}></ModeTwoHome>
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.tabContent}>
                        <ModeTwoCustomer linkFriend={linkFriend} onShow={onShow} reduxAppCMS={reduxAppCMS}></ModeTwoCustomer>
                    </TabPanel>
                    <TabPanel value={value} index={2} className={classes.tabContent}>
                        <ModeTwoCommission linkFriend={linkFriend} onShow={onShow} reduxAppCMS={reduxAppCMS} inviteData={inviteData}></ModeTwoCommission>
                    </TabPanel>
                </div>
            </div>
        </>
    );
}